import React, { useEffect, useMemo, useState } from "react";
import {
    useReactTable,
    getCoreRowModel,
    flexRender,
    getSortedRowModel,
} from "@tanstack/react-table";
import { capitalizeString, makeDeepCopy } from "../../utils/utils";
import { errorMessage } from "../../utils/messages";
import chevronDown from "../../assets/images/svg/chevronDown.svg";
import chevronUp from "../../assets/images/chevronUp.png";

const defaultColumn = {
    cell: ({ getValue, row: { index }, column: { id }, table }) => {
        let value = getValue();
        return id.endsWith("date") ? (
            <input
                type="date"
                onBlur={(e) => {
                    table.options.meta?.updateData(index, id, e.target.value, true);
                }}
                value={value || ""}
                onChange={(e) => {
                    table.options.meta?.updateData(index, id, e.target.value);
                }}
            />
        ) : (
            <input
                maxLength={25}
                placeholder="---"
                onBlur={(e) => {
                    value = e.target.value.toUpperCase();
                    table.options.meta?.updateData(index, id, value, true);
                }}
                value={value || ""}
                onChange={(e) => {
                    let value = e.target.value.toUpperCase();
                    table.options.meta?.updateData(index, id, value);
                }}
            />
        );
    },
};

export default function EditMapData({
    changedHeaders,
    changeableHeaders,
    data,
    focusFirst,

    headers,
    state,
    setHeaders,

    setCurrentUpdateData,
    handleInsertedEdit,
    handleDelSelect,
    rawDataIds,
}) {
    const columns = useMemo(() => {
        let generatedColumns = headers.map((header, i) => ({
            header: capitalizeString(
                changedHeaders?.newHeaders?.length ? changedHeaders?.newHeaders?.[i] : header
            ),
            accessorKey: header,
        }));

        return generatedColumns;
    }, [JSON.stringify(changedHeaders?.newHeaders)]);

    const [tableData, setTableData] = useState(makeDeepCopy(data));

    // TO CHANGE DATA
    const standardOptions = {
        address: "address_location",
        city: "city_country",
        state: "state_province",
        zip: "zip_postcode",
        group: "groupby_thematicValue",
        phone: "phone",
    };

    const advanceOptions = {
        name: "name",
        email: "email",
        url: "url",
    };

    const table = useReactTable({
        data: tableData,
        columns,
        defaultColumn,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),

        meta: {
            updateData: (rowIndex, columnId, value, type) => {
                if (type) {
                    // getUpdatedData(tableData);
                    // setCurrentUpdateData(rowIndex, type, newObj, tableData);

                    return;
                } else {
                    let updatedData = null;
                    setTableData((old) => {
                        updatedData = old.map((row, index) => {
                            if (index === rowIndex) {
                                let newObj = {
                                    ...old[rowIndex],
                                    [columnId]: value,
                                };
                                if (
                                    state.standardOptions[standardOptions[columnId]] ||
                                    state.advanceOptions[advanceOptions[columnId]]
                                ) {
                                    newObj[
                                        state.standardOptions[standardOptions[columnId]] ||
                                            state.advanceOptions[advanceOptions[columnId]]
                                    ] = value;
                                }
                                if (setCurrentUpdateData) {
                                    setCurrentUpdateData(rowIndex, newObj);
                                }
                                if (handleInsertedEdit) {
                                    handleInsertedEdit(newObj);
                                }

                                return newObj;
                            }
                            return row;
                        });

                        return updatedData;
                    });
                }
            },
        },
        debugTable: true,
    });

    const handleChangeHeader = (e, index) => {
        e.stopPropagation();

        const value = e.target.value.trim().toUpperCase();

        let newHeaders = makeDeepCopy(changedHeaders?.newHeaders);

        let bool = false;

        if (newHeaders.includes(value) && newHeaders.indexOf(value) !== index) {
            bool = true;
            errorMessage("Column name already exists!");
            e.target.focus();
            e.target.value = capitalizeString(newHeaders[index]);
        } else {
            newHeaders[index] = value;
            setHeaders((prevState) => {
                return {
                    ...prevState,
                    newHeaders,
                };
            });
        }
        if (e.relatedTarget == null && !bool) {
            let container = document.querySelector(".mapTabular-TableView--container");
            container?.focus?.();
            container?.blur?.();
        }
    };

    useEffect(() => {
        setTableData(data);
        return () => {};
    }, [data]);

    useEffect(() => {
        if (focusFirst) {
            const tableContainer = document.querySelector(".mapTabular-TableView--container table");
            tableContainer.querySelector("input")?.focus();
        }
    }, []);

    const selectRows = (row) => {
        return (
            rawDataIds?.insertSelected?.includes(row.original.id) ||
            rawDataIds?.delSelected?.includes(row.original.id)
        );
    };

    return (
        <div
            className="mapTabular-TableView--container"
            style={{ borderRadius: "10px", overflowX: "auto" }}
            tabIndex="-1"
        >
            <table className="mapData-tabular--table create">
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <th key={header.id} colSpan={header.colSpan}>
                                        {header.isPlaceholder ? null : changeableHeaders ? (
                                            <input
                                                type="text"
                                                maxLength={25}
                                                name={header.id}
                                                id={header.id}
                                                defaultValue={header.column.columnDef.header}
                                                onBlur={(e) => handleChangeHeader(e, header.index)}
                                            />
                                        ) : (
                                            <div
                                                {...{
                                                    className: header.column.getCanSort()
                                                        ? "cursor-pointer select-none"
                                                        : "",
                                                    onClick:
                                                        header.column.getToggleSortingHandler(),
                                                }}
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                {{
                                                    asc: (
                                                        <img src={chevronUp} alt="ascending icon" />
                                                    ),
                                                    desc: (
                                                        <img
                                                            src={chevronDown}
                                                            alt="descending icon"
                                                        />
                                                    ),
                                                }[header.column.getIsSorted()] ?? (
                                                    <img src={chevronDown} alt="default icon" />
                                                )}
                                            </div>
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>

                <tbody>
                    {table.getRowModel().rows.map((row) => {
                        let isSelected = selectRows(row);
                        return (
                            <tr
                                onClick={(e) => {
                                    if (handleDelSelect) {
                                        handleDelSelect(e, row.original.id);
                                    }
                                }}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: isSelected ? "rgba(221, 62, 62, 0.16)" : "",
                                }}
                                key={row.id}
                            >
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <td
                                            id="table_data"
                                            key={cell.id}
                                            style={{ textTransform: "none !important" }}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
