import React, { useMemo, useState } from "react";
import EditMapData from "../../../components/EditMapData/EditMapData";
import MapGroups from "../../../components/MapGroups/MapGroups";
import MarkerOptionsEditMap from "../../../components/myMaps/MarkerOptionsEditMap";
import EditMapNav from "./EditMapNav";
import Map from "../../../components/Map/Map";
import MapMarkers from "../../../components/Map/MapMarkers";
import { InfoWindow } from "@react-google-maps/api";
import MarkerInfo from "../../../components/Map/MarkerInfo";
import { setMapBounds } from "../../../utils/mapUtils";
import GoogleMapResizer from "../../../components/GoogleMapResizer/GoogleMapResizer";
import { CREATE_MAP_SOCKET, UPDATE_MAP_LOCATION } from "../../../socket/socketsList";
import socket, { addSetSocketMap, updateSocketMap } from "../../../socket/socket";
import { rawData } from "../../../utils/constants";
import { v4 as uuid } from "uuid";
import ReactPortal from "../../../components/Portal/ReactPortal";
import EditMapConfirmModel from "./EditMapConfirmModel";

const containerStyle = {
    width: "100%",
    maxHeight: "100%",
    height: "100vh",
};

function EditMapView(props) {
    const {
        refState,
        editMapState,
        setEditMapState,
        handleCancel,
        handleUpdateMap,
        zoomRef,
        changedRow,
        setUpdatingRef,
        setCurrentUpdateData,
        setHistoryState,
        historyState,
        setChangedRow,
        setTableRowsData,
        tableRowsData,
        rawDataIds,
        setRawDataIds,
        getCurrentMap,
        handleEditMapModel,
        cancelMapDataUpadte,
        handleDataUpdate,
    } = props;

    const [editMapViewState, setEditMapViewState] = useState({
        showInfo: false,
        markerInfo: null,
        duplicateMarkers: null,
        showUserBox: false,
        center: editMapState.center,
        showTable: true,
        showEditInputCodes: true,
    });

    const [showFailedRecords, setShowFailedRecords] = useState(false);

    // const getUpdated

    const handleShowTableData = (cond) => {
        setEditMapViewState((prevState) => {
            return {
                ...prevState,
                showTable: cond,
            };
        });
    };
    const headers = useMemo(
        () => ["name", "email", "phone", "url", "group", "address", "city", "state", "zip"],
        []
    );

    const getUpdatedData = (updatedData) => {
        if (updatedData) {
            setEditMapState((prevState) => {
                return {
                    ...prevState,
                    rawData: updatedData,
                };
            });
        }
    };

    function setMapZoom(map, ref) {
        ref.current.defaultZoom = map.zoom;
    }

    const onMapLoad = ({ map, maps }) => {
        map.addListener("zoom_changed", () => setMapZoom(map, zoomRef));
        setEditMapState((prevState) => {
            return {
                ...prevState,
                map: {
                    ...prevState.map,
                    mapRef: map,
                },
            };
        });

        setTimeout(() => {
            setMapBounds(map, editMapState.rawData, zoomRef, window.google?.maps.LatLngBounds);
        }, 0);

        return () => {
            map.removeListener("zoom_changed", () => setMapZoom(zoomRef, map));
        };
    };

    function handleMarkerClick(props, pin) {
        if (pin?.length > 1) {
            setEditMapViewState((prevState) => ({
                ...prevState,
                markerInfo: pin?.[0],
                duplicateMarkers: pin,
                showInfo: !editMapViewState.showInfo,
            }));
        } else {
            setEditMapViewState((prevState) => ({
                ...prevState,
                markerInfo: pin?.[0],
                duplicateMarkers: null,
                showInfo: !editMapViewState.showInfo,
            }));
        }
    }

    const handleCloseMarkerModel = () => {
        setEditMapViewState((prevState) => {
            return {
                ...prevState,
                showInfo: false,
            };
        });
    };

    const handlePinDragStart = (data, pin) => {
        console.log("DRAG START: ", {
            lat: data.latLng.lat(),
            lng: data.latLng.lng(),
        });
    };
    const handlePinDragEnd = (data, pin) => {
        console.log("DRAG END: ", {
            lat: data.latLng.lat(),
            lng: data.latLng.lng(),
        });
    };

    const info = useMemo(
        () => ({
            name: editMapViewState.markerInfo?.[editMapState.advanceOptions.name],
            email: editMapViewState.markerInfo?.[editMapState.advanceOptions.email],
            url: editMapViewState.markerInfo?.[editMapState.advanceOptions.url],
            phone: editMapViewState.markerInfo?.[editMapState.standardOptions.phone],
            address: editMapViewState.markerInfo?.[editMapState.standardOptions.address_location],
            zip: editMapViewState.markerInfo?.[editMapState.standardOptions.zip_postcode],
            city: editMapViewState.markerInfo?.[editMapState.standardOptions.city_country],
            state: editMapViewState.markerInfo?.[editMapState.standardOptions.state_province],
            group: editMapViewState.markerInfo?.[
                editMapState.standardOptions.groupby_thematicValue
            ],
        }),
        [
            editMapViewState.markerInfo,
            JSON.stringify(editMapState.advanceOptions),
            JSON.stringify(editMapState.standardOptions),
        ]
    );

    const handleSaveUpdates = () => {
        const isLocationUpdate = changedRow?.filter((item) => {
            if (
                tableRowsData.NAME !== item?.rowData?.NAME ||
                tableRowsData.CITY !== item?.rowData?.CITY ||
                tableRowsData.STATE !== item?.rowData?.STATE ||
                tableRowsData.ADDRESS !== item?.rowData?.ADDRESS ||
                tableRowsData.ZIP !== item?.rowData?.ZIP
                // &&
                // (item?.rowData?.NAME ||
                //     item?.rowData?.CITY ||
                //     item?.rowData?.STATE ||
                //     item?.rowData?.ADDRESS ||
                //     item?.rowData?.ZIP))
            ) {
                return item;
            }
        });

        console.log("check send loc", isLocationUpdate);

        if (isLocationUpdate?.length > 0) {
            socket.emit(UPDATE_MAP_LOCATION, {
                mapID: editMapState?.id,
                socketID: socket.id,
                data: isLocationUpdate,
            });
            addSetSocketMap(changedRow);
            setUpdatingRef(true);
        }
        if (isLocationUpdate?.length === 0) {
            setTimeout(() => {
                setHistoryState((prev) => {
                    return {
                        ...prev,
                        isHistoryLoading: false,
                    };
                });
                setChangedRow([]);
            }, 500);
        }
    };

    const handleDelSelect = (e, id) => {
        if (id) {
            if (e.shiftKey) {
                if (rawDataIds.delSelected.includes(id)) {
                    setRawDataIds((prev) => {
                        let filteredSelections = rawDataIds.delSelected.filter(
                            (item) => item !== id
                        );
                        return {
                            ...prev,
                            delSelected: filteredSelections,
                        };
                    });
                } else {
                    setRawDataIds((prev) => {
                        return {
                            ...prev,
                            delSelected: [id, ...prev.delSelected],
                        };
                    });
                }
            } else {
                setRawDataIds((prev) => {
                    return {
                        ...prev,
                        delSelected: [id],
                    };
                });
            }
        } else {
            setRawDataIds((prev) => {
                return {
                    ...prev,
                    delSelected: [],
                };
            });
        }
    };

    const handleDeleteSelections = () => {
        let filteredData = tableRowsData?.filter(
            (item) => !rawDataIds?.delSelected?.includes(item.id)
        );
        if (rawDataIds?.delSelected?.length > 0) {
            setEditMapState((prev) => {
                return {
                    ...prev,
                    deletedLocations: rawDataIds.delSelected,
                };
            });
            setRawDataIds((prev) => {
                return {
                    ...prev,
                    delSelected: [],
                };
            });
        }
        setTableRowsData(filteredData);
        setEditMapState((prev) => {
            return {
                ...prev,
                rawData: filteredData,
            };
        });
    };

    const handleRowInsert = () => {
        // Generate a new item with a unique ID and default values
        let newItem = Object.keys(editMapState.rawData?.[0] || {}).reduce((acc, key) => {
            if (key === "id") {
                acc[key] = uuid(); // Generate a unique ID for the new row
            } else {
                acc[key] = ""; // Default value for other fields
            }
            return acc;
        }, {});
        let changedRowObj = {
            rowId: newItem.id,
            rowData: newItem,
        };
        setChangedRow([changedRowObj, ...changedRow]);
        setTableRowsData([newItem, ...tableRowsData]);
    };

    const handleInsertedEdit = (dataItem) => {
        const getUpdatedRows = tableRowsData.map((item) => {
            if (item?.id === dataItem?.id) {
                return dataItem;
            }
            return item;
        });
        setTableRowsData(getUpdatedRows);
    };

    return (
        <>
            <section className="map-options-container">
                <MarkerOptionsEditMap
                    refState={refState}
                    state={editMapState}
                    create={true}
                    setState={setEditMapState}
                    handleShowTableData={handleShowTableData}
                    showEditInputCodes={editMapViewState.showEditInputCodes}
                    showFailedRecords={showFailedRecords}
                    setShowFailedRecords={setShowFailedRecords}
                >
                    <EditMapNav
                        state={editMapState}
                        handleCancel={handleCancel}
                        handleUpdateMap={handleUpdateMap}
                        changedRow={changedRow}
                        setHistoryState={setHistoryState}
                        historyState={historyState}
                        handleSaveUpdates={handleSaveUpdates}
                        showFailedRecords={showFailedRecords}
                        setShowFailedRecords={setShowFailedRecords}
                    />
                </MarkerOptionsEditMap>

                <Map
                    componentProps={{
                        center: editMapViewState.center,
                        containerStyle,
                        mapStyle: editMapState.advanceOptions.mapStyle,
                        maxZoomOut: zoomRef.current.maxZoomOut,
                        defaultZoom: zoomRef.current.defaultZoom,
                        maxZoomIn: zoomRef.current.maxZoomIn,
                        onMapLoad: onMapLoad,
                    }}
                >
                    {/* MARKERS */}
                    {editMapState.rawData?.length && (
                        <MapMarkers
                            handlePinDragStart={handlePinDragStart}
                            handlePinDragEnd={handlePinDragEnd}
                            data={editMapState.rawData}
                            map={editMapState.map.mapRef}
                            markerStyle={editMapState.advanceOptions?.markerStyle}
                            handleMarkerClick={handleMarkerClick}
                        />
                    )}

                    {editMapViewState.showInfo && (
                        <InfoWindow
                            onCloseClick={() =>
                                setEditMapViewState((prevState) => ({
                                    ...prevState,
                                    showInfo: false,
                                    markerInfo: null,
                                }))
                            }
                            position={editMapViewState.markerInfo.location}
                            zIndex={2}
                        >
                            <MarkerInfo
                                // info={info}
                                info={editMapViewState.markerInfo}
                                mapState={editMapState}
                                handleCloseMarkerModel={handleCloseMarkerModel}
                                setMapState={setEditMapState}
                                getSingleMapData={getCurrentMap}
                            />
                        </InfoWindow>
                    )}
                </Map>
                <GoogleMapResizer />
                <MapGroups
                    state={editMapState}
                    markerStyle={
                        editMapState.advanceOptions?.customMarkerURL ||
                        editMapState.advanceOptions?.markerStyle
                    }
                    data={editMapState.groupedData}
                    editMode={true}
                    handleRowInsert={handleRowInsert}
                    rawDataIds={rawDataIds}
                    setRawDataIds={setRawDataIds}
                    handleDeleteSelections={handleDeleteSelections}
                    changedRow={changedRow}
                    handleUpdateLoc={handleSaveUpdates}
                />
            </section>

            {editMapViewState.showTable && (
                <EditMapData
                    state={editMapState}
                    data={tableRowsData}
                    getUpdatedData={getUpdatedData}
                    headers={headers}
                    setCurrentUpdateData={setCurrentUpdateData}
                    rawDataIds={rawDataIds}
                    handleDelSelect={handleDelSelect}
                    handleInsertedEdit={handleInsertedEdit}
                />
            )}

            {historyState?.showHistoryWarnModel && (
                <ReactPortal wrapperId="external_modal_container">
                    <EditMapConfirmModel
                        // handleEditMapModel={handleEditMapModel}
                        historyState={historyState}
                        handleSaveUpdates={handleSaveUpdates}
                        handleEditMapModel={handleEditMapModel}
                        cancelMapDataUpadte={cancelMapDataUpadte}
                        handleDataUpdate={handleDataUpdate}
                        changedRow={changedRow}
                    />
                </ReactPortal>
            )}
        </>
    );
}

export default EditMapView;
