import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dummyUser from "../../assets/images/avatar-grey.png";
import "react-toastify/dist/ReactToastify.css";
import "./sidebar.css";
import OneSignal from "react-onesignal";
import logoXoomMaps from "../../assets/images/LogoXoomMaps.png";
import {
    getAllNotifications,
    getUnreadCount,
    setShowSystemNotificationModel,
} from "../../store/slicers/systemNotificationsSlice";
import { axiosInstance } from "../../apiCalls/axios";
import { successMessage } from "../../utils/messages";
import { setMapDashboardId } from "../../store/slicers/mapDashboardSlice";

const Sidebar = (props) => {
    const { user, userImage } = useSelector((state) => state.auth);
    const { id: mapDashboardId, resetMapId } = useSelector((state) => state?.mapDashboardState);
    const NotificationsLength = useSelector(getAllNotifications);
    const unReadCount = useSelector(getUnreadCount);

    const dispatch = useDispatch();

    const handleLogout = () => {
        const tags = ["user_id", "email"];
        OneSignal.User.removeTags(tags);

        localStorage.clear();
        // DELETE USER TOKEN
        axiosInstance.defaults.headers = {
            "x-auth-token": "",
        };

        successMessage("Successfully logout");
        setTimeout(() => {
            window.location.href = "/login";
        }, 1000);
    };

    const handlHowToModel = () => {
        props?.setHowToModelOpen(true);
        // resetDashboardId();
    };
    const handleNotifications = (e) => {
        e.preventDefault();
        dispatch(setShowSystemNotificationModel(true));
        // resetDashboardId();
    };
    const resetDashboardId = () => {
        if (resetMapId === mapDashboardId) {
            return;
        }
        dispatch(setMapDashboardId(resetMapId));
    };

    return (
        <>
            <aside className="sidenav">
                <div className="sidebar-menu-container">
                    <div className="sidenav-subfirst">
                        <div className="sidebar-title">
                            <img src={logoXoomMaps} alt="logo-xoommaps" />
                        </div>
                        <ul>
                            <li className="dashboard">
                                <NavLink to="/dashboard">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            id="dashboard-off"
                                            d="M4.5,17.833H15.167V4.5H4.5ZM4.5,28.5H15.167v-8H4.5Zm13.333,0H28.5V15.167H17.833Zm0-24v8H28.5v-8Z"
                                            transform="translate(-4.5 -4.5)"
                                            fill="#999b9e"
                                        />
                                    </svg>
                                    Dashboard
                                </NavLink>
                            </li>
                            <li className="noc">
                                <NavLink
                                    to="/noc"
                                    //  onClick={resetDashboardId}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="23.998"
                                        height="23.998"
                                        viewBox="0 0 23.998 23.998"
                                    >
                                        <path
                                            id="N.O.C.-off"
                                            d="M17068.6,18094h-19.2a2.4,2.4,0,0,1-2.4-2.4V18072.4a2.4,2.4,0,0,1,2.4-2.4h19.2a2.406,2.406,0,0,1,2.4,2.4v19.193A2.406,2.406,0,0,1,17068.6,18094Zm-13.352-15.615h.117c.566,1.443,1.176,2.959,1.816,4.232l4.635,8.98h4.6v-18.742h-4.232v6.309a56.308,56.308,0,0,0,.473,6.023l.008.043c.031.252.066.555.1.838h-.111c-.57-1.441-1.18-2.947-1.844-4.23l-4.611-8.982h-4.635v18.742h4.26v-6.3a54.353,54.353,0,0,0-.469-6l-.006-.029-.018-.168c-.029-.242-.059-.48-.082-.715Z"
                                            transform="translate(-17047.002 -18069.998)"
                                            fill="#999b9e"
                                        />
                                    </svg>
                                    NOC
                                </NavLink>
                            </li>
                            <li className="messages">
                                <NavLink to="/create-map" onClick={resetDashboardId}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24.002"
                                        height="24"
                                        viewBox="0 0 24.002 24"
                                    >
                                        <g id="map_plus" data-name="map plus" opacity="0.8">
                                            <rect
                                                id="boundary"
                                                width="24"
                                                height="24"
                                                fill="#fff"
                                                opacity="0"
                                            />
                                            <path
                                                id="Path_map-plus"
                                                data-name="Path / map-plus"
                                                d="M10.2,3,3.432,5.28A.62.62,0,0,0,3,5.856V24a.6.6,0,0,0,.6.6.388.388,0,0,0,.192-.036L10.2,22.08l4.992,1.752A7.4,7.4,0,0,1,15,22.2a5,5,0,0,1,.048-.84L10.2,19.68V5.4l7.2,2.52v8.952A7.184,7.184,0,0,1,22.2,15a6.971,6.971,0,0,1,2.4.432V3.6A.6.6,0,0,0,24,3h-.192L17.4,5.52,10.2,3M21,17.4V21H17.4v2.4H21V27h2.4V23.4H27V21H23.4V17.4Z"
                                                transform="translate(-2.998 -3)"
                                                fill="#999b9e"
                                            />
                                        </g>
                                    </svg>
                                    Add map
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="sidenav-subtwo">
                        <ul>
                            <li className="manage-users">
                                <NavLink to="manage-users" onClick={resetDashboardId}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            id="Manage_users-off"
                                            data-name="Manage users-off"
                                            d="M7,21.667c0-2.667,5.333-4.133,8-4.133S23,19,23,21.667V23H7M19,11a4,4,0,1,1-4-4,4,4,0,0,1,4,4M3,5.667V24.333A2.667,2.667,0,0,0,5.667,27H24.333A2.667,2.667,0,0,0,27,24.333V5.667A2.667,2.667,0,0,0,24.333,3H5.667A2.666,2.666,0,0,0,3,5.667Z"
                                            transform="translate(-3 -3)"
                                            fill="#999b9e"
                                        />
                                    </svg>
                                    Manage users
                                </NavLink>
                            </li>
                            <li className="billing">
                                <NavLink to="/billing" onClick={resetDashboardId}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="21"
                                        height="24"
                                        viewBox="0 0 21 24"
                                    >
                                        <path
                                            id="Billing-off"
                                            d="M5.211,11.786v8H8.526v-8Zm6.632,0v8h3.316v-8ZM3,25.5H24V22.071H3ZM18.474,11.786v8h3.316v-8ZM13.5,1.5,3,7.214V9.5H24V7.214Z"
                                            transform="translate(-3 -1.5)"
                                            fill="#999b9e"
                                        />
                                    </svg>
                                    Billing
                                </NavLink>
                            </li>
                            <li className="notifications">
                                <NavLink to="#" onClick={handleNotifications}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="24"
                                        viewBox="0 0 20 24"
                                    >
                                        <path
                                            id="Notification-off"
                                            d="M16,27.75a2.488,2.488,0,0,0,2.5-2.462h-5A2.48,2.48,0,0,0,16,27.75Zm7.5-7.385V14.212c0-3.778-2.05-6.942-5.625-7.778V5.6a1.875,1.875,0,0,0-3.75,0v.837C10.537,7.27,8.5,10.421,8.5,14.212v6.154L6,22.827v1.231H26V22.827Z"
                                            transform="translate(-6 -3.75)"
                                            fill="#999b9e"
                                        />
                                    </svg>
                                    Notifications
                                    {unReadCount !== 0 && NotificationsLength?.length !== 0 && (
                                        <div className="notification-circle flex-center">
                                            {unReadCount}
                                        </div>
                                    )}
                                    {/* {unReadCount !== 0 && (
                                        <div className="notification-circle flex-center"></div>
                                    )} */}
                                </NavLink>
                            </li>
                            <li className="how-to">
                                <NavLink to="#" onClick={handlHowToModel}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            id="Help-off"
                                            d="M17.684,13.1,16.6,14.2a4.069,4.069,0,0,0-1.4,3.4H12.8V17a4.787,4.787,0,0,1,1.4-3.4l1.488-1.512A2.346,2.346,0,0,0,16.4,10.4a2.4,2.4,0,0,0-4.8,0H9.2a4.8,4.8,0,0,1,9.6,0,3.84,3.84,0,0,1-1.116,2.7M15.2,22.4H12.8V20h2.4M14,2A12,12,0,1,0,26,14,12,12,0,0,0,14,2Z"
                                            transform="translate(-2 -2)"
                                            fill="#999b9e"
                                        />
                                    </svg>
                                    How to
                                </NavLink>
                            </li>
                            <li className="profile">
                                <NavLink
                                    onClick={resetDashboardId}
                                    to="/profile"
                                    style={{ textTransform: "capitalize" }}
                                >
                                    {userImage ? (
                                        <>
                                            {" "}
                                            <img src={userImage} alt="user" />
                                        </>
                                    ) : (
                                        <>
                                            {" "}
                                            <img src={user?.avatar || dummyUser} alt="user" />
                                        </>
                                    )}
                                    <span>
                                        {user.first_name} {user.last_name}
                                    </span>
                                </NavLink>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <Link onClick={handleLogout}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            id="Log_out-off"
                                            data-name="Log out-off"
                                            d="M17.773,19.787l3.453-3.453H8.333V13.667H21.227l-3.453-3.453,1.893-1.88L26.333,15l-6.667,6.667-1.893-1.88M24.333,3A2.667,2.667,0,0,1,27,5.667v6.227L24.333,9.227V5.667H5.667V24.333H24.333v-3.56L27,18.107v6.227A2.667,2.667,0,0,1,24.333,27H5.667A2.666,2.666,0,0,1,3,24.333V5.667A2.657,2.657,0,0,1,5.667,3Z"
                                            transform="translate(-3 -3)"
                                            fill="#999b9e"
                                        />
                                    </svg>
                                    Log out
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </aside>
        </>
    );
};

export default Sidebar;
