/* global google*/

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Drop from "./Drop";
import CreateMapView from "./CreateMapView";
import { tsvData, groupedData, rawData } from "../../../utils/constants";
import { filterHeaders, setMapBounds } from "../../../utils/mapUtils";
import {
    changeToGroup,
    makeDeepCopy,
    idGenerator,
    getMandatoryHeaders,
} from "../../../utils/utils";
import { setMapType } from "../../../store/slicers/userMapSlice";
import socket, { addSetSocketMap, deleteSocketMap } from "../../../socket/socket";
import {
    CANCEL_MAP_SOCKET,
    CREATE_MAP_SOCKET,
    UPDATE_MAP_LOCATION,
} from "../../../socket/socketsList";
import { errorMessage, successMessage, warningLinkMessage } from "../../../utils/messages";
import { useCreateMapMutation, useGetAllMapsQuery } from "../../../services/baseApi";
import LoadingAnimation from "../../../components/LoadingAnimation/LoadingAnimation";
import { MAP_IMAGES } from "../../../config";
import { setCurrentMapDashboardId } from "../../../store/slicers/mapDashboardSlice";
import { getUserPlanData } from "../../../store/slicers/authSlice";
import { setMapLayerType } from "../../../store/slicers/mapLayerSlice";

const CreateMap = (props) => {
    const authUser = useSelector((state) => state.auth.user);
    const UserPlanData = useSelector(getUserPlanData);

    const { headers, setHeaders } = props;

    const currentDate = new Date();

    const options = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        month: "short",
        day: "numeric",
        year: "numeric",
    };

    const formattedDate = currentDate.toLocaleString("en-US", options);

    const refState = useRef({
        title: formattedDate,
        description: "",
    });

    const zoomRef = useRef({
        maxZoomOut: 2,
        defaultZoom: true,
        maxZoomIn: 24,
    });

    const socketDataRef = useRef({
        current: 0,
    });

    const { id } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [customMap, setCustomMap] = useState(true);

    const updatingRef = useRef(false);
    const [changedRow, setChangedRow] = useState([]);
    const [locUpdatingRef, setLocUpdatingRef] = useState(false);

    const [createMap] = useCreateMapMutation();

    const [createMapState, setCreateMapState] = useState({
        id: idGenerator(),

        loading: false,
        saveLoading: false,
        updateLoading: false,

        status: "START",

        tsvData,
        rawData,
        groupedData,
        dropFileName: "",

        successRows: [],
        failedRows: [],

        email: authUser?.email,
        description: refState.current.description,
        access: "unlisted",
        viewSearch: "disabled",
        projectImplementation: "disabled",
        dataDisplay: "mapdata",
        password: "",
        view: "table",

        map: {
            mapRef: null,
            showMap: false,
        },

        standardOptions: {
            region: "international",
            state_province: "STATE",
            address_location: "ADDRESS",
            zip_postcode: "ZIP",
            city_country: "CITY",
            phone: "PHONE",
            groupby_thematicValue: "GROUP",
            leadBySource: "LEADBYSOURCE",
            stage: "STAGE",
            opportunity: "OPPORTUNITIES",
        },

        advanceOptions: {
            name: "NAME",
            email: "EMAIL",
            url: "URL",

            lng: "",
            lat: "",
            markerDescription: "",

            markerStyle: "default",
            mapStyle: "simple",
            markerLabel: "letters",
            mapView: "satellite",
            distance: "mile",

            clustering: false,
            limitZoom: false,
            disableKML: false,
            openLinkInNew: false,
            groupsIntoRanges: false,
            filterRoadType: false,
        },
    });

    const [tableRowsData, setTableRowsData] = useState([]);
    const [rawDataIds, setRawDataIds] = useState({
        delSelected: [],
        insertSelected: [],
    });

    const {
        isLoading: allMapsLoading,
        data: allMapsData,
        error: allMapsError,
    } = useGetAllMapsQuery(authUser?.email);

    const handleMapNow = () => {
        try {
            if (allMapsData && allMapsData?.length >= Number(UserPlanData?.maps)) {
                return warningLinkMessage("map limit exceeds");
            }
            let newRawData = makeDeepCopy(createMapState.rawData);

            let newState = {};

            // IF USER IS CREATING MAP (NOT UPDATING)
            if (!updatingRef.current) {
                let { orgHeaders, newHeaders } = headers;

                // SETTING NEW HEADERS
                newRawData?.forEach((row) => {
                    let rowsHeaders = Object.keys(row);
                    orgHeaders?.forEach((orgHeader, i) => {
                        let index = rowsHeaders.indexOf(orgHeader);
                        if (index !== -1 && orgHeader !== newHeaders[index]) {
                            row[newHeaders[i]] = row[orgHeader];
                            row[orgHeader] = undefined;
                            delete row[orgHeader];
                        }
                    });
                });

                let rawHeaders = Object.keys(newRawData[0] || []);

                let mandatoryHeaders = getMandatoryHeaders(rawHeaders);

                newRawData?.forEach((row) => {
                    row.address = row[mandatoryHeaders.address];
                    row.city = row[mandatoryHeaders.city];
                    row.zip = row[mandatoryHeaders.zip];
                    row.state = row[mandatoryHeaders.state];

                    // BY DEFAULT SETTING
                    row.name = row[mandatoryHeaders.name];
                    row.email = row[mandatoryHeaders.email];
                    row.phone = row[mandatoryHeaders.phone];
                    row.url = row[mandatoryHeaders.url];

                    row.note = row[mandatoryHeaders.note];
                    row.opportunities = row[mandatoryHeaders.opportunities];
                });

                setCreateMapState((prevState) => {
                    let group = newHeaders.includes("GROUP") ? "GROUP" : "";
                    let groupedData = changeToGroup(newRawData, group);

                    newState = {
                        ...prevState,
                        status: "PENDING",
                        loading: true,
                        successRows: [],
                        failedRows: [],

                        map: {
                            ...createMapState.map,
                            showMap: true,
                        },

                        rawData: newRawData,
                        groupedData,

                        standardOptions: {
                            ...prevState.standardOptions,
                            groupby_thematicValue: group,

                            address_location: mandatoryHeaders.address,
                            city_country: mandatoryHeaders.city,
                            zip_postcode: mandatoryHeaders.zip,
                            state_province: mandatoryHeaders.state,
                            phone: mandatoryHeaders.phone,
                        },

                        advanceOptions: {
                            ...prevState.advanceOptions,
                            name: mandatoryHeaders.name,
                            email: mandatoryHeaders.email,
                            url: mandatoryHeaders.url,
                        },
                    };
                    return newState;
                });

                addSetSocketMap(newState);
            } else {
                newRawData?.forEach((row) => {
                    row.address = row[createMapState.standardOptions.address_location];
                    row.city = row[createMapState.standardOptions.city_country];
                    row.zip = row[createMapState.standardOptions.zip_postcode];
                    row.state = row[createMapState.standardOptions.state_province];
                    row.phone = row[createMapState.standardOptions.phone];
                    row.leadBySource = row[createMapState.standardOptions.leadBySource];

                    row.name = row[createMapState.advanceOptions.name];
                    row.email = row[createMapState.advanceOptions.email];
                    row.url = row[createMapState.advanceOptions.url];
                });

                setCreateMapState((prevState) => {
                    let headers = Object.keys(prevState?.rawData?.[0] || []);

                    let group = headers.includes(
                        createMapState.standardOptions.groupby_thematicValue
                    )
                        ? createMapState.standardOptions.groupby_thematicValue
                        : "";

                    newState = {
                        ...prevState,
                        updateLoading: true,
                        status: "PENDING",
                        successRows: [],
                        failedRows: [],
                        groupedData: changeToGroup(newRawData, group),
                        rawData: newRawData,
                    };
                    return newState;
                });
                addSetSocketMap(newState);
            }

            // return;
            socket.emit(CREATE_MAP_SOCKET, {
                mapID: createMapState.id,
                socketID: socket.id,
                data: newRawData,
            });
        } catch (err) {
            console.log("ERR: ", err);
            if (updatingRef.current) {
                let newMap = {};

                setCreateMapState((prevState) => {
                    let headers = Object.keys(prevState?.rawData?.[0] || []);
                    let group = headers.includes(
                        createMapState.standardOptions.groupby_thematicValue
                    )
                        ? createMapState.standardOptions.groupby_thematicValue
                        : "";

                    newMap = {
                        ...prevState,
                        successRows: [],
                        groupedData: changeToGroup(createMapState.rawData, group),
                        failedRows: makeDeepCopy(createMapState.rawData),
                    };

                    return newMap;
                });
                addSetSocketMap(newMap);
            } else {
                let newMap = {};
                setCreateMapState((prevState) => {
                    let headers = Object.keys(prevState?.rawData?.[0] || []);
                    let group = headers.includes("GROUP") ? "GROUP" : "";

                    newMap = {
                        ...prevState,
                        loading: false,
                        successRows: [],
                        groupedData: changeToGroup(err.rawData, group),
                        failedRows: makeDeepCopy(err.rawData),
                        rawData: makeDeepCopy(err.rawData),
                        map: {
                            ...prevState.map,
                            showMap: true,
                        },
                    };

                    return newMap;
                });

                addSetSocketMap(newMap);
            }
        }
    };

    const handleCancel = () => {
        setHeaders((prevState) => {
            return {
                ...prevState,
                newHeaders: filterHeaders(Object.keys(rawData?.[0]) || []),
                orgHeaders: filterHeaders(Object.keys(rawData?.[0]) || []),
            };
        });
        deleteSocketMap(createMapState.id);
        navigate("/dashboard");
    };

    const getUpdatedData = (updatedData) => {
        if (updatedData) {
            setCreateMapState((prevData) => {
                return {
                    ...prevData,
                    rawData: updatedData,
                };
            });

            let map = socket.maps?.find((map) => map.id === createMapState.id);
            if (map) {
                map.rawData = updatedData;
            }
        }
    };

    const generateBody = () => {
        let rawData = makeDeepCopy(createMapState.successRows);

        let id = createMapState.id;

        rawData?.forEach((data) => {
            data.phone = data[createMapState.standardOptions.phone] || "";
            data.leadBySource = data[createMapState.standardOptions.leadBySource] || "";
            data.url = data[createMapState.advanceOptions.url] || "";
            data.name = data[createMapState.advanceOptions.name] || "";
            data.email = data[createMapState.advanceOptions.email] || "";
        });

        const body = {
            title: refState.current.title,
            email: createMapState.email,
            owner_name: `${authUser.first_name} ${authUser.last_name}`,
            description: refState.current.description,
            access: createMapState.access,
            password: createMapState.access === "password" ? createMapState.password : "",
            render_method: "mapdata",
            shared: [],
            view: createMapState.view,
            mapImage: MAP_IMAGES[Math.floor(Math.random() * 10)],
            mapData: {
                rawData,
                groupedData: createMapState.groupedData,

                // STANDARD OPTIONS
                region: createMapState.standardOptions.region,
                city: createMapState.standardOptions.city_country,
                state: createMapState.standardOptions.state_province,
                address: createMapState.standardOptions.address_location,
                zip: createMapState.standardOptions.zip_postcode,
                group: createMapState.standardOptions.groupby_thematicValue || "NO_GROUP",
                phone: createMapState.standardOptions.phone,
                leadBySource: createMapState.standardOptions.leadBySource,

                // ADVANCE OPTIONS
                name: createMapState.advanceOptions.name,
                description: createMapState.advanceOptions.markerDescription,
                url: createMapState.advanceOptions.url,
                email: createMapState.advanceOptions.email,

                pinShape: "PRE FILLED",
                mapType: "street",
                targetLink: "_blank",
                markerLabel: "none",
                mapStyles: "PRE FILLED",
                imageURL: "no need",

                longitude: createMapState.advanceOptions.lng,
                latitude: createMapState.advanceOptions.lat,
                markerStyle: createMapState.advanceOptions.markerStyle,
                mapStyle: createMapState.advanceOptions.mapStyle,
            },
        };

        return { body, id };
    };

    const handleSaveMapSubmit = async (e) => {
        e.preventDefault();

        if (!createMapState.successRows.length) {
            errorMessage("No location found.");
            return;
        }

        if (!refState.current.title.trim()) {
            errorMessage("Map title is required.");

            return;
        }

        if (createMapState.access === "password") {
            if (!createMapState.password) {
                errorMessage("Password cannot be empty.");
                return;
            }
        }

        setCreateMapState((prevState) => {
            return {
                ...prevState,
                saveLoading: true,
            };
        });

        let { body, id } = generateBody();

        body.mapData.groupedData = changeToGroup(
            body.mapData.rawData,
            createMapState.standardOptions.groupby_thematicValue
        );
        const { data, error } = await createMap(body);
        if (error) {
            errorMessage(error.data?.message || error.message);
        }
        if (data) {
            setTableRowsData(createMapState?.rawData);
            successMessage("Successfully created");
            dispatch(setCurrentMapDashboardId(data?._id));
            deleteSocketMap(id);
            dispatch(setMapType(1));
            navigate("/dashboard");
        }
    };

    useEffect(() => {
        socket.child = true;

        if (id) {
            let map = socket.maps?.find((map) => map.id === id);

            if (map) {
                setCreateMapState({
                    ...map,
                    loading: false,
                    map: {
                        ...map.map,
                        showMap: true,
                    },
                });
                setCustomMap(false);
            } else {
                navigate("/create-map");
            }
        } else {
            setCustomMap(false);
        }

        socket.on(CREATE_MAP_SOCKET, async (res) => {
            if (socket.child) {
                if (socket.id === res.socketID) {
                    if (createMapState.id === res.mapID) {
                        socketDataRef.current = res;

                        if (res.completed) {
                            let map = socket.maps.find((map) => map.id === res.mapID);
                            map.successRows = [...res.data];

                            const processRows = async (map) => {
                                const allSuccessRows = [];
                                const allFailedRows = [];
                                const locationsNotFoundIDs = [];

                                if (map?.successRows?.length) {
                                    await Promise.all(
                                        map.successRows.map((item) => {
                                            return new Promise((resolve) => {
                                                if (item?.location) {
                                                    allSuccessRows.push(item);
                                                } else {
                                                    allFailedRows.push(item);
                                                    locationsNotFoundIDs.push(item.id);
                                                }
                                                resolve();
                                            });
                                        })
                                    );
                                }
                                return { allSuccessRows, allFailedRows, locationsNotFoundIDs };
                            };

                            const { allSuccessRows, allFailedRows, locationsNotFoundIDs } =
                                await processRows(map);

                            if (map && !map.successRows.length) {
                                let newMap = {};
                                setCreateMapState((prevState) => {
                                    newMap = {
                                        ...prevState,
                                        loading: false,
                                        updateLoading: false,
                                        successRows: [],
                                        failedRows: makeDeepCopy(map.rawData),
                                        rawData: makeDeepCopy(map.rawData),
                                        status: "COMPLETED",
                                        map: {
                                            ...prevState.map,
                                            showMap: true,
                                        },
                                    };
                                    map = newMap;
                                    return newMap;
                                });
                                return;
                            }

                            // Save data and show map
                            setCreateMapState((prevState) => {
                                let newState = {
                                    ...prevState,
                                    loading: false,
                                    updateLoading: false,
                                    status: "COMPLETED",
                                    successRows: makeDeepCopy(allSuccessRows),
                                    failedRows: makeDeepCopy(allFailedRows),
                                };
                                map = newState;
                                addSetSocketMap(map);
                                setTableRowsData(newState.successRows);
                                return newState;
                            });
                            // setRawDataIds((prev) => {
                            //     const mergedData = [
                            //         ...new Set([...locationsNotFoundIDs, ...prev.insertSelected]),
                            //     ];
                            //     return {
                            //         ...prev,
                            //         insertSelected: mergedData,
                            //     };
                            // });

                            setTimeout(() => {
                                setMapBounds(
                                    map.map.mapRef,
                                    map.successRows,
                                    zoomRef,
                                    window.google?.maps?.LatLngBounds
                                );
                            }, 0);
                        } else {
                            let newMap = {};
                            let map = socket.maps.find((map) => map.id === res.mapID);

                            if (map) {
                                if (res.data) {
                                    map.successRows = [...res.data];
                                }
                            }

                            if (!updatingRef.current) {
                                setCreateMapState((prevState) => {
                                    if (res.data?.location) {
                                        newMap = {
                                            ...prevState,
                                            successRows: [...prevState.successRows, res.data],
                                        };
                                    } else {
                                        newMap = {
                                            ...prevState,
                                            failedRows: [...prevState.failedRows, res.data],
                                        };
                                    }
                                    setTableRowsData(newMap.successRows);
                                    return newMap;
                                });
                            }
                        }
                        socket.resetLogoutTimer();
                    }
                }
            }
        });

        return () => {
            socket.child = false;
        };
    }, []);

    const handleCancelMap = () => {
        let mapID = createMapState?.id;
        socket.emit(CANCEL_MAP_SOCKET, { mapID, socketID: socket.id });
        if (createMapState?.status !== "COMPLETED") {
            navigate("/dashboard");
        }
    };

    const setCurrentUpdateData = (index, rowData) => {
        if (index !== undefined && rowData) {
            setChangedRow((prev) => {
                let rowItemIndex = prev.findIndex((item) => item?.rowData?.id === rowData?.id);
                let newObj = { rowId: rowData?.id, rowData: rowData };

                if (rowItemIndex === -1) {
                    return [...prev, newObj];
                } else {
                    let newArray = [...prev];
                    newArray[rowItemIndex] = newObj;
                    return newArray;
                }
            });
        }
    };

    useEffect(() => {
        socket.child = true;

        const handleUpdateMapLocation = (res) => {
            if (!socket.child || socket.id !== res.socketID) return;

            if (res.completed && locUpdatingRef && res.data) {
                const updatedData = res.data;
                const copyRawData = makeDeepCopy(tableRowsData);
                const locationNotFound = [];
                const failedRowData = [...createMapState.failedRows];

                // Process the updated data
                updatedData.forEach((item) => {
                    const rowIndex = copyRawData.findIndex((row) => row.id === item.id);
                    if (rowIndex !== -1) copyRawData[rowIndex] = item;

                    if (
                        item?.location === null &&
                        !failedRowData.some((row) => row.id === item.id)
                    ) {
                        locationNotFound.push(item.id);
                        // failedRowData.push(item);
                    }

                    // Remove successfully updated rows from insertSelected
                    if (rawDataIds?.insertSelected?.includes(item.id)) {
                        setRawDataIds((prev) => ({
                            ...prev,
                            insertSelected: prev.insertSelected.filter((id) => id !== item.id),
                        }));
                    }
                });

                let filteredData = copyRawData.filter(
                    (row) => row.location && row.location !== null
                );

                // Update state with the processed data
                setCreateMapState((prevState) => ({
                    ...prevState,
                    loading: false,
                    updateLoading: false,
                    updateLocations: true,
                    status: "COMPLETED",
                    rawData: filteredData,
                    successRows: filteredData,
                    // failedRows: failedRowData,
                    groupedData: changeToGroup(copyRawData, "GROUP" || ""),
                }));

                setRawDataIds((prev) => ({
                    ...prev,
                    insertSelected: [...new Set([...locationNotFound, ...prev.insertSelected])],
                }));

                // Final updates
                setLocUpdatingRef(false);
                setTableRowsData(copyRawData);
                setChangedRow([]);

                socket.resetLogoutTimer();
            }
        };

        socket.on(UPDATE_MAP_LOCATION, handleUpdateMapLocation);

        return () => {
            socket.child = false;
            socket.off(UPDATE_MAP_LOCATION, handleUpdateMapLocation);
        };
    }, [locUpdatingRef]);

    useEffect(() => {
        if (changedRow?.length > 0) {
            setCreateMapState((prevState) => {
                return {
                    ...prevState,
                    updateLocations: false,
                };
            });
        }
    }, [changedRow?.length]);

    useEffect(() => {
        dispatch(setMapLayerType(0));
    }, []); // for heat map layer

    return (
        <>
            <section className="createMap--container">
                {customMap ? (
                    <LoadingAnimation />
                ) : createMapState.map.showMap ? (
                    createMapState.loading ? (
                        <LoadingAnimation
                            rawDataLength={createMapState.rawData.length}
                            fileName={createMapState?.dropFileName}
                            successRowsLength={socketDataRef.current.current}
                            showContent={true}
                            handleCancelMap={handleCancelMap}
                        />
                    ) : (
                        <section className="pos-rel" id="afterMap">
                            <CreateMapView
                                refState={refState.current}
                                createMapState={createMapState}
                                setCreateMapState={setCreateMapState}
                                // ------------
                                handleSaveMapSubmit={handleSaveMapSubmit}
                                handleMapNow={handleMapNow}
                                handleCancel={handleCancel}
                                updatingRef={updatingRef}
                                zoomRef={zoomRef}
                                changedRow={changedRow}
                                setChangedRow={setChangedRow}
                                setTableRowsData={setTableRowsData}
                                tableRowsData={tableRowsData}
                                setCurrentUpdateData={setCurrentUpdateData}
                                setLocUpdatingRef={setLocUpdatingRef}
                                locUpdatingRef={locUpdatingRef}
                                rawDataIds={rawDataIds}
                                setRawDataIds={setRawDataIds}
                            />
                        </section>
                    )
                ) : (
                    <Drop
                        createMapState={createMapState}
                        setCreateMapState={setCreateMapState}
                        getUpdatedData={getUpdatedData}
                        headers={headers}
                        setHeaders={setHeaders}
                        // ------------
                        handleSaveMapSubmit={handleSaveMapSubmit}
                        handleMapNow={handleMapNow}
                        handleCancel={handleCancel}
                        updatingRef={updatingRef}
                    />
                )}
            </section>
        </>
    );
};

export default CreateMap;
