import React from "react";
import { capitalizeString } from "../../../utils/utils";
import videoCallimg from "../../../assets/images/videoCallimg.png";

const MarkerBoxPreview = (props) => {
    const { info, markerOptionsState } = props;

    return (
        <section className="createMap-marker-box vanishIn">
            <h6>Map marker preview</h6>
            <div className="markerbox-preview-container shadow-all-blue">
                <div className="marker-box-header">
                    <h4> {capitalizeString(info?.name)}</h4>
                    <p>
                        {capitalizeString(info?.address ? `${info?.address},` : "")}{" "}
                        {capitalizeString(info?.city ? `${info?.city},` : "")}{" "}
                        {capitalizeString(info?.state ? `${info?.state},` : "")}{" "}
                        {capitalizeString(info?.zip ? `${info?.zip}` : "")}
                    </p>
                </div>
                <div className="marker-box-body">
                    <ul>
                        {info?.city && (
                            <li>
                                City: <span>{capitalizeString(info?.city)}</span>
                            </li>
                        )}
                        {info?.phone && (
                            <li>
                                Phone: <span>{info?.phone}</span>
                            </li>
                        )}

                        {info?.group && (
                            <li>
                                Group:{" "}
                                <span style={{ textTransform: "uppercase" }}>{info?.group}</span>
                            </li>
                        )}

                        <li>
                            URL: <span style={{ textTransform: "lowercase" }}>{info?.url}</span>
                        </li>
                    </ul>
                </div>
            </div>
            {/* {markerOptionsState?.options === "standard" && (
                <div className="createMap-video--container">
                    <img src={videoCallimg} alt="video call" />
                    <p>Big video coming</p>
                </div>
            )} */}
        </section>
    );
};

export default MarkerBoxPreview;
