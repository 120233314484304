import React from "react";
import dummyUser from "../../assets/images/avatar-grey.png";

const ImageListView = (props) => {
    if (!props?.data?.length) {
        return <></>;
    }

    const { data } = props;
    const limitImg = 5;

    const handleGetUserImages = (data, index) => {
        return data?.avatar && data?.avatar !== "null" ? (
            <img
                src={data?.avatar || dummyUser}
                className={`myMap-sharedlist-img 
                `}
                alt="shared-user"
                key={index}
            />
        ) : (
            <span className="user-initials-circle" key={index}>
                {data.first_name?.[0]}
                {data.last_name?.[0]}
            </span>
        );
    };

    return (
        <div className="myMapList-people-shared-excerpt">
            <div className="myMapList-people-shared">
                {data?.length < limitImg ? (
                    data?.map((data, index) => handleGetUserImages(data, index))
                ) : (
                    <>
                        {data?.slice(0, limitImg).map((data, index) => (
                            <span
                                className="user-initials-circle"
                                style={{ zIndex: data?.length - index || 1 }}
                                key={index}
                            >
                                {handleGetUserImages(data, index)}
                            </span>
                        ))}

                        {data?.slice(limitImg).length === 0 ? (
                            <></>
                        ) : (
                            <div className="myMapList-people-maxcount">
                                <span>+{data?.slice(limitImg).length}</span>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ImageListView;
