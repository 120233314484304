import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Footer from "../../components/Sidebar/Footer";

import PaymentCard from "./PaymentCard";
import plusIcon from "../../assets/images/plus-icon.png";
import TransactionTable from "./TransactionTable";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripePaymentForm from "./StripePaymentForm";
import { PUBLIC_KEY_STRIPE } from "../../config";
import { useGetAllBillingCardsQuery, useSubscribePlanMutation } from "../../services/baseApi";
import { errorMessage } from "../../utils/messages";
import { useEffect } from "react";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import BillingCard from "./BillingCard";
import { makeDeepCopy } from "../../utils/utils";

const Billing = () => {
    const { user } = useSelector((state) => state.auth);
    const authPlan = useSelector((state) => state.auth.plan);
    const { planDetails } = useSelector((state) => state.planDetails);

    const location = useLocation();
    const { state } = location;

    const stripeTestPromise = loadStripe(PUBLIC_KEY_STRIPE);

    const [billingState, setbillingState] = useState({
        openCardPaymentModal: false,
        loading: false,
        paymentCardsData: {},
        paymentCustomerData: {},
        confirmPassword: "",
        openSubscribeModal: false,
        planModalOpen: "",
    });

    const { data: allPaymentCards, error: allPaymentCardsError } = useGetAllBillingCardsQuery(
        user.email,
        { skip: !user?.email }
    );

    const sortedPlanDetails = makeDeepCopy(planDetails);

    useEffect(() => {
        setbillingState((prevState) => {
            return {
                ...prevState,
                openSubscribeModal: state?.openSubscribeModal || "",
                planModalOpen: state?.planModalOpen || "",
            };
        });
    }, [state]);

    useEffect(() => {
        if (allPaymentCards) {
            setbillingState((prevState) => {
                return {
                    ...prevState,
                    paymentCardsData: allPaymentCards.data,
                    paymentCustomerData: allPaymentCards.customer,
                };
            });
        }
        if (allPaymentCardsError) {
            errorMessage(allPaymentCardsError.data.message);
        }
    }, [allPaymentCards, allPaymentCardsError]);

    const selectedPlanIndex = sortedPlanDetails?.find((item) => item.planName === authPlan);

    return (
        <>
            {!allPaymentCards ? (
                <LoadingAnimation />
            ) : (
                <section className="billing-main">
                    <section className="billing-container">
                        <div className="welcome-note">
                            <p>Welcome</p>
                            <h2>
                                {user?.first_name} {user?.last_name}
                            </h2>
                        </div>

                        <div className="billing-cards-wrapper">
                            {sortedPlanDetails
                                ?.sort((a, b) => a.position - b.position)
                                .slice(1)
                                .map((item, index) => {
                                    return (
                                        <BillingCard
                                            userPlan={authPlan}
                                            billingState={billingState}
                                            setbillingState={setbillingState}
                                            data={item}
                                            key={index}
                                            index={index}
                                            selectedPlanIndex={selectedPlanIndex}
                                            stripeTestPromise={stripeTestPromise}
                                        />
                                    );
                                })}
                        </div>
                        <div className="payment-history-wrapper">
                            <h2>Payment method: </h2>
                            <div className="payment-method-wrapper">
                                <div className="payment-cards-wrapper">
                                    {billingState?.paymentCardsData?.length ? (
                                        billingState?.paymentCardsData.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={
                                                        item.id ===
                                                        billingState?.paymentCustomerData
                                                            ?.invoice_settings
                                                            ?.default_payment_method
                                                            ? "primary-card"
                                                            : ""
                                                    }
                                                >
                                                    <PaymentCard
                                                        cardId={item.id}
                                                        customerID={
                                                            billingState.paymentCustomerData.id
                                                        }
                                                        name={item.billing_details.name}
                                                        expMonth={item.card.exp_month}
                                                        expYear={item.card.exp_year}
                                                        cardnumber={item.card.last4}
                                                        primary={
                                                            item.id ===
                                                            billingState?.paymentCustomerData
                                                                ?.invoice_settings
                                                                ?.default_payment_method
                                                        }
                                                        brand={item.card.brand}
                                                        key={index}
                                                    />
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <> </>
                                    )}
                                </div>
                                <div style={{ marginBottom: "18px" }}>
                                    <button
                                        className="new-payment-card-wrapper"
                                        onClick={() =>
                                            setbillingState((prevState) => {
                                                return {
                                                    ...prevState,
                                                    openCardPaymentModal: true,
                                                };
                                            })
                                        }
                                    >
                                        <img src={plusIcon} alt="add card" style={{ width: 17 }} />{" "}
                                        <span>Add credit or debit card</span>
                                    </button>
                                </div>
                            </div>

                            {!!billingState.openCardPaymentModal && (
                                <div className="stripe-card-credentials-wrapper">
                                    <Elements stripe={stripeTestPromise}>
                                        <StripePaymentForm setbillingState={setbillingState} />
                                    </Elements>
                                </div>
                            )}
                        </div>
                        <div className="transaction-history-wrapper">
                            <TransactionTable email={user.email} />
                        </div>
                    </section>
                    <Footer />
                </section>
            )}
        </>
    );
};

export default Billing;
