import React, { useState } from "react";

const EditMapConfirmModel = (props) => {
    const {
        historyState,
        handleSaveUpdates,
        handleEditMapModel,
        handleConfirmSaveUpdate,
        cancelMapDataUpadte,
        handleDataUpdate,
        changedRow,
    } = props;

    const [checkboxes, setCheckboxes] = useState(
        historyState?.mapOutPins?.map(() => true) // Assume all checkboxes are selected initially
    );

    const [selectAll, setSelectAll] = useState(true);

    const handleSavePinsOut = () => {
        const selectedPins = historyState?.mapOutPins?.filter((_, index) => checkboxes[index]);
        const filteredChangedRow = historyState?.allUpdatedPins
            .map((changedItem) => changedItem.rowData)
            .filter(
                (changedItem) =>
                    !historyState?.mapOutPins.some(
                        (selectedItem) => selectedItem.id === changedItem.id
                    )
            );
        const mergedPins = [...selectedPins, ...filteredChangedRow];

        const uniquePins = Array.from(new Map(mergedPins.map((pin) => [pin.id, pin])).values());
        handleDataUpdate(uniquePins);
    };

    const handleSelectAll = () => {
        const newState = !selectAll;
        setSelectAll(newState);
        setCheckboxes(checkboxes.map(() => newState));
    };

    const handleCheckboxChange = (index) => {
        const updatedCheckboxes = [...checkboxes];
        updatedCheckboxes[index] = !updatedCheckboxes[index];
        setCheckboxes(updatedCheckboxes);
        setSelectAll(updatedCheckboxes.every((checked) => checked));
    };

    return (
        <div className="edit-warning-model">
            <div className="edit-warning-header">
                <div className="warn-heading">
                    <h2>
                        You are modifying markers linked to a different map. Would you like to
                        continue?
                    </h2>
                </div>
                <div className="group-checkbox-field">
                    <label class="custom-label clr-light" for="openLinkInNewWindow">
                        <input
                            type="checkbox"
                            name="openLinkInNewWindow"
                            id="openLinkInNewWindow"
                            checked={selectAll}
                            onChange={handleSelectAll}
                        />
                        <span>Select all markers</span>
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>
            <div className="edit-warning-body">
                {historyState?.mapOutPins && historyState?.mapOutPins?.length > 0
                    ? historyState?.mapOutPins?.map((pin, key) => (
                          <div className="group-checkbox-field pin-data" key={key}>
                              <label class="custom-label clr-light" for={`pin-${key}`}>
                                  <input
                                      type="checkbox"
                                      name={`pin-${key}`}
                                      id={`pin-${key}`}
                                      checked={checkboxes[key]}
                                      onChange={() => handleCheckboxChange(key)}
                                  />
                                  <span>{pin.name}</span>
                                  <span class="checkmark"></span>
                              </label>
                          </div>
                      ))
                    : null}
            </div>
            <div className="edit-warning-footer">
                <button className="cancel" onClick={cancelMapDataUpadte}>
                    Cancel
                </button>
                <button className="save" onClick={handleSavePinsOut}>
                    Save
                </button>
            </div>
        </div>
    );
};

export default EditMapConfirmModel;
