import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import Home from "./views/Home";
import Billing from "./views/billing/Billing";
import Profile from "./views/profile/Profile";
import NOC from "./views/noc/NOC";
import HowTo from "./views/howTo/HowTo";
import ManageUsers from "./views/manageUsers/ManageUsers";
import EditMap from "./views/dashboard/editMap/EditMap";
import Dashboard from "./views/dashboard/Dashboard";
import CreateMap from "./views/dashboard/createMap/CreateMap";
import PublicView from "./views/publicView/PublicView";
import ProtectedRoute from "./views/auth/ProtectedRoute";
import Forgetpassword from "./views/auth/Forgetpassword";
import ResetPasswordMessage from "./views/auth/ResetPasswordMessage";
import RegisterSuccess from "./views/auth/RegisterSuccess";
import ResetpasswordModal from "./views/auth/ResetpasswordModal";
import Login from "./views/auth/Login";
import Register from "./views/auth/Register";

import "./styles/generic.css";
import "./styles/app.css";
import "./assets/css/neumorphism.css";
import "./assets/css/@fortawesome/fontawesome-free/css/all.min.css";

import socket from "./socket/socket";
import { CREATE_MAP_SOCKET } from "./socket/socketsList";
import { changeToGroup, makeDeepCopy } from "./utils/utils";
import { rawData } from "./utils/constants";
import { filterHeaders } from "./utils/mapUtils";

import {
    getDistanceMeasurement,
    setTempPasswordAuth,
    setUser,
    setUserMeasurement,
    setUserPlan,
    setUserPlanData,
} from "./store/slicers/authSlice";
import {
    useGetPlanDetailsQuery,
    useGetUserByTokenQuery,
    useGetUserDataQuery,
    useGetUserPlanQuery,
} from "./services/baseApi";
import SpeedoMeterWebView from "./views/SpeedoMeterWebView";
import AcceptInvitaion from "./views/manageUsers/AcceptInvitaion";
import LoadingAnimation from "./components/LoadingAnimation/LoadingAnimation";
import { setPlanDetails } from "./store/slicers/planDetailsSlice";
import OneSignal from "react-onesignal";
import TemPasswordAuth from "./components/Modals/TemPasswordAuth";
import { TEMP_PASSWORD } from "./config";
import ShareLocationView from "./views/shareLocation/ShareLocationView";
import { setMapLayerType } from "./store/slicers/mapLayerSlice";

const App = (props) => {
    const dispatch = useDispatch();
    const [updateProgressBar, setUpdateProgressBar] = useState(false);
    const [canLeaveSite, setCanLeaveSite] = useState(true);

    const [initializedOneSignal, setInitializedOneSignal] = useState(false);

    useEffect(() => {
        let isSessionPass = sessionStorage.getItem("TempPasswordAuth");
        if (isSessionPass === null) {
            dispatch(setTempPasswordAuth(false));
        } else if (isSessionPass === TEMP_PASSWORD) {
            dispatch(setTempPasswordAuth(true));
        }
    }, []);

    const { TempPasswordAuth } = useSelector((state) => state.auth);

    // mapData Headers Dynamic
    const [headers, setHeaders] = useState({
        newHeaders: filterHeaders(Object.keys(rawData?.[0]) || []),
        orgHeaders: filterHeaders(Object.keys(rawData?.[0]) || []),
    });

    // MapData Headers Dynamic

    const { user, inactiveLogout } = useSelector((state) => state.auth);

    const { data, error } = useGetUserByTokenQuery();

    const measureDistanceType = useSelector(getDistanceMeasurement);
    const { data: userProfileData } = useGetUserDataQuery(user?.email, {
        skip: !user?.email,
    });

    useEffect(() => {
        if (userProfileData?.measureDistance !== measureDistanceType) {
            dispatch(setUserMeasurement(userProfileData?.measureDistance));
        }
    }, [measureDistanceType, dispatch, userProfileData?.measureDistance]);
    const initOneSignal = async () => {
        try {
            await OneSignal.init({
                appId: "3e64c592-c0b1-417b-bf7e-7639c7d70714",
                safari_web_id: "web.onesignal.auto.13d8bf97-93cf-4a09-b799-2a50baaf1ebd",
                allowLocalhostAsSecureOrigin: true,
                serviceWorkerParam: { scope: "/" },
                serviceWorkerPath: "/OneSignalSDKWorker.js",
                autoRegister: true,
                autoResubscribe: true,
            });
            setInitializedOneSignal(true);
        } catch (error) {
            setInitializedOneSignal(false);
            console.error("OneSignal initialization failed:", error);
        }
    };

    if (user) {
        if (!(OneSignal.User.PushSubscription.optedIn && OneSignal.Notifications.permission)) {
            OneSignal.Slidedown.promptPush();
        }
        const tags = { user_id: user?._id, email: user?.email };
        OneSignal.User.addTags(tags);
    }

    useEffect(() => {
        if (!initializedOneSignal) {
            initOneSignal();
        }
    }, [initializedOneSignal]);

    ////////////////////////////////////////////////

    useEffect(() => {
        if (error) {
            dispatch(setUser(null));
        }
        if (data) {
            dispatch(setUser(data));
        }
    }, [dispatch, data, error]);

    const { data: userPlanData } = useGetUserPlanQuery(user?.email, {
        skip: !user?.email,
    });
    const { data: planDetailsData } = useGetPlanDetailsQuery();

    useEffect(() => {
        if (userPlanData) {
            dispatch(setUserPlan(userPlanData?.plan || "FREE"));
        }
        if (planDetailsData) {
            dispatch(setPlanDetails(makeDeepCopy(planDetailsData)));
        }

        if (userPlanData && planDetailsData) {
            const element = planDetailsData?.find(
                (item, index) => item.planName === userPlanData?.plan
            );

            if (element) {
                let updatePlanData = makeDeepCopy(element);
                updatePlanData.subscriptionId = userPlanData?.subscriptionId;
                updatePlanData.endDate = userPlanData?.endDate;
                dispatch(setUserPlanData(updatePlanData));
            }
        }
        if (userPlanData === undefined && planDetailsData) {
            dispatch(setUserPlanData(planDetailsData[0]));
        }
    }, [dispatch, userPlanData, planDetailsData]);

    useEffect(() => {
        if (user) {
            socket.connect();

            socket.on(CREATE_MAP_SOCKET, (res) => {
                if (!socket.child) {
                    if (socket.id === res.socketID) {
                        setCanLeaveSite(false);
                        let index = socket.maps?.findIndex((map) => map.id === res.mapID);

                        if (index === -1) return;

                        let map = makeDeepCopy(socket?.maps?.[index]);

                        if (res.data?.location) {
                            map?.successRows?.push(res.data);
                        } else {
                            map?.failedRows?.push(res.data);
                        }

                        if (res.completed) {
                            const respHeaders = filterHeaders(Object.keys(map.rawData[0]));

                            let group = respHeaders.includes(
                                map.standardOptions.groupby_thematicValue
                            )
                                ? map.standardOptions.groupby_thematicValue
                                : "";

                            // 2.3) MAKE GROUP DATA
                            let groupedData = changeToGroup(map.rawData, group);

                            map = {
                                ...map,
                                loading: false,
                                status: "COMPLETED",
                                groupedData,
                            };
                            setCanLeaveSite(true);
                        }

                        socket.maps[index] = map;
                        setTimeout(() => {
                            socket.resetLogoutTimer();
                            setUpdateProgressBar((prevState) => !prevState);
                        }, 0);
                    }
                }
            });

            socket.on("disconnect", () => {});
        } else {
            socket.off("connect");
            socket.off("disconnect");
            socket.off(CREATE_MAP_SOCKET);

            socket.disconnect();
        }

        return () => {
            socket.off("connect");
            socket.off("disconnect");
            socket.off(CREATE_MAP_SOCKET);

            socket.disconnect();
        };
    }, [user]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (!canLeaveSite) {
                e.preventDefault();
                e.stopImmediatePropagation();
                const message = "Files are being updated and leaving will cancel this action";
                e.returnValue = "";
                return message;
            } else {
                console.log("User closed window while uploading a map");
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [canLeaveSite]);

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // const [excelData, setExcelData] = useState(null);

    // // Log the excelData for debugging
    // console.log("Excel Data:", excelData);

    // useEffect(() => {
    //     // Function to handle the message from the HTML page
    //     const handleMessage = (event) => {
    //         // Validate the origin of the message (for security)
    //         console.log("event", event);
    //         if (event.origin !== "http://127.0.0.1:5501") return console.log("Invalid origin");

    //         const { type, data } = event.data;
    //         if (type === "excelFileData") {
    //             // Process the base64 Excel data
    //             setExcelData(data); // Store the file data (base64)
    //         }
    //     };

    //     // Listen for the message
    //     window.addEventListener("message", handleMessage);

    //     // Clean up the event listener when the component unmounts
    //     return () => {
    //         window.removeEventListener("message", handleMessage);
    //     };
    // }, []);

    // const handleFileProcessing = () => {
    //     // Use a library like 'xlsx' or 'SheetJS' to process the Excel file
    //     if (excelData) {
    //         const byteArray = new Uint8Array(
    //             atob(excelData.split(",")[1])
    //                 .split("")
    //                 .map((c) => c.charCodeAt(0))
    //         );
    //         const file = new Blob([byteArray], {
    //             type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //         });
    //         const reader = new FileReader();
    //         reader.onload = function (e) {
    //             const data = e.target.result;
    //             // Now you can use 'xlsx' library or other methods to parse and handle the Excel data.
    //             console.log("Parsed Excel data:", data);
    //         };
    //         reader.readAsArrayBuffer(file);
    //     }
    // };
    // useEffect(() => {
    //     handleFileProcessing();
    // }, [excelData]);
    ////////////////////////////////////////////////////////////////////////////////////////////////

    if (!TempPasswordAuth) {
        return <TemPasswordAuth />;
    }

    if (!data && !error) return <LoadingAnimation />;

    return (
        <>
            <ToastContainer
                autoClose={inactiveLogout ? false : 3000}
                closeButton={false}
                position="bottom-center"
                hideProgressBar={true}
            />

            <Routes>
                <Route
                    path="/login"
                    element={
                        <ProtectedRoute isAllowed={!data} redirectedPath="/dashboard">
                            <Login />
                        </ProtectedRoute>
                    }
                />

                <Route path="/forget-password" element={<Forgetpassword />} />

                <Route path="/sign-up" element={<Register />} />

                <Route path="/ResetEmailSent" element={<ResetPasswordMessage />} />

                <Route path="/passwordReset" element={<ResetpasswordModal />} />

                <Route path="/map-view/:type/:id" element={<PublicView />} />
                <Route
                    path="/sharelocation-view/:id?/:pinId?/:email?"
                    element={<ShareLocationView />}
                />
                {/* <Route path="/sharelocation-view" element={<ShareLocationView />} /> */}

                <Route path="/verify-account" element={<RegisterSuccess />} />

                <Route path="/speedometer" element={<SpeedoMeterWebView />} />

                <Route path="/team-invitation/:token" element={<AcceptInvitaion />} />

                <Route
                    element={
                        <ProtectedRoute isAllowed={!!data || user} redirectedPath="/login">
                            <Home />
                        </ProtectedRoute>
                    }
                >
                    <Route
                        path="/dashboard"
                        element={<Dashboard mapDataheaders={headers} setDataHeaders={setHeaders} />}
                    />

                    <Route
                        path="/noc"
                        element={<NOC mapDataheaders={headers} setDataHeaders={setHeaders} />}
                    />

                    <Route path="/billing" element={<Billing />} />

                    <Route path="/how-to" element={<HowTo />} />

                    <Route path="/manage-users" element={<ManageUsers />} />

                    <Route path="/profile" element={<Profile />} />

                    <Route
                        path="/create-map"
                        element={
                            <CreateMap
                                headers={headers}
                                setHeaders={setHeaders}
                                planDetailsData={planDetailsData}
                                userPlanData={userPlanData}
                            />
                        }
                    >
                        <Route
                            path=":id"
                            element={<CreateMap headers={headers} setHeaders={setHeaders} />}
                        />
                    </Route>

                    <Route path="/edit-map/:id/:type" element={<EditMap />} />
                </Route>

                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="*" element={<Navigate to="/dashboard" />} />
            </Routes>
        </>
    );
};

export default App;
