import React from "react";
import DotLoader from "../DotLoaderBtn/DotLoader";
import { capitalizeString } from "../../utils/utils";

const DeletePin = (props) => {
    const { pinInfo, mapData, deletePinLoading, handleDeletePin, closeDeletePinModel } = props;

    return (
        <section className="delete-modal-wrapper">
            <h5 className="delete-modal-wrapper-title ">Remove marker?</h5>
            <div style={{ marginBottom: 15 }}>
                <p className="content">
                    <b>
                        {capitalizeString(pinInfo?.address)}, {capitalizeString(pinInfo?.city)},{" "}
                        {pinInfo?.state}, {capitalizeString(pinInfo?.zip)}{" "}
                    </b>{" "}
                    from <b> {mapData?.title}?</b>
                </p>
            </div>
            <div className="delete-modal-actions">
                <div className="buttons">
                    <button
                        type="button"
                        aria-label="Close"
                        className="btn border-cancel-btn"
                        onClick={closeDeletePinModel}
                        disabled={deletePinLoading}
                    >
                        Cancel
                    </button>
                    {deletePinLoading ? (
                        <button
                            className="btn animate-dots-red del-outline-btn"
                            type="button"
                            disabled
                        >
                            <DotLoader />
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="btn del-outline-btn"
                            onClick={() => handleDeletePin()}
                        >
                            Confirm
                        </button>
                    )}
                </div>
            </div>
        </section>
    );
};

export default DeletePin;
