import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { calculateCenterPoint } from "../../../utils/mapUtils";
import EditMapView from "./EditMapView";

import { capitalizeFirstCharatcter, changeToGroup, makeDeepCopy } from "../../../utils/utils";

import { errorMessage, successMessage } from "../../../utils/messages";
import {
    useGetGroupsHistoryQuery,
    useLazyGetGroupByIdQuery,
    useLazyGetMapByIdQuery,
    useUpdateMapMutation,
} from "../../../services/baseApi";
import LoadingAnimation from "../../../components/LoadingAnimation/LoadingAnimation";
import socket from "../../../socket/socket";
import { UPDATE_MAP_LOCATION } from "../../../socket/socketsList";
import ReactPortal from "../../../components/Portal/ReactPortal";
import { IsMarkerInShape } from "../../../components/Map/DrawingManagerBtns";
import {
    setCurrentMapDashboardId,
    setMapDashboardId,
} from "../../../store/slicers/mapDashboardSlice";
import { setMapLayerType } from "../../../store/slicers/mapLayerSlice";

import "./EditMapConfirmModel.css";
import EditMapConfirmModel from "./EditMapConfirmModel";

function EditMyMap() {
    let { id: mapId, type: currentMapType } = useParams();
    const location = useLocation();
    const authUser = useSelector((state) => state.auth.user);
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [customMap, setCustomMap] = useState(false);
    const [updatingRef, setUpdatingRef] = useState(false);

    const [currentMapResp, setCurrentMapResp] = useState({
        singleMapData: {},
        singleMapLoading: false,
        singleMapError: null,
    });

    const [changedRow, setChangedRow] = useState([]);

    const zoomRef = useRef({
        maxZoomOut: 2,
        defaultZoom: 3,
        maxZoomIn: 24,
    });

    const refState = useRef({
        title: "",
        description: "",
    });

    const [historyState, setHistoryState] = useState({
        isHistoryLoading: false,
        showHistoryWarnModel: false,
        historyData: [],
        allUpdatedPins: [],
        mapOutPins: [],
    });

    const [editMapState, setEditMapState] = useState({
        id: mapId,

        // loading: singleMapLoading,
        loading: currentMapResp?.singleMapLoading,
        saveLoading: false,
        updateLoading: false,
        updateLocations: false,
        deletedLocations: [],

        status: "START",

        successRows: [],
        failedRows: [],

        center: { lat: 40.756795, lng: -73.954298 },

        data: {},
        mapData: {},

        rawData: [],
        groupedData: [],

        email: "",
        access: "",
        password: "",
        view: "",

        map: {
            mapRef: null,
            showMap: false,
        },

        standardOptions: {
            region: "",
            state_province: "",
            address_location: "",
            zip_postcode: "",
            city_country: "",
            phone: "",
            groupby_thematicValue: "",
        },

        advanceOptions: {
            name: "",
            email: "",
            url: "",

            lng: "",
            lat: "",
            markerDescription: "",

            markerStyle: "",
            mapStyle: "",
            markerLabel: "letters",
            mapView: "satellite",
            distance: "mile",

            clustering: false,
            limitZoom: false,
            disableKML: false,
            openLinkInNew: false,
            groupsIntoRanges: false,
            filterRoadType: false,
        },
    });
    const [tableRowsData, setTableRowsData] = useState([]);
    const [rawDataIds, setRawDataIds] = useState({
        delSelected: [],
        insertSelected: [],
    });

    const [getMapById] = useLazyGetMapByIdQuery();
    const [getGroupById] = useLazyGetGroupByIdQuery();

    const [updateMap] = useUpdateMapMutation();

    async function getCurrentMap(id, type) {
        if (id) {
            setCurrentMapResp((prev) => {
                return {
                    ...prev,
                    singleMapLoading: true,
                };
            });
        }
        let resp;
        switch (type) {
            case "map": {
                resp = await getMapById(id);
                break;
            }
            case "group": {
                resp = await getGroupById(id);
                break;
            }
            default:
                break;
        }

        if (resp?.isError) {
            errorMessage(resp?.error?.data?.message);
            setCurrentMapResp((prev) => {
                return {
                    ...prev,
                    data: {},
                    singleMapLoading: false,
                    singleMapError: resp?.error,
                };
            });
            return;
        } else {
            setMapData(resp?.data, resp?.error);
            setCurrentMapResp((prev) => {
                return {
                    ...prev,
                    data: resp?.data,
                    singleMapLoading: false,
                };
            });
        }
    }

    const handleCancel = () => {
        navigate("/dashboard", {
            replace: true,
        });
        dispatch(setMapDashboardId(location.state.mapId));
    };

    const handleSaveMap = async (body) => {
        const { data, error } = await updateMap(body);
        if (data) {
            setEditMapState((prevState) => {
                return {
                    ...prevState,
                    updateLoading: false,
                };
            });

            setTableRowsData(editMapState?.rawData);
            if (editMapState?.rawData?.length === 0) {
                dispatch(setMapDashboardId(location.state.mapId_opt));
                dispatch(setCurrentMapDashboardId(location.state.mapId_opt));
            } else {
                dispatch(setMapDashboardId(location.state.mapId));
            }
            successMessage("Successfully updated");

            navigate("/dashboard", {
                replace: true,
            });
        }
        if (error) {
            setEditMapState((prevState) => {
                return {
                    ...prevState,
                    updateLoading: false,
                };
            });
            errorMessage(error?.data?.message);
        }
    };

    const handleUpdateMap = () => {
        let newRawData = makeDeepCopy(editMapState.rawData);

        const requiredFields = [
            "city_country",
            "zip_postcode",
            "address_location",
            "state_province",
        ];
        // for (let i = 0; i < requiredFields.length; i++) {
        //     let fieldToCheck = requiredFields[i];
        //     if (!editMapState.standardOptions[fieldToCheck]) {
        //         errorMessage(
        //             `${capitalizeFirstCharatcter(fieldToCheck.split("_")[0])} is required.`
        //         );

        //         return;
        //     }
        // }

        if (editMapState.access === "password" && !editMapState.password.trim()) {
            errorMessage("Password cannot be empty.");
            return;
        }

        newRawData?.forEach((row) => {
            row.address = row[editMapState.standardOptions.address_location];
            row.city = row[editMapState.standardOptions.city_country];
            row.zip = row[editMapState.standardOptions.zip_postcode];
            row.state = row[editMapState.standardOptions.state_province];

            row.phone = row[editMapState.standardOptions.phone];
            row.name = row[editMapState.advanceOptions.name];
            row.email = row[editMapState.advanceOptions.email];
            row.url = row[editMapState.advanceOptions.url];
        });

        let headers = Object.keys(editMapState?.rawData?.[0] || []);
        let group = headers?.includes(editMapState.standardOptions.groupby_thematicValue)
            ? editMapState?.standardOptions.groupby_thematicValue
            : "";

        let newState = {
            ...editMapState,
            updateLoading: true,
            status: "PENDING",
            successRows: [],
            failedRows: [],
            groupedData: changeToGroup(newRawData, group),
            rawData: newRawData,
        };

        setEditMapState(newState);

        const body = {
            mapId: mapId,
            type: newState?.mapData?.type,
            currentMapType: currentMapType,
            title: refState.current.title,
            email: newState.email,
            owner_name: `${authUser.first_name} ${authUser.last_name}`,
            description: refState.current.description,
            access: newState.access,
            password: newState?.access === "password" ? newState?.password : "",
            render_method: "mapdata",
            view: newState.view,
            mapOutPins: historyState?.mapOutPins,
            deletedLocations: newState?.deletedLocations,

            mapData: {
                rawData: newState?.rawData,
                groupedData: newState?.groupedData,

                // STANDARD OPTIONS
                region: newState.standardOptions?.region,
                city: newState.standardOptions?.city_country,
                state: newState.standardOptions?.state_province,
                address: newState.standardOptions?.address_location,
                zip: newState.standardOptions?.zip_postcode,
                group: newState.standardOptions?.groupby_thematicValue || "NO_GROUP",
                phone: newState.standardOptions?.phone,

                // ADVANCE OPTIONS
                name: newState.advanceOptions?.name,
                description: newState.advanceOptions?.markerDescription,
                url: newState.advanceOptions?.url,
                email: newState.advanceOptions?.email,

                pinShape: "PRE FILLED",
                mapType: "street",
                targetLink: "_blank",
                markerLabel: "none",
                mapStyles: "PRE FILLED",
                imageURL: "no need",

                longitude: newState.advanceOptions?.lng,
                latitude: newState.advanceOptions?.lat,
                markerStyle: newState.advanceOptions?.markerStyle,
                mapStyle: newState.advanceOptions?.mapStyle,
            },
        };

        handleSaveMap({ id: editMapState.id, body });
    };

    const setMapData = (currentMapData, dataError) => {
        if (currentMapData) {
            let { data, mapData } = makeDeepCopy(currentMapData);
            let { rawData, groupedData } = data;

            let locationsCenterPoint = calculateCenterPoint(data?.rawData);

            refState.current.title = mapData.title;
            refState.current.description = mapData.description;

            setEditMapState((prevState) => {
                return {
                    ...prevState,
                    loading: false,
                    data,
                    mapData,

                    groupedData,
                    rawData,

                    center: locationsCenterPoint,

                    email: location.state.ownerEmail || "",
                    access: mapData.access,
                    password: mapData.password,
                    confirmPassword: mapData.password,
                    view: mapData.view,

                    standardOptions: {
                        region: data.region || "",
                        state_province: data.state || "",
                        address_location: data.address || "",
                        zip_postcode: data.zip || "",
                        city_country: data.city || "",
                        phone: data.phone || "",
                        groupby_thematicValue: data.group || "",
                    },

                    advanceOptions: {
                        name: data.name || "",
                        email: data.email || "",
                        lng: data.longitude || "",
                        markerDescription: "none" || "",
                        url: data.imageURL || "",
                        lat: data.latitude || "",
                        markerStyle: data.markerStyle || "default",
                        mapStyle: data.mapStyle || "",
                    },
                };
            });
            setTableRowsData(rawData);
        }

        if (dataError) {
            setEditMapState((prevState) => {
                return {
                    ...prevState,
                    loading: false,
                };
            });

            errorMessage(dataError?.data?.message);

            navigate("/create-map");
        }
    };

    useEffect(() => {
        getCurrentMap(mapId, currentMapType);
    }, [mapId, currentMapType]);

    const setCurrentUpdateData = (index, rowData) => {
        if (index !== undefined && rowData) {
            setChangedRow((prev) => {
                let rowItemIndex = prev.findIndex((item) => item?.rowData?.id === rowData?.id);
                let newObj = { rowId: rowData?.id, rowData: rowData };

                if (rowItemIndex === -1) {
                    return [...prev, newObj];
                } else {
                    let newArray = [...prev];
                    newArray[rowItemIndex] = newObj;
                    return newArray;
                }
            });
        }
    };

    const CheckOutOfMapMarker = async (data, historyData) => {
        let pinIndex = 0;

        if (data?.length > 0 && historyData?.length > 0) {
            for (const item of data) {
                const isItemHaveGroup = historyData?.find(
                    (historyItem) => historyItem?.group_id === item?.rowData?.groupId
                );
                let isPinOut = IsMarkerInShape(item?.rowData?.location, isItemHaveGroup?.pathInfo);
                if (isItemHaveGroup) {
                    if (!isPinOut) {
                        setHistoryState((prev) => {
                            let rowItemIndex = prev?.mapOutPins?.findIndex(
                                (prevItem) => prevItem?.id === item?.rowData?.id
                            );
                            let newArray = [...prev.mapOutPins];

                            if (rowItemIndex !== -1) {
                                newArray[rowItemIndex] = item?.rowData;
                            } else {
                                newArray.push(item?.rowData);
                            }

                            return {
                                ...prev,
                                mapOutPins: newArray,
                                showHistoryWarnModel: true,
                            };
                        });
                        pinIndex += 1;
                    } else {
                        setHistoryState((prev) => {
                            let newarray = prev?.mapOutPins?.filter(
                                (existItem) => existItem?.id !== item.rowData?.id
                            );

                            return {
                                ...prev,
                                mapOutPins: newarray,
                            };
                        });
                    }
                }
            }
        }
        return pinIndex;
    };

    useEffect(() => {
        if (changedRow?.length > 0) {
            setEditMapState((prevState) => {
                return {
                    ...prevState,
                    updateLocations: false,
                };
            });
        }
    }, [changedRow?.length]);

    const handleEditMapModel = () => {
        setHistoryState((prev) => {
            return {
                ...prev,
                showHistoryWarnModel: false,
            };
        });
    };

    const { data: historyDetails, error: historyError } = useGetGroupsHistoryQuery(
        {
            id: editMapState?.id,
            type: editMapState?.data?.type,
        },
        {
            skip: !editMapState?.data?.type,
        }
    );

    useEffect(() => {
        if (historyDetails) {
            setHistoryState((prev) => {
                return {
                    ...prev,
                    historyData: historyDetails,
                };
            });
        }
        if (historyError) {
            setHistoryState((prev) => {
                return {
                    ...prev,
                    historyData: [],
                };
            });
        }
    }, [historyDetails, historyError]);

    const cancelMapDataUpadte = () => {
        setTableRowsData(editMapState?.rawData);
        setEditMapState((prevState) => {
            return {
                ...prevState,
                updateLocations: false,
            };
        });
        setHistoryState((prev) => ({
            ...prev,
            mapOutPins: [],
            showHistoryWarnModel: false,
        }));
        setUpdatingRef(false);
        setChangedRow([]);
    };

    const handleDataUpdate = (updatedData, isDataRevert = false) => {
        if (updatedData?.length === 0) {
            return cancelMapDataUpadte();
        }
        let copyRawData = makeDeepCopy(tableRowsData);
        let editRawData;
        let locationNotFound = [];
        let failedRowData = [];

        setEditMapState((prevState) => {
            updatedData?.forEach((item) => {
                let currentItem = item?.rowData || item;
                let currentItemID = currentItem?.rowId || currentItem?.id;
                copyRawData?.forEach((rowItem, index) => {
                    if (currentItemID === rowItem?.id && currentItem?.location !== null) {
                        copyRawData[index] = currentItem;
                    }
                });
                if (
                    currentItem?.location === null &&
                    !failedRowData?.find((locItem) => locItem?.id === currentItemID)
                ) {
                    locationNotFound.push(currentItemID);
                    // failedRowData.push(currentItem);
                }
                if (rawDataIds?.insertSelected?.includes(currentItemID)) {
                    setRawDataIds((prev) => {
                        let updatedInserts = rawDataIds.insertSelected?.filter(
                            (insertedUpdates) => insertedUpdates !== currentItemID
                        );
                        return {
                            ...prev,
                            insertSelected: updatedInserts,
                        };
                    });
                }
            });

            editRawData = copyRawData?.filter((filtereditem) => filtereditem?.location !== "");

            let groupedData = changeToGroup(editRawData, "GROUP" || "");
            return {
                ...prevState,
                loading: false,
                updateLoading: false,
                updateLocations: true,
                status: "COMPLETED",
                rawData: editRawData,
                // failedRows: failedRowData,
                groupedData: groupedData,
            };
        });
        setRawDataIds((prev) => {
            const mergedData = [...new Set([...locationNotFound, ...prev.insertSelected])];
            return {
                ...prev,
                insertSelected: mergedData,
            };
        });
        setUpdatingRef(false);
        setTableRowsData(copyRawData);
        setChangedRow([]);
        setHistoryState((prev) => {
            return {
                ...prev,
                isHistoryLoading: false,
                showHistoryWarnModel: false,
            };
        });
    };

    useEffect(() => {
        socket.child = true;

        // Define the async handler
        const handleMapUpdate = async (res) => {
            if (socket.child) {
                if (socket.id === res.socketID) {
                    const updatedData = res?.data;
                    setHistoryState((prev) => ({
                        ...prev,
                        allUpdatedPins: updatedData,
                    }));
                    let isPinOut = await CheckOutOfMapMarker(
                        updatedData,
                        historyState?.historyData
                    );

                    if (res.completed && updatingRef && updatedData) {
                        if (isPinOut === 0) {
                            handleDataUpdate(updatedData);
                        }

                        socket.resetLogoutTimer();
                    }
                }
            }
        };

        // Attach the event listener
        socket.on(UPDATE_MAP_LOCATION, handleMapUpdate);

        return () => {
            // Clean up: remove the event listener and reset child flag
            socket.child = false;
            socket.off(UPDATE_MAP_LOCATION, handleMapUpdate);
        };
    }, [updatingRef]);

    useEffect(() => {
        dispatch(setMapLayerType(0));
    }, []); // for heat map layer

    return currentMapResp?.singleMapLoading ? (
        <LoadingAnimation />
    ) : (
        <>
            <section className="editMap--container pos-rel" id="afterMap">
                <EditMapView
                    refState={refState.current}
                    editMapState={editMapState}
                    setEditMapState={setEditMapState}
                    zoomRef={zoomRef}
                    // ---------
                    handleUpdateMap={handleUpdateMap}
                    handleCancel={handleCancel}
                    updatingRef={updatingRef}
                    setUpdatingRef={setUpdatingRef}
                    setChangedRow={setChangedRow}
                    changedRow={changedRow}
                    setCurrentUpdateData={setCurrentUpdateData}
                    setHistoryState={setHistoryState}
                    historyState={historyState}
                    setTableRowsData={setTableRowsData}
                    tableRowsData={tableRowsData}
                    rawDataIds={rawDataIds}
                    setRawDataIds={setRawDataIds}
                    editMapId={mapId}
                    getCurrentMap={getCurrentMap}
                    currentMapType={currentMapType}
                    handleEditMapModel={handleEditMapModel}
                    cancelMapDataUpadte={cancelMapDataUpadte}
                    handleDataUpdate={handleDataUpdate}
                />
            </section>
            {/* {historyState?.showHistoryWarnModel && ( */}

            {/* {true && (
                <ReactPortal wrapperId="external_modal_container">
                    <EditMapConfirmModel
                        handleEditMapModel={handleEditMapModel}
                        mapOutPins={historyState?.mapOutPins}
                    />
                </ReactPortal>
            )} */}
        </>
    );
}

export default EditMyMap;
