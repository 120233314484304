import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AddressElement, CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { errorMessage, successMessage } from "../../utils/messages";
import { useCreateBillingCardMutation } from "../../services/baseApi";
import { debounce } from "../../utils/utils";

const StripePaymentForm = (props) => {
    const { setbillingState } = props;

    const stripe = useStripe();
    const elements = useElements();

    const { user } = useSelector((state) => state.auth);

    const [cardDetailsState, setcardDetailsState] = useState({
        loading: false,
        address: "",
        name: "",
    });

    const [loading, setLoading] = useState(false);

    const CARD_OPTIONS = {
        style: {
            base: {
                fontSize: "16px",
                color: "#424770",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#9e2146",
            },
        },
    };

    const [createBillingCard] = useCreateBillingCardMutation();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: {
                address: cardDetailsState.address,
                name: cardDetailsState.name,
                email: user.email,
            },
        });

        if (!error) {
            try {
                setLoading(true);
                const { data, error } = await createBillingCard({
                    body: paymentMethod,
                    email: user.email,
                });
                if (data) {
                    setbillingState((prevState) => {
                        return {
                            ...prevState,
                            openCardPaymentModal: false,
                        };
                    });
                    successMessage("Successfully added");
                }

                if (error) {
                    errorMessage(error?.data?.message);
                }
            } catch (error) {
                console.log("error", error);
                errorMessage(error?.message);
            }
            setLoading(false);
        } else {
            errorMessage(error?.message);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="stripe-card-credentials-box">
                    <AddressElement
                        options={{
                            mode: "billing",
                            required: true,
                            fields: {
                                address: "always",
                            },
                        }}
                        onChange={debounce((event) => {
                            if (event) {
                                setcardDetailsState((prevState) => {
                                    return {
                                        ...prevState,
                                        address: event.value.address,
                                        name: event.value.name,
                                    };
                                });
                            }
                        }, 1000)}
                    />
                    <CardElement options={CARD_OPTIONS} />
                    <div className="callToActions">
                        <button type="submit" disabled={!cardDetailsState.name}>
                            Add
                        </button>
                        <button
                            type="button"
                            onClick={() =>
                                setbillingState((prevState) => {
                                    return {
                                        ...prevState,
                                        openCardPaymentModal: false,
                                    };
                                })
                            }
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default StripePaymentForm;
