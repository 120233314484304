/* global google */
import {
    convertNumberToCommaNotation,
    distanceBetweenTwoMarkersUsingMaps,
} from "../../utils/utils";
import {
    distanceBetweenPoints,
    drawingToolsOptions,
    setMapBounds,
    getMiles,
    getKilometers,
    getSquare,
    calculateCircleRadius,
    calculateCenterPoint,
    calculateCircleCenter,
} from "../../utils/mapUtils";
import { errorMessage, warningMessage } from "../../utils/messages";

const $ = (selector) => document.querySelector(selector);
let circleListener = null;
let rectangleListener = null;
let lassoListener = null;

export let customButtonsData = [
    {
        id: "lassoButton",
        title: "Lasso",
        icon: ` <svg id="Lasso" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <rect id="BG" width="30" height="30" rx="16" fill="#fff" opacity="0"/>
        <g id="Icon" transform="translate(-1350 -332)">
          <g id="Boundary" transform="translate(1350 332)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
            <rect width="30" height="30" stroke="none"/>
            <rect x="0.5" y="0.5" width="31" height="31" fill="none"/>
          </g>
          <path id="Path_lasso" data-name="Path / lasso" d="M15.348,2C22.689,2,28.7,6.178,28.7,11.344c0,4.351-4.258,8.009-10.011,9.05V20.02a6.675,6.675,0,0,0-.387-2.256c4.458-.8,7.728-3.377,7.728-6.42,0-3.684-4.779-6.674-10.678-6.674S4.67,7.66,4.67,11.344a5.408,5.408,0,0,0,2.389,4.2,6.613,6.613,0,0,0-1.348,2.269A8.161,8.161,0,0,1,2,11.344C2,6.178,8.007,2,15.348,2M12.011,15.348a4.673,4.673,0,0,1,.133,9.344,1.74,1.74,0,0,0,.307,2.242c1.562,1.6,4.605-1.281,8.756-1.295a31.537,31.537,0,0,1,4.859.347s1.4.174,1.255,1.428a1.419,1.419,0,0,1-1.415,1.241,22.311,22.311,0,0,0-4.925-.387A14.9,14.9,0,0,0,16.1,29.617a5.8,5.8,0,0,1-5.686-.854c-1.775-1.455-1.121-3.924-.841-4.739a4.739,4.739,0,0,1-2.229-4,4.677,4.677,0,0,1,4.672-4.672m0,2.67a2,2,0,1,0,2,2A2,2,0,0,0,12.011,18.018Z" transform="translate(1350.652 332)" fill="#999b9e"/>
        </g>
      </svg>
      `,
        callback: lassoCallback,
    },
    {
        id: "restoreButton",
        title: "Center",
        icon: ` <svg id="Center" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <g id="Boundary" stroke="rgba(0,0,0,0)" stroke-width="1" opacity="0">
          <rect width="28" height="28" stroke="none"/>
          <rect x="0.5" y="0.5" width="27" height="27" fill="none"/>
        </g>
        <path id="Center-2" data-name="Center" d="M17,12.333A4.667,4.667,0,1,0,21.667,17,4.667,4.667,0,0,0,17,12.333M27.889,27.889H21.667V31h6.222A3.111,3.111,0,0,0,31,27.889V21.667H27.889M27.889,3H21.667V6.111h6.222v6.222H31V6.111A3.111,3.111,0,0,0,27.889,3M6.111,6.111h6.222V3H6.111A3.111,3.111,0,0,0,3,6.111v6.222H6.111m0,9.333H3v6.222A3.111,3.111,0,0,0,6.111,31h6.222V27.889H6.111Z" transform="translate(-3 -3)" fill="#999b9e"/>
      </svg>
      
      `,
        callback: restoreCallback,
    },
    {
        id: "handButton",
        title: "Move",
        icon: `<svg id="Move_off" data-name="Move off" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <rect id="BG" width="32" height="32" rx="16" fill="#fff" opacity="0"/>
        <g id="back_hand_black_24dp" transform="translate(6 6)">
          <rect id="BG-2" data-name="BG" width="32" height="32" transform="translate(-6 -6)" fill="none"/>
          <path id="Path_100" data-name="Path 100" d="M22.8,8.167a2.736,2.736,0,0,0-3.292-2.858V4.667A2.835,2.835,0,0,0,16.763,1.75a2.552,2.552,0,0,0-.735.105A2.762,2.762,0,0,0,13.472,0a2.8,2.8,0,0,0-2.7,2.4,2.434,2.434,0,0,0-.593-.07A2.835,2.835,0,0,0,7.437,5.25v6.872a3.132,3.132,0,0,0-1.339-.77l-.845-.245a2.1,2.1,0,0,0-2.4.968,2.624,2.624,0,0,0-.165,2.275l2.809,7.5A9.19,9.19,0,0,0,14.02,28h0A9.066,9.066,0,0,0,22.8,18.667ZM20.6,18.667a6.807,6.807,0,0,1-6.583,7h0a6.99,6.99,0,0,1-6.485-4.678l-2.853-7.63.582.163a1.661,1.661,0,0,1,1.1,1.05L7.437,17.5H9.632V5.25a.55.55,0,1,1,1.1,0V14h2.194V2.917a.55.55,0,1,1,1.1,0V14h2.194V4.667a.55.55,0,1,1,1.1,0V14h2.194V8.167a.55.55,0,1,1,1.1,0Z" transform="translate(-2.651 -4)" fill="#999b9e"/>
        </g>
      </svg>
      `,
        callback: handCallback,
    },
    {
        id: "polygonButton",
        title: "Polygon",
        icon: ` 
        <svg id="Polygon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
          <g id="icon" transform="translate(-1165 -330)">
            <rect id="Boundary" width="32" height="32" transform="translate(1165 330)" fill="#fff" opacity="0"/>
            <path id="Path_vector-polygon" data-name="Path / vector-polygon" d="M2,2v8.4H5.192L7,21.6H4.8V30h8.4V27.284l7-.014V30h8.4V21.6H26.038L27.2,11.8H30V3.4H21.6V8.342L19.92,10.4H12.626L10.4,7.348V2M4.8,4.8H7.6V7.6H4.8M24.4,6.2h2.8V9H24.4M8.034,10.4h1.12L11.8,14.026V18.8h8.4V14.474L22.4,11.8h2l-1.176,9.8H20.2v2.884h-7V21.6H9.84m4.76-8.4h2.8V16H14.6m-7,8.4h2.8v2.8H7.6M23,24.4h2.8v2.8H23" transform="translate(1165 330)" fill="#999b9e"/>
          </g>
        </svg>
      `,
        callback: polygonCallback,
    },
    {
        id: "rectangleButton",
        title: "Rectangle",
        icon: `<svg id="Square" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <rect id="BG" width="32" height="32" rx="16" fill="#fff" opacity="0"/>
        <g id="Icon" transform="translate(-1221 -334)">
          <rect id="Boundary" width="32" height="32" transform="translate(1221 334)" fill="#fff" opacity="0"/>
          <g id="Icon-2" data-name="Icon" transform="translate(1223 336)" fill="none">
            <path d="M0,0H28V28H0Z" stroke="none"/>
            <path d="M 2 2 L 2 26 L 26 26 L 26 2 L 2 2 M 0 0 L 28 0 L 28 28 L 0 28 L 0 0 Z" stroke="none" fill="#999b9e"/>
          </g>
        </g>
      </svg>
      `,
        callback: rectangleCallback,
    },
    {
        id: "triangulationButton",
        title: "Triangulation",
        icon: ` <svg id="Triangulation" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <rect id="BG" width="32" height="32" fill="#fff" opacity="0"/>
        <g id="Icon" transform="translate(-49 -360)">
          <rect id="Boundary" width="32" height="32" transform="translate(49 360)" fill="#fff" opacity="0"/>
          <path id="Triangulation-2" data-name="Triangulation" d="M7052.5-738a3.981,3.981,0,0,1-4.045-3.9,3.937,3.937,0,0,1,3.006-3.773v-5.456a7.907,7.907,0,0,1-2.8-.9l-2.791,3.164a3.786,3.786,0,0,1,.718,2.217,3.98,3.98,0,0,1-4.044,3.9,3.981,3.981,0,0,1-4.045-3.9,3.981,3.981,0,0,1,4.045-3.9,4.137,4.137,0,0,1,1.914.465l2.676-3.034a7.353,7.353,0,0,1-2.413-5.405A7.636,7.636,0,0,1,7052.5-766a7.636,7.636,0,0,1,7.778,7.467,7.334,7.334,0,0,1-2.55,5.529l2.647,3a4.131,4.131,0,0,1,2.08-.557,3.98,3.98,0,0,1,4.045,3.9,3.98,3.98,0,0,1-4.045,3.9,3.98,3.98,0,0,1-4.044-3.9,3.784,3.784,0,0,1,.61-2.06l-2.851-3.233a7.962,7.962,0,0,1-2.842.84v5.384a3.947,3.947,0,0,1,3.218,3.821A3.981,3.981,0,0,1,7052.5-738Zm0-5.94a2.113,2.113,0,0,0-2.178,2.037,2.113,2.113,0,0,0,2.178,2.037,2.113,2.113,0,0,0,2.179-2.037A2.113,2.113,0,0,0,7052.5-743.94Zm9.955-4.753a2.113,2.113,0,0,0-2.177,2.037,2.113,2.113,0,0,0,2.177,2.037,2.113,2.113,0,0,0,2.178-2.037A2.113,2.113,0,0,0,7062.454-748.692Zm-19.91,0a2.113,2.113,0,0,0-2.179,2.037,2.113,2.113,0,0,0,2.179,2.037,2.113,2.113,0,0,0,2.177-2.037A2.113,2.113,0,0,0,7042.544-748.692Zm9.955-15.441a5.768,5.768,0,0,0-5.911,5.6,5.535,5.535,0,0,0,2.425,4.522,6.084,6.084,0,0,0,3.486,1.078,6.113,6.113,0,0,0,3.354-.988,5.528,5.528,0,0,0,2.558-4.612A5.768,5.768,0,0,0,7052.5-764.133Z" transform="translate(-6987.5 1128)" fill="#999b9e"/>
        </g>
      </svg>
      `,
        callback: triangulationCallback,
    },
    {
        id: "circleButton",
        title: "Circle",
        icon: `<svg id="Circle" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <rect id="BG" width="32" height="32" rx="16" fill="#fff" opacity="0"/>
        <g id="icon" transform="translate(-1419 -330)">
          <rect id="Boundary" width="32" height="32" transform="translate(1419 330)" fill="#fff" opacity="0"/>
          <g id="Path_1742" data-name="Path 1742" transform="translate(1419 330)" fill="none">
            <path d="M16,0A16,16,0,1,1,0,16,16,16,0,0,1,16,0Z" stroke="none"/>
            <path d="M 16 2 C 12.26045989990234 2 8.744749069213867 3.456249237060547 6.100500106811523 6.100500106811523 C 3.456249237060547 8.744749069213867 2 12.26045989990234 2 16 C 2 19.73954010009766 3.456249237060547 23.2552490234375 6.100500106811523 25.89949989318848 C 8.744749069213867 28.54375076293945 12.26045989990234 30 16 30 C 19.73954010009766 30 23.2552490234375 28.54375076293945 25.89949989318848 25.89949989318848 C 28.54375076293945 23.2552490234375 30 19.73954010009766 30 16 C 30 12.26045989990234 28.54375076293945 8.744749069213867 25.89949989318848 6.100500106811523 C 23.2552490234375 3.456249237060547 19.73954010009766 2 16 2 M 16 0 C 24.8365592956543 0 32 7.163440704345703 32 16 C 32 24.8365592956543 24.8365592956543 32 16 32 C 7.163440704345703 32 0 24.8365592956543 0 16 C 0 7.163440704345703 7.163440704345703 0 16 0 Z" stroke="none" fill="#999b9e"/>
          </g>
        </g>
      </svg>
      `,
        callback: circleCallback,
    },
    {
        id: "rulerButton",
        innerButton: [
            {
                id: "rulerKm",
                title: "Ruler - Kilometer",
                icon: `
                                <svg id="Ruler_km_off" data-name="Ruler km off" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                        <rect id="blundary" width="32" height="32" fill="#fff" opacity="0"/>
                        <path id="Ruler" d="M-7109.545,15641.242l-5.747-5.752a.9.9,0,0,1,0-1.28l20.441-20.441a.9.9,0,0,1,1.277,0l5.75,5.747a.911.911,0,0,1,0,1.281l-20.445,20.445a.912.912,0,0,1-.638.265A.918.918,0,0,1-7109.545,15641.242Zm-3.192-6.39,3.835,3.835,2.717-2.722-1.994-1.989a1.128,1.128,0,0,1,0-1.6,1.141,1.141,0,0,1,1.6,0l1.99,2,1.277-1.28-.713-.713a1.138,1.138,0,0,1,0-1.6,1.138,1.138,0,0,1,1.6.008l.713.713,1.274-1.277-1.99-1.993a1.124,1.124,0,0,1,0-1.594,1.128,1.128,0,0,1,1.6,0l1.99,1.99,1.281-1.274-.713-.713a1.128,1.128,0,0,1,0-1.6,1.121,1.121,0,0,1,1.6.007l.717.709,1.281-1.276-2-1.994a1.126,1.126,0,0,1,0-1.593,1.137,1.137,0,0,1,1.6-.007l1.99,2,2.714-2.718-3.831-3.834Zm17.507,6.549,7.23-7.23v7.23Zm-9.8-20.781v-4.584l-1.157,4.584h-1.132l-1.149-4.584v4.584h-1.093v-5.822h1.76l1.057,3.97,1.043-3.97h1.763v5.822Zm-6.641,0-1.6-2.732-.954.972v1.761h-1.174v-5.822h1.174v2.586l2.374-2.586h1.582l-2.192,2.267,2.309,3.556Z" transform="translate(7117.06 -15611.379)" fill="#999b9e"/>
                        </svg>

            `,
                callback: rulerCallback,
            },
            {
                id: "rulerMi",
                title: "Ruler - Miles",
                icon: `<svg id="Ruler_km_off" data-name="Ruler km off" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <rect id="blundary" width="32" height="32" fill="#fff" opacity="0"/>
                <path id="Ruler-mi" d="M-10085.549,15585.237l-5.747-5.748a.911.911,0,0,1,0-1.279l20.447-20.445a.9.9,0,0,1,1.275,0l5.748,5.747a.913.913,0,0,1,0,1.283l-20.447,20.442a.9.9,0,0,1-.643.266A.905.905,0,0,1-10085.549,15585.237Zm-3.191-6.391,3.835,3.839,2.719-2.722-1.993-1.99a1.128,1.128,0,0,1,0-1.595,1.124,1.124,0,0,1,1.594,0l1.994,1.994,1.279-1.277-.714-.714a1.134,1.134,0,0,1,0-1.6,1.137,1.137,0,0,1,1.6.007l.718.715,1.272-1.28-1.993-1.99a1.128,1.128,0,0,1,0-1.6,1.123,1.123,0,0,1,1.594,0l1.994,1.99,1.276-1.276-.711-.714a1.123,1.123,0,0,1,0-1.6,1.129,1.129,0,0,1,1.6,0l.719.719,1.275-1.284-1.994-1.993a1.129,1.129,0,0,1,0-1.595,1.134,1.134,0,0,1,1.6,0l1.994,1.994,2.714-2.716-3.831-3.835Zm17.509,6.551,7.229-7.229v7.229Zm-12.55-20.782v-5.818h1.178v5.818Zm-2.224,0v-4.581l-1.155,4.581h-1.131l-1.148-4.581v4.581h-1.093v-5.818h1.757l1.058,3.97,1.045-3.97h1.761v5.818Z" transform="translate(10093.063 -15555.375)" fill="#999b9e"/>
              </svg>
              `,
                callback: rulerCallback,
            },
        ],
    },
];

export function createToolBtn(btnid, btnTitle, icon) {
    const btn = document.createElement("button");

    btn.id = btnid;
    btnid === "handButton"
        ? btn.classList.add("custom-drawing-tool--btn", "active")
        : btn.classList.add("custom-drawing-tool--btn");

    btn.title = btnTitle;
    btn.type = "button";
    btn.insertAdjacentHTML("beforeend", icon);

    return btn;
}

function removeClass(selector, cls) {
    document.querySelectorAll(selector).forEach((ele) => ele.classList.remove(cls));
}
function removeDrawingBtnsActiveCls() {
    removeClass(".custom-drawing-tool--btn", "active");
}
function removeEvents(map, maps, defaultUI) {
    if (circleListener) {
        maps.event.removeListener(circleListener);
    }
    if (rectangleListener) {
        maps.event.removeListener(rectangleListener);
    }

    if (lassoListener) {
        maps.event.removeListener(lassoListener);
    }

    map?.setOptions({
        draggable: true,
        draggableCursor: "grab",

        disableDefaultUI: defaultUI,
    });
    maps?.event.clearListeners(map, "mousedown");
    maps?.event.clearListeners(map, "mousemove");
    maps?.event.clearListeners(map, "mouseup");
}

export let circle;
export let rectangle;
export let lassoPolygon;

export function repeatedActions(btnId, props) {
    let { map, maps, setMapState } = props;

    circle?.setMap?.(null);
    rectangle?.setMap?.(null);
    lassoPolygon?.setMap?.(null);

    // REMOVE PREVIOUS EVENTS
    removeEvents(map, maps, false);

    setMapState((prevState) => {
        // REMOVE OLD LISTENER
        return {
            ...prevState,
            shapeArea: null,
            selectedPins: [],
            shapeType: "",
            showError: false,
            userLocation: null,
            triangulation: false,
        };
    });

    removeDrawingBtnsActiveCls();
    props.deleteAllShapes();

    $(btnId)?.classList.add("active");
}

export function restoreCallback(props) {
    let { map, setMapState } = props;
    repeatedActions("#handButton", props);
    props.toggleMapHistory(false);

    $('button[title="Stop drawing"]')?.click();

    setMapState((prevMapState) => {
        setMapBounds(map, prevMapState?.data?.rawData, false, window.google?.maps.LatLngBounds);
        return prevMapState;
    });
}

function handCallback(props) {
    repeatedActions("#handButton", props);
    props.toggleMapHistory(false);
    $('button[title="Stop drawing"]')?.click();
}
function polygonCallback(props) {
    repeatedActions("#polygonButton", props);
    props.toggleMapHistory(true);
    $('button[title="Draw a shape"]')?.click();
}
function rulerCallback(props) {
    repeatedActions("#rulerButton", props);
    props.toggleMapHistory(false);
    $('button[title="Draw a line"]')?.click();
}

function getCirclePath(circle) {
    let center = circle.getCenter();
    let radius = circle.getRadius();

    // Calculate the path of the circle
    let path = [];
    let points = 360;
    for (let i = 0; i < points; i++) {
        let angle = (i * 360) / points;
        let point = google.maps.geometry.spherical.computeOffset(center, radius, angle);
        path.push(point);
    }
    path.push(path[0]); // close the circle
    return path;
}

export let shapes = [];
export function clearShapes() {
    shapes = [];
}

const checkOverlap = (boundsToCheck) => {
    for (const shape of shapes) {
        if (doShapesOverlap(shape.bounds, boundsToCheck)) return true;
    }
    return false;
};

function doShapesOverlap(bounds1, bounds2) {
    // Compare the bounds of the shapes

    if (!bounds1 || !bounds2) return false;

    if (bounds1.intersects(bounds2)) {
        return true; // Overlap detected
    }
    return false; // No overlap
}

export function addShapeToDrawingHistory(bounds, drawing) {
    if (shapes.find((shape) => shape.title === drawing.title)) return;

    shapes.push({
        title: drawing.title,
        bounds,
    });
}

// CUSTOM TOOLS
function circleCallback(props) {
    let { map, maps, setMapState, measureDistanceType } = props;

    $('button[title="Stop drawing"]')?.click();
    repeatedActions("#circleButton", props);

    props.toggleMapHistory(true);

    /* Bind the drawing mode */
    map.setOptions({
        draggableCursor: "crosshair",
        draggable: false,
    });

    const info = $("#info");

    circleListener = map.addListener("mousedown", (center) => {
        circle?.setMap?.(null);

        /* Create a new circle which will be manually scaled */
        let radius = 0;

        let warned = false;

        info.style.display = "block";

        circle = new google.maps.Circle({
            map: map,
            center: center.latLng,

            radius: 1,
            zIndex: 1,

            ...drawingToolsOptions.circleOptions,
            clickable: false,
        });

        let onMouseMove = map.addListener("mousemove", function (border) {
            let radius = 1000 * distanceBetweenPoints(center.latLng, border.latLng);
            circle.setRadius(radius);
            info.style.left = `${border.domEvent.clientX}px`;
            info.style.top = `${border.domEvent.clientY}px`;
        });

        let onRadiusChange = maps.event.addListener(circle, "radius_changed", function () {
            let CircleRadius;
            if (measureDistanceType === 2) {
                CircleRadius = circle.getRadius() / 1.609344;
                radius = CircleRadius; // CONVERTING MILES TO KILOMETER;
            } else {
                CircleRadius = circle.getRadius() / 1609.344;
                radius = CircleRadius; // CONVERTING METERS TO MILES;
            }

            info.textContent =
                measureDistanceType === 2
                    ? `${convertNumberToCommaNotation(
                          (Math.PI * Math.pow(radius, 2)).toFixed(2)
                      )} sq km`
                    : `${convertNumberToCommaNotation(
                          (Math.PI * Math.pow(radius, 2)).toFixed(2)
                      )} sq mi`;

            if (checkOverlap(circle?.getBounds?.()) && !warned) {
                warningMessage("Overlap Markers!");
                warned = true;
            }
        });

        /* The user has finished its drawing */
        maps.event.addListenerOnce(map, "mouseup", function () {
            /* Remove all listeners as they are no more required */
            maps.event.removeListener(onMouseMove);
            maps.event.removeListener(onRadiusChange);
            info.style.display = "none";
            info.textContent = "";

            // let polygon = new google.maps.Polygon({
            //     map: map,
            //     paths: getCirclePath(circle),
            //     fillColor,
            //     fillOpacity,
            //     strokeColor,
            // });

            // polygon.setMap(null);

            // let path = polygon.getPath().getArray();

            // setTimeout(() => {
            //     new google.maps.Polygon({
            //         map: map,
            //         paths: path,
            //         fillColor,
            //         fillOpacity,
            //         strokeColor,
            //     });
            // }, 500);

            setMapState((prevState) => {
                let data = prevState.data.rawData;
                let selectedPins = [];
                let alreadySelectedPins = [];

                if (data) {
                    selectedPins = data.filter((item) => {
                        const { location } = item;
                        if (
                            circle
                                .getBounds()
                                .contains(
                                    new window.google.maps.LatLng(
                                        Number(location?.lat),
                                        Number(location?.lng)
                                    )
                                ) &&
                            item?.history?.at(-1)?.status
                        )
                            alreadySelectedPins.push(item);

                        if (
                            circle
                                .getBounds()
                                .contains(
                                    new window.google.maps.LatLng(
                                        Number(location?.lat),
                                        Number(location?.lng)
                                    )
                                ) &&
                            !item?.history?.at(-1)?.status
                        ) {
                            return item;
                        } else return false;
                    });

                    if (selectedPins.length) {
                        return {
                            ...prevState,
                            shapeArea: radius,
                            selectedPins,
                            alreadySelectedPins,
                            shapeType: "circle",
                            path: getCirclePath(circle),
                            showError: false,
                        };
                    } else {
                        return {
                            ...prevState,
                            shapeArea: null,
                            selectedPins,
                            alreadySelectedPins,
                            shapeType: "",
                            path: "",
                            showError: true,
                            // errorMessage: "Select One Pin Atleast",
                            errorMessage: "Selection must contain a minimum of one marker.",
                        };
                    }
                } else return prevState;
            });
        });
    });
}

function rectangleCallback(props) {
    let { map, maps, setMapState, measureDistanceType } = props;

    $('button[title="Stop drawing"]')?.click();
    repeatedActions("#rectangleButton", props);
    props.toggleMapHistory(true);

    /* Bind the drawing mode */
    map.setOptions({
        draggableCursor: "crosshair",
        draggable: false,
    });

    const info = $("#info");

    rectangleListener = map.addListener("mousedown", (event) => {
        rectangle?.setMap?.(null);

        /* Create a new rectangle which will be manually scaled */

        let area = 0;
        let warned = false;
        let initialLatLng = event.latLng;

        info.style.display = "block";

        rectangle = new google.maps.Rectangle({
            map,
            bounds: new google.maps.LatLngBounds(initialLatLng),
            ...drawingToolsOptions.rectangleOptions,
            clickable: false,
        });

        let onMouseMove = map.addListener("mousemove", function (border) {
            let bounds = new google.maps.LatLngBounds(initialLatLng, null);
            bounds.extend(border.latLng);
            rectangle.setBounds(bounds);
            info.style.left = `${border.domEvent.clientX}px`;
            info.style.top = `${border.domEvent.clientY}px`;

            if (checkOverlap(rectangle?.getBounds?.()) && !warned) {
                warningMessage("Overlap Markers!");
                warned = true;
            }
        });

        // FOR CALCULATING AREA
        // let onBoundsChange = maps.event.addListener(rectangle, "bounds_changed", function () {
        //     let bounds = rectangle.getBounds();
        //     let ne = bounds.getNorthEast();
        //     let sw = bounds.getSouthWest();
        //     let southWest = new window.google.maps.LatLng(sw.lat(), sw.lng());
        //     let northEast = new window.google.maps.LatLng(ne.lat(), ne.lng());
        //     let southEast = new window.google.maps.LatLng(sw.lat(), ne.lng());
        //     let northWest = new window.google.maps.LatLng(ne.lat(), sw.lng());

        //     area = convertNumberToCommaNotation(
        //         (
        //             window.google.maps.geometry.spherical.computeArea([
        //                 northEast,
        //                 northWest,
        //                 southWest,
        //                 southEast,
        //             ]) /
        //             2 /
        //             2589988.11
        //         ).toFixed(2)
        //     );

        //     // DIVINDING BY 2589988.11 SO THAT WE CAN CONVERT SQUARE METER TO SQUARE MILES
        //     info.innerHTML = `${area} sq.miles`;
        // });

        // FOR CALCULATING WIDTH AND HEIGHT
        let onBoundsChange = maps.event.addListener(rectangle, "bounds_changed", function () {
            let north = rectangle.bounds.toJSON().north;
            let south = rectangle.bounds.toJSON().south;
            let east = rectangle.bounds.toJSON().east;
            let west = rectangle.bounds.toJSON().west;

            let ne = new google.maps.LatLng(north, east);
            let nw = new google.maps.LatLng(north, west);
            let se = new google.maps.LatLng(south, east);
            let sw = new google.maps.LatLng(south, west);

            let height = google.maps.geometry.spherical.computeDistanceBetween(ne, se);
            let width = google.maps.geometry.spherical.computeDistanceBetween(ne, nw);
            let measureWidth;
            let measureHeight;
            if (measureDistanceType === 2) {
                measureWidth = getKilometers(width);
                measureHeight = getKilometers(height);
            } else {
                measureWidth = getMiles(width);
                measureHeight = getMiles(height);
            }

            area = convertNumberToCommaNotation(
                (
                    window.google.maps.geometry.spherical.computeArea([ne, nw, sw, se]) /
                    2 /
                    2589988.11
                ).toFixed(2)
            );

            // DIVINDING BY 2589988.11 SO THAT WE CAN CONVERT SQUARE METER TO SQUARE MILES
            info.innerHTML =
                measureDistanceType === 2
                    ? `${convertNumberToCommaNotation(
                          (measureWidth * measureHeight).toFixed(2)
                      )} sq km`
                    : `${convertNumberToCommaNotation(
                          (measureWidth * measureHeight).toFixed(2)
                      )} sq mi`;
        });

        /* The user has finished its drawing */
        maps.event.addListenerOnce(map, "mouseup", function () {
            /* Remove all listeners as they are no more required */
            maps.event.removeListener(onMouseMove);
            maps.event.removeListener(onBoundsChange);

            info.style.display = "none";
            info.textContent = "";

            setMapState((prevState) => {
                let data = prevState.data.rawData;
                let selectedPins = [];
                let alreadySelectedPins = [];

                selectedPins = data.filter((item) => {
                    const { location } = item;
                    if (
                        rectangle
                            .getBounds()
                            .contains(
                                new window.google.maps.LatLng(
                                    Number(location?.lat),
                                    Number(location?.lng)
                                )
                            ) &&
                        item?.history?.at(-1)?.status
                    )
                        alreadySelectedPins.push(item);

                    if (
                        rectangle
                            .getBounds()
                            .contains(
                                new window.google.maps.LatLng(
                                    Number(location?.lat),
                                    Number(location?.lng)
                                )
                            ) &&
                        !item?.history?.at(-1)?.status
                    ) {
                        return item;
                    } else return false;
                });

                if (selectedPins.length) {
                    return {
                        ...prevState,
                        shapeArea: area,
                        selectedPins,
                        bounds: rectangle.getBounds(),
                        alreadySelectedPins,
                        shapeType: "rectangle",
                        showError: false,
                    };
                } else {
                    return {
                        ...prevState,
                        shapeArea: null,
                        selectedPins,
                        alreadySelectedPins,
                        shapeType: "",
                        path: "",
                        showError: true,
                        // errorMessage: "Select 1 pin atleast.",
                        errorMessage: "Selection must contain a minimum of one marker.",
                    };
                }
            });
        });
    });
}

function lassoCallback(props) {
    repeatedActions("#lassoButton", props);

    $('button[title="Stop drawing"]')?.click();

    let { maps, map, setMapState } = props;
    props.toggleMapHistory(true);

    // USING PENCIL
    map.setOptions({
        draggable: false,
        draggableCursor: "url('/pen.png'),crosshair",
        disableDefaultUI: true, // HIDING DEFAULT MENU OPTIONS
    });

    lassoListener = map.addListener("mousedown", () => {
        lassoPolygon?.setMap?.(null);

        let warned = false;

        let poly = new maps.Polyline({
            map: map,
            ...drawingToolsOptions.polylineOptions,
            clickable: false,
        });

        //Listens to mouse move
        const onMouseMove = maps.event.addListener(map, "mousemove", (e) => {
            poly.getPath().push(e.latLng);

            let polyBounds = new google.maps.LatLngBounds();

            poly.getPath()
                .getArray()
                .forEach((latlng) => {
                    polyBounds.extend(latlng);
                });

            if (checkOverlap(polyBounds) && !warned) {
                warningMessage("Overlap Markers!");
                warned = true;
                // poly.setMap?.(null);
                // return;
            }
        });

        maps.event.addListenerOnce(map, "mouseup", () => {
            maps.event.removeListener(onMouseMove);

            const path = poly.getPath();
            poly.setMap?.(null);

            lassoPolygon = new maps.Polygon({
                map: map,
                paths: path,
                ...drawingToolsOptions.polygonOptions,
                clickable: false,
            });

            if (!lassoPolygon.getPath?.()) return;

            let lassoBounds = new google.maps.LatLngBounds();

            lassoPolygon
                .getPath()
                .getArray()
                .forEach((latlng) => {
                    lassoBounds.extend(latlng);
                });

            if (checkOverlap(lassoBounds) && !warned) {
                warningMessage("Overlap Markers!");
                warned = true;
            }

            let area = convertNumberToCommaNotation(
                (
                    window.google.maps.geometry.spherical.computeArea(lassoPolygon.getPath()) / 2
                ).toFixed(2) / 2589988.11
            );

            setMapState((prevState) => {
                let selectedPins = [];
                let alreadySelectedPins = [];

                // CALCULATE WHICH POINTS ARE IN THE SELECTED AREA
                selectedPins = prevState.data?.rawData?.filter((item) => {
                    if (
                        maps.geometry?.poly.containsLocation(
                            new maps.LatLng({
                                lat: Number(item.location.lat),
                                lng: Number(item.location.lng),
                            }),
                            lassoPolygon
                        ) &&
                        item?.history?.at(-1)?.status
                    ) {
                        alreadySelectedPins.push(item);
                    }

                    return (
                        maps.geometry?.poly.containsLocation(
                            new maps.LatLng({
                                lat: Number(item.location.lat),
                                lng: Number(item.location.lng),
                            }),
                            lassoPolygon
                        ) && !item?.history?.at(-1)?.status
                    );
                });

                if (selectedPins?.length) {
                    // removeEvents(map, maps, true);

                    return {
                        ...prevState,
                        shapeArea: area,
                        selectedPins,
                        path: lassoPolygon.getPath().getArray(),
                        shapeType: "lasso",
                        showError: false,
                    };
                }

                if (prevState.data.rawData) {
                    return {
                        ...prevState,
                        shapeArea: null,
                        selectedPins: [],
                        shapeType: "",
                        showError: true,
                        path: "",
                        errorMessage: "Selection must contain a minimum of one marker.",
                    };
                }

                return prevState;
            });
        });
    });
}

function triangulationCallback(props) {
    repeatedActions("#triangulationButton", props);
    $('button[title="Stop drawing"]')?.click();
    props.toggleMapHistory(false);

    let { maps, setMapState } = props;

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((data, err) => {
            if (data) {
                let userCordinates = { lat: data.coords.latitude, lng: data.coords.longitude };

                setMapState((prevState) => {
                    if (prevState.data.rawData) {
                        let newRawData = [...prevState.data.rawData];

                        newRawData?.forEach((item) => {
                            let distance = distanceBetweenTwoMarkersUsingMaps(
                                maps,
                                userCordinates,
                                { lat: Number(item.location.lat), lng: Number(item.location.lng) }
                            );
                            item.distanceFromUser = distance;
                        });

                        return {
                            ...prevState,
                            center: userCordinates,
                            userLocation: userCordinates,
                            triangulation: true,
                            data: {
                                ...prevState.data,
                                rawData: newRawData,
                            },
                        };
                    }

                    return prevState;
                });
            }
            if (err) {
                return false;
            }
        });
    }
}

export const IsMarkerInShape = (position, pathInfo) => {
    const latLng = new window.google.maps.LatLng(position.lat, position.lng);

    if (pathInfo?.shapeType === "circle") {
        const center = calculateCircleCenter(pathInfo?.path);
        const radius = calculateCircleRadius(center, pathInfo?.path[0]);
        const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
            center,
            latLng
        );
        return distance <= radius;
    } else if (pathInfo?.shapeType === "rectangle") {
        const bounds = new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(pathInfo?.bounds?.south, pathInfo?.bounds?.west),
            new window.google.maps.LatLng(pathInfo?.bounds?.north, pathInfo?.bounds?.east)
        );
        return bounds.contains(latLng);
    } else if (
        pathInfo?.shapeType === "lasso" ||
        pathInfo?.shapeType === "polygon" ||
        pathInfo?.shapeType === "kmlPolygon"
    ) {
        let selectPath =
            pathInfo?.shapeType === "kmlPolygon" ? pathInfo?.pathsArr[0] : pathInfo?.path;
        let flatternArray = selectPath?.flat();
        const paths = flatternArray?.map(
            (path) => new window.google.maps.LatLng(path.lat, path.lng)
        );
        const polygon = new window.google.maps.Polygon({ paths });
        return window.google.maps.geometry.poly.containsLocation(latLng, polygon);
    }
    return false;
};

export function SinglePinHistory(map, centerPoint) {
    let radius = 30000;
    let circle = new google.maps.Circle({
        map: map,
        center: centerPoint,
        radius: radius,
        zIndex: 1,
        ...drawingToolsOptions.circleOptions,
        clickable: false,
    });

    // Ensure circle is fully initialized before using its properties
    google.maps.event.addListenerOnce(circle, "center_changed", function () {
        let path = getCirclePath(circle);
        return path;
    });
}
