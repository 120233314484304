import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GEO_MAPPING_BASE_URL_API } from "../config";
import ShareETA from "../components/icons/ShareETA";

const baseApi = createApi({
    reducerPath: "baseApi",

    baseQuery: fetchBaseQuery({
        baseUrl: GEO_MAPPING_BASE_URL_API,

        prepareHeaders: (headers, { getState }) => {
            const stateToken = getState().auth.token;
            const token = localStorage.getItem("authToken");
            headers.set("x-auth-token", stateToken || token || "");
            return headers;
        },
    }),

    tagTypes: [
        "CONNECTED-USERS",
        "MAPS",
        "SHARED-MAPS",
        "GROUPS",
        "ROUTES",
        "MOVE-PIN",
        "UPDATE-PIN",
        "GET-PAYMENT-CARD",
        "GET-PAYMENT-INVOICES",
        "GET-PLAN",
        "GET-WORKERS",
        "GET-USER-DATA",
        "NOTIFICATIONS",
        "SYSTEM-NOTIFICATIONS",
        "GROUP-HISTORY",
        "GET-ALL-GROUPS-BY-MAPID",
    ],

    endpoints: (builder) => ({
        // ---------------------------
        // USER API CALLS
        // ---------------------------

        // SIGNUP USER
        addUser: builder.mutation({
            query: (body) => ({ url: "users", method: "POST", body }),
        }),

        // RESEND VERIFICATION
        resendVerification: builder.mutation({
            query: (body) => ({ url: "sendEmailVerification", method: "POST", body }),
        }),

        // WHEN USER VISIT THE APP
        getUserByToken: builder.query({
            query: () => "users/getUserByAuthtoken",

            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => ({
                ...response?.data,
                token: meta.request.headers.get("x-auth-token"),
            }),
        }),

        // LOGIN
        getUserByEmailAndPassword: builder.mutation({
            query: (body) => ({ url: "users/login", method: "POST", body }),

            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => ({
                ...response?.data,
                token: response.token,
            }),
        }),

        getUserByGoogle: builder.query({
            query: (body) => ({ url: "users/signinwithgoogle", method: "POST", body }),
        }),

        getUserByFacebook: builder.query({
            query: (body) => ({ url: "users/signinWithFacebook", method: "POST", body }),
        }),

        requestResetPassword: builder.mutation({
            query: (body) => ({ url: "users/requestResetPassword", method: "POST", body }),
        }),

        resetPassword: builder.mutation({
            query: (body) => ({ url: "users/resetPassword", method: "POST", body }),
        }),
        resetPasswordOnLogin: builder.mutation({
            query: (body) => ({ url: "users/resetPasswordOnLogin", method: "POST", body }),
        }),

        verifyToken: builder.mutation({
            query: (body) => ({ url: "verify", method: "POST", body }),
        }),

        //TOGGLE 2-STEP VERIFICATION
        toggleTwoStepVerification: builder.mutation({
            query: (body) => ({ url: "user/enableTwoStepVerification", method: "PATCH", body }),
            invalidatesTags: ["GET-USER-DATA"],
        }),

        verifyTwoStepVerification: builder.mutation({
            query: (body) => ({ url: "user/verifyTwoStepVerification", method: "POST", body }),
            // invalidatesTags: ["GET-USER-DATA"],
        }),
        sendUserCode: builder.mutation({
            query: (body) => ({ url: "users/signOnCode", method: "POST", body }),
            // invalidatesTags: ["GET-USER-DATA"],
        }),
        confirmUserVerificationCode: builder.mutation({
            query: ({ email, code }) => ({
                url: "users/signOn",
                method: "POST",
                body: { email, code },
            }),
        }),
        // ---------------------------
        // USERS API CALLS
        // ---------------------------
        getPlanDetails: builder.query({
            query: () => "plan-details",
            transformResponse: (response) => response.data,
        }),
        getUserPlan: builder.query({
            query: (email) => `plan?auth=${email}`,
            transformResponse: (response) => response.data,
            providesTags: ["GET-PLAN"],
        }),
        getUserData: builder.query({
            query: (email) => `user/email/${email}`,
            transformResponse: (response) => response.data,
            providesTags: ["GET-USER-DATA"],
        }),

        updateUserData: builder.mutation({
            query: (body) => ({
                url: `user/email/${body.email}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["GET-USER-DATA"],
        }),

        updateUserImage: builder.mutation({
            query: (body) => ({
                url: `/user/update/profile`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["GET-USER-DATA"],
        }),

        updateCoverImage: builder.mutation({
            query: (body) => ({
                url: `/user/update/coverimage`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["GET-USER-DATA"],
        }),

        deleteUserProfileImage: builder.mutation({
            query: (email) => ({ url: `user/profile/avatar/${email}`, method: "DELETE" }),
            invalidatesTags: ["GET-USER-DATA"],
        }),
        deleteUserCoverImage: builder.mutation({
            query: (email) => ({ url: `user/profile/cover/${email}`, method: "DELETE" }),
            invalidatesTags: ["GET-USER-DATA"],
        }),

        getUsersList: builder.query({
            query: (pageNum) => `getUsers?pageNum=${pageNum}`,
            transformResponse: (response) => response.userdata,
        }),

        getConnectedUsers: builder.query({
            query: ({ search, email, pageNum, mapType, mapId, filter }) =>
                `connected-users/${mapId}?search=${search}&auth=${email}&pageNum=${pageNum}&type=${mapType}&filter=${filter}`,
            transformResponse: (response) => response?.data,
            providesTags: ["CONNECTED-USERS"],
        }),

        deleteUserFromMapOrGroup: builder.mutation({
            query: (body) => ({
                url: `delete-share/${body.id}`,
                method: "PUT",
                body: { email: body.email, type: body.type },
            }),

            invalidatesTags: (result, error, arg) => {
                let tags = [];

                switch (arg.mapType) {
                    case 1:
                        tags = ["MAPS", "SYSTEM-NOTIFICATIONS"];
                        break;

                    case 4:
                        tags = ["GROUPS", "SYSTEM-NOTIFICATIONS"];
                        break;

                    default:
                        break;
                }

                return tags;
            },
        }),

        deleteUserFromSharedMap: builder.mutation({
            query: (body) => ({
                url: `share/delete-share/${body.id}`,
                method: "PUT",
                body: { email: body.email, type: body.type },
            }),

            invalidatesTags: ["SHARED-MAPS", "SYSTEM-NOTIFICATIONS"],
        }),

        deleteUserFromMyRoutes: builder.mutation({
            query: (body) => ({
                url: `route/delete-share/${body.id}`,
                method: "PUT",
                body: { email: body.email, type: body.type },
            }),

            invalidatesTags: ["ROUTES", "SYSTEM-NOTIFICATIONS"],
        }),

        // ---------------------------
        // MAP API CALLS
        // ---------------------------

        //  PUBLIC VIEW MAP AND GROUP
        publicViewMapOrGroup: builder.mutation({
            query: (body) => ({
                url: `publicview/${body.id}`,
                method: "POST",
                body: { type: body.type, password: body.password },
            }),
        }),

        //PUBLIC VIEW ROUTE
        getPublicViewRoute: builder.query({
            query: (id) => `routepublicview/${id}?type=map`,
        }),

        getAllMaps: builder.query({
            query: (email) => `maps?auth=${email}`,
            transformResponse: (response) => response.data,

            providesTags: ["MAPS"],
        }),

        getAllSharedMaps: builder.query({
            query: (email) => `shared-maps?auth=${email}`,
            transformResponse: (response) => response.data,

            providesTags: ["SHARED-MAPS"],
        }),

        getMapById: builder.query({
            query: (id) => `maps/${id}`,

            providesTags: ["UPDATE-PIN"],
        }),

        // SHARE MAP
        shareMap: builder.mutation({
            query: (body) => ({
                url: `${body.type}/${body.id}`,
                // url: `share-map/${body.id}`,
                method: "PUT",
                // body: { email: body.email },
                body: body.email,
            }),
            invalidatesTags: (result, error, arg) => {
                return arg.type === "share-map"
                    ? ["MAPS", "CONNECTED-USERS", "SYSTEM-NOTIFICATIONS"]
                    : ["SHARED-MAPS", "ROUTES", "CONNECTED-USERS", "SYSTEM-NOTIFICATIONS"];
            },
        }),

        toggleMapFav: builder.mutation({
            query: (body) => ({
                url: `maps/favorite/${body.id}`,
                method: "PUT",
                body: { favorite: body.favorite },
            }),

            invalidatesTags: ["MAPS"],
        }),

        toggleShareMapFav: builder.mutation({
            query: (body) => ({
                url: `share-map/favorite/${body.id}`,
                method: "PUT",
                body: { favorite: body.favorite },
            }),

            invalidatesTags: ["SHARED-MAPS"],
        }),

        // CREATE MAP
        createMap: builder.mutation({
            query: (body) => ({ url: `maps`, method: "POST", body }),
            transformResponse: (response) => response?.data,
            invalidatesTags: ["MAPS"],
        }),

        // UPDATE MAP IMAGE
        updateMapImage: builder.mutation({
            query: (body) => ({ url: `map/update/image`, method: "POST", body }),
            invalidatesTags: ["MAPS"],
        }),
        // DELETE MAP IMAGE
        deleteMapImage: builder.mutation({
            query: (body) => ({
                url: `map/update/image`,
                method: "DELETE",
                body: {
                    id: body.id,
                },
            }),
            invalidatesTags: ["MAPS"],
        }),
        // UPDATE MAP
        updateMap: builder.mutation({
            query: ({ id, body }) => ({
                url: `maps/${id}`,
                method: "PATCH",
                body,
            }),
            invalidatesTags: ["MAPS", "GROUPS"],
        }),

        // GET PIN DATA
        getMarkerData: builder.query({
            query: ({ map_id, pin_id, type }) =>
                `get-single-pin?map_id=${map_id}&pin_id=${pin_id}&type=${type}`,
            transformResponse: (response) => response.data,
            providesTags: ["UPDATE-PIN"],
        }),

        // UPDATE PIN
        updatePin: builder.mutation({
            query: ({ id, body }) => ({
                url: `edit-pin/${id}`,
                method: "PATCH",
                body,
            }),
            invalidatesTags: (rslt, err, args) =>
                args.body.type === "group" ? ["GROUPS"] : ["MAPS", "SHARED-MAPS"],
            // invalidatesTags: ["UPDATE-PIN"],
        }),
        // UPDATE GROUP
        updateGroup: builder.mutation({
            query: ({ id, title }) => ({
                url: `group-update`,
                method: "PUT",
                body: {
                    id: id,
                    title: title,
                },
            }),
            invalidatesTags: ["GROUPS"],
        }),

        // DELETE MAP
        deleteMap: builder.mutation({
            query: (id) => ({ url: `maps/${id}`, method: "DELETE" }),
            invalidatesTags: ["MAPS", "GROUPS"],
        }),

        // DELETE MULTIPLE MAPS
        deleteMultipleMaps: builder.mutation({
            query: (multipleDelArr) => ({
                url: `delete-maps`,
                method: "DELETE",
                body: {
                    maps: multipleDelArr,
                },
            }),
            invalidatesTags: ["MAPS"],
        }),
        // DELETE MULTIPLE MY SHARED
        deleteMultipleMyShared: builder.mutation({
            query: (multipleDelArr) => ({
                url: `delete-shares`,
                method: "DELETE",
                body: {
                    ids: multipleDelArr,
                },
            }),
            invalidatesTags: ["SHARED-MAPS"],
        }),
        // DELETE MULTIPLE ROUTES
        deleteMultipleRoutes: builder.mutation({
            query: (multipleDelArr) => ({
                url: `delete-routes`,
                method: "DELETE",
                body: {
                    ids: multipleDelArr,
                },
            }),
            invalidatesTags: ["ROUTES"],
        }),

        // DELETE MULTIPLE GROUPS
        deleteMultipleGroups: builder.mutation({
            query: (multipleDelArr) => ({
                url: `delete-groups`,
                method: "DELETE",
                body: {
                    ids: multipleDelArr,
                },
            }),
            invalidatesTags: ["GROUPS"],
        }),

        // DELETE SHARED MAP
        deleteShareMap: builder.mutation({
            // query: ({ id, email, type }) => ({
            //     url: `delete-share/${id}`,
            //     method: "DELETE",
            //     body: { email: email, type: type },
            // }),
            query: (id) => ({
                url: `delete-share/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["SHARED-MAPS"],
        }),

        //GET ALL ROUTES
        getAllRoutes: builder.query({
            query: (email) => `route-ios/${email}`,
            transformResponse: (response) => response.data,
            providesTags: ["ROUTES"],
        }),

        // DELETE ROUTE MAP
        deleteRouteMap: builder.mutation({
            query: (id) => ({ url: `route/${id}`, method: "DELETE" }),
            invalidatesTags: ["ROUTES"],
        }),

        // FAV ROUTE MAP
        toggleRouteFav: builder.mutation({
            query: (body) => ({
                url: `routes/favorite/${body.id}`,
                method: "PUT",
                body: { favorite: body.favorite },
            }),

            invalidatesTags: ["ROUTES"],
        }),

        getRouteById: builder.query({
            query: (id) => `route/${id}`,
            providesTags: ["UPDATE-PIN"],
        }),

        // UPDATE ROUTE IMAGE
        updateRouteImage: builder.mutation({
            query: (body) => ({ url: `route/update/image`, method: "POST", body }),
            invalidatesTags: ["ROUTES"],
        }),
        // DELETE ROUTE IMAGE
        deleteRouteImage: builder.mutation({
            query: (body) => ({
                url: `route/update/image`,
                method: "DELETE",
                body: {
                    id: body.id,
                },
            }),
            invalidatesTags: ["ROUTES"],
        }),

        shareETALocation: builder.mutation({
            query: ({ id, pinId, email }) => ({
                url: `/share-eta/${id}`,
                method: "PUT",
                body: {
                    email: email,
                    pinId: pinId,
                },
            }),
            invalidatesTags: ["ROUTES"],
        }),
        getETAData: builder.query({
            query: ({ id, pinId, email }) => ({
                url: `/eta-pin/${email}?routeId=${id}&pinId=${pinId}`,
                method: "GET",
            }),
        }),

        // ---------------------------
        // GROUP API CALLS
        // ---------------------------

        getAllGroups: builder.query({
            query: (email) => `group-ios/${email}`,
            transformResponse: (response) => response?.data,
            providesTags: ["GROUPS"],
        }),

        getGroupsHistory: builder.query({
            query: ({ id, type }) => `group-history/${id}?type=${type}`,
            transformResponse: (response) => response?.data,
            providesTags: ["GROUP-HISTORY"],
        }),

        getGroupById: builder.query({
            query: (id) => `group/${id}`,
            providesTags: ["UPDATE-PIN"],
        }),

        getAllGroupsByMapId: builder.query({
            query: (id) => `get-all-map-groups/${id}`,
            // providesTags: ['GET-ALL-GROUPS-BY-MAPID'],
            transformResponse: (response) => response?.data,
            // providesTags: ["GET-ALL-GROUPS-BY-MAPID"],
        }),

        getAllDirectGroupsByMapId: builder.query({
            query: (id) => `get-all-map-direct-groups/${id}`,
            // providesTags: ['GET-ALL-GROUPS-BY-MAPID'],
            transformResponse: (response) => response?.data,
        }),

        getAllGroupsByGroupId: builder.query({
            query: (id) => `get-all-group-groups/${id}`,
            transformResponse: (response) => response?.data,
        }),
        getAllSharedMapGroups: builder.query({
            query: (id) => `get-all-sharedMap-groups/${id}`,
            transformResponse: (response) => response?.data,
        }),
        getAllSharedMapGroupGroups: builder.query({
            query: (id) => `get-all-sharedMap-group-groups/${id}`,
            transformResponse: (response) => response?.data,
        }),
        // GROUP ROUTE IMAGE
        updateGroupImage: builder.mutation({
            query: (body) => ({ url: `group/update/image`, method: "POST", body }),
            invalidatesTags: ["GROUPS"],
        }),
        // GROUP ROUTE IMAGE
        deleteGroupImage: builder.mutation({
            query: (body) => ({
                url: `group/update/image`,
                method: "DELETE",
                body: {
                    id: body.id,
                },
            }),
            invalidatesTags: ["GROUPS"],
        }),

        // SHARE GROUP
        shareGroup: builder.mutation({
            query: (body) => ({
                url: `share-group/${body.id}`,
                method: "PUT",
                body: body.email,
            }),
            invalidatesTags: ["GROUPS", "CONNECTED-USERS"],
        }),

        toggleGroupFav: builder.mutation({
            query: (body) => ({
                url: `groups/favorite/${body.id}`,
                method: "PUT",
                body: { favorite: body.favorite },
            }),

            invalidatesTags: ["GROUPS"],
            // invalidatesTags: ["GROUPS","GET-ALL-GROUPS-BY-MAPID"],
        }),

        // CREATE GROUP
        createGroup: builder.mutation({
            query: (body) => ({ url: `groups`, method: "POST", body }),
            invalidatesTags: ["GROUPS", "MAPS", "SHARED-MAPS"],
        }),

        // CREATE GROUP LIST
        createGroupsList: builder.mutation({
            query: (body) => ({ url: `group-list`, method: "POST", body }),
            invalidatesTags: ["GROUPS", "MAPS"],
        }),

        // DELETE GROUP
        deleteGroup: builder.mutation({
            query: (id) => ({ url: `group/${id}`, method: "DELETE" }),
            invalidatesTags: ["GROUPS", "MAPS"],
        }),

        // ---------------------------
        // PINS API CALLS
        // ---------------------------
        movePin: builder.mutation({
            query: (body) => ({ url: `movepin`, method: "POST", body }),
            invalidatesTags: (rslt, err, args) =>
                // args.type === "group" ? ["GROUPS", "MOVE-PIN"] : ["MAPS"],
                args.type === "group" ? ["GROUPS"] : ["MAPS"],
        }),

        deletePin: builder.mutation({
            query: (body) => ({
                url: `deletepin`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["MAPS", "GROUPS"],
        }),

        // CREATE PAYMENT CARD
        createBillingCard: builder.mutation({
            query: ({ body, email }) => ({ url: `card/${email}`, method: "POST", body }),
            invalidatesTags: ["GET-PAYMENT-CARD"],
        }),

        // GET PAYMENT CARD
        getAllBillingCards: builder.query({
            query: (email) => `card/${email}`,
            providesTags: ["GET-PAYMENT-CARD"],
        }),

        // DELETE PAYMENT CARD
        deletePaymentCard: builder.mutation({
            query: (id) => ({ url: `card/${id}`, method: "DELETE" }),
            invalidatesTags: ["GET-PAYMENT-CARD"],
        }),
        cancelSubscription: builder.mutation({
            query: (body) => ({
                url: `call-cancel-subcription/${body.id}`,
                method: "POST",
                body: body,
            }),
            invalidatesTags: ["GET-USER-DATA", "GET-PLAN"],
        }),
        // cancelSubscription: builder.mutation({
        //     query: (id) => ({ url: `call-cancel-subcription/${id}`, method: "DELETE" }),
        //     invalidatesTags: ["GET-USER-DATA", "GET-PLAN"],
        // }),

        setPrimaryPaymentCard: builder.mutation({
            query: (body) => ({
                url: "card/set-primary",
                method: "PUT",
                body,
            }),

            invalidatesTags: ["GET-PAYMENT-CARD"],
        }),

        // GET PAYMENT iNVOICES
        getPaymentInvoices: builder.query({
            query: (email) => `invoices/${email}`,
            providesTags: ["GET-PAYMENT-INVOICES"],
        }),

        // CREATE PAYMENT INTENT
        createPaymentIntent: builder.mutation({
            query: ({ email, plan }) => ({
                url: `create-payment-intent/${email}`,
                method: "POST",
                body: { email: email, plan: plan },
            }),
            // invalidatesTags: ["GET-PAYMENT-INVOICES", "GET-PLAN"],
        }),

        //  SUBSCRIBE PLAN
        subscribePlan: builder.mutation({
            query: ({ email, plan, password }) => ({
                url: "charge",
                method: "POST",
                body: { email: email, plan: plan, password: password },
            }),
            invalidatesTags: ["GET-PAYMENT-INVOICES", "GET-PLAN"],
        }),

        // GET CO-WORKERS
        getCoworkers: builder.query({
            query: (email) => `invite/${email}`,
            providesTags: ["GET-WORKERS"],
        }),

        // GET TEAM CO-WORKERS BY TOKEN
        getTeamCoworkerByToken: builder.query({
            query: (token) => `team-user-token/${token}`,
        }),

        //  INVITE CO-WORKERS
        createCoworker: builder.mutation({
            query: ({ email, message, receiverEmail, password }) => ({
                url: `invite/${email}`,
                method: "POST",
                body: { shared: receiverEmail, msg: message, password: password },
            }),
            invalidatesTags: ["GET-WORKERS"],
        }),

        // DELETE CO-WORKERS
        deleteCoworker: builder.mutation({
            query: ({ email, coworkerEmail }) => ({
                url: `invite/${email}`,
                method: "DELETE",
                body: {
                    shared: coworkerEmail,
                },
            }),
            invalidatesTags: ["GET-WORKERS"],
        }),

        //  ACCEPT TEAM CO-WORKERS INVITE
        acceptInviteTeamCoworker: builder.mutation({
            query: ({ sender, receiver, token }) => ({
                url: "team-invitation",
                method: "POST",
                body: { transmitter: sender, recipient: receiver, token: token },
            }),
            invalidatesTags: ["GET-WORKERS"],
        }),

        //  ACCEPT CO-WORKERS INVITE  (If team then of no use)
        acceptInviteCoworker: builder.mutation({
            query: ({ email, shared }) => ({
                url: "accept",
                method: "POST",
                body: { email: email, shared: shared },
            }),
            invalidatesTags: ["GET-WORKERS"],
        }),

        //  CHANGE ROLE OF A CO-WORKER
        toggleRoleCoworker: builder.mutation({
            query: ({ email, coworkerEmail, role }) => ({
                url: "role",
                method: "PATCH",
                body: { email: email, role: role, child: coworkerEmail },
            }),
            invalidatesTags: ["GET-WORKERS"],
        }),

        //  CHANGE STATUS OF A CO-WORKER
        toggleStatusCoworker: builder.mutation({
            query: ({ email, coworkerEmail, status }) => ({
                url: "check_active",
                method: "PATCH",
                body: { email: email, isActive: status, child: coworkerEmail },
            }),
            invalidatesTags: ["GET-WORKERS"],
        }),

        //  CONTACT ENTERPRSIE MODAL
        upgradeEnterpriseContact: builder.mutation({
            query: ({ body }) => ({
                url: "enterprise-upgrade",
                method: "POST",
                body,
            }),
        }),

        //  UPDATE USER NOTIFICATIONS
        updateUserNotifications: builder.mutation({
            query: ({ body, id }) => ({
                url: `user/notifications/${id}`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["NOTIFICATIONS"],
        }),

        // GET CO-WORKERS
        getUserNotifications: builder.query({
            query: (id) => `/user/notifications/${id}`,
            providesTags: ["NOTIFICATIONS"],
        }),

        // SUBMIT ISSUE
        submitIssue: builder.mutation({
            query: (body) => ({
                url: "issue-submission",
                method: "POST",
                body,
            }),
        }),
        // ONE-SIGNAL NOTIFICATIONS IMPLEMENT
        getSystemNotifications: builder.query({
            query: ({ email, pageNum, filter, search }) =>
                `/get-all-notifications?search=${search}&auth=${email}&pageNum=${pageNum}&filter=${filter}`,
            providesTags: ["SYSTEM-NOTIFICATIONS"],
        }),

        setSystemNotificationsStatus: builder.mutation({
            query: ({ id, status }) => ({
                url: `/update-unread/${id}?state=${status}`,
                method: "PATCH",
                body: { unread: status },
            }),
            invalidatesTags: ["SYSTEM-NOTIFICATIONS"],
        }),

        deleteSystemNotification: builder.mutation({
            query: (id) => ({
                url: `/delete-notification/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["SYSTEM-NOTIFICATIONS"],
        }),

        // ONE-SIGNAL NOTIFICATIONS IMPLEMENT
    }),
});

// const favMap_list = ["maps", "share-map", "route", "groups"];

export const {
    // useLazyGetPublicViewMapQuery,
    useLazyGetPublicViewRouteQuery,
    usePublicViewMapOrGroupMutation,

    // USERS LOGIN SIGNUP
    useAddUserMutation,
    useResendVerificationMutation,
    useGetUserByTokenQuery,
    useGetUserByEmailAndPasswordMutation,
    useLazyGetUserByGoogleQuery,
    useLazyGetUserByFacebookQuery,
    useRequestResetPasswordMutation,
    useResetPasswordMutation,
    useResetPasswordOnLoginMutation,
    useVerifyTokenMutation,
    useToggleTwoStepVerificationMutation,
    useVerifyTwoStepVerificationMutation,
    useSendUserCodeMutation,
    useConfirmUserVerificationCodeMutation,

    // USER MAP RELATED API
    useGetUserPlanQuery,
    useGetUserDataQuery,
    useGetPlanDetailsQuery,
    useUpdateUserDataMutation,
    useUpdateUserImageMutation,
    useUpdateCoverImageMutation,
    useDeleteUserCoverImageMutation,
    useDeleteUserProfileImageMutation,
    useLazyGetUsersListQuery,
    useGetConnectedUsersQuery,
    useDeleteUserFromMapOrGroupMutation,
    useDeleteUserFromSharedMapMutation,
    useDeleteUserFromMyRoutesMutation,

    // MAPS API
    useGetAllMapsQuery,
    useGetAllRoutesQuery,
    useGetAllSharedMapsQuery,
    useGetMapByIdQuery,
    useLazyGetMapByIdQuery,
    useShareMapMutation,
    useCreateMapMutation,
    useUpdateMapMutation,
    useUpdateMapImageMutation,
    useDeleteMapImageMutation,
    useUpdatePinMutation,
    useLazyGetMarkerDataQuery,
    useUpdateGroupMutation,
    useDeleteMapMutation,
    useDeleteMultipleMapsMutation,
    useDeleteMultipleMySharedMutation,
    useDeleteMultipleGroupsMutation,
    useDeleteMultipleRoutesMutation,
    useDeleteShareMapMutation,
    useDeleteRouteMapMutation,
    useToggleMapFavMutation,
    useToggleShareMapFavMutation,
    useToggleRouteFavMutation,
    useUpdateRouteImageMutation,
    useDeleteRouteImageMutation,
    useShareETALocationMutation,
    useLazyGetETADataQuery,

    // GROUPS API
    useGetAllGroupsQuery,
    useLazyGetAllSharedMapGroupsQuery,
    useLazyGetAllSharedMapGroupGroupsQuery,
    useLazyGetGroupsHistoryQuery,
    useGetGroupsHistoryQuery,
    useLazyGetGroupByIdQuery,
    useLazyGetRouteByIdQuery,
    useLazyGetAllGroupsByMapIdQuery,
    useLazyGetAllDirectGroupsByMapIdQuery,
    useLazyGetAllGroupsByGroupIdQuery,
    useShareGroupMutation,
    useToggleGroupFavMutation,
    useCreateGroupMutation,
    useCreateGroupsListMutation,
    useDeleteGroupMutation,
    useUpdateGroupImageMutation,
    useDeleteGroupImageMutation,

    // PINS API
    useMovePinMutation,
    useDeletePinMutation,

    // BILLING API
    useCreateBillingCardMutation,
    useGetAllBillingCardsQuery,
    useSetPrimaryPaymentCardMutation,
    useCreatePaymentIntentMutation,
    useSubscribePlanMutation,
    useGetPaymentInvoicesQuery,
    useDeletePaymentCardMutation,
    useCancelSubscriptionMutation,

    //MANAGE USERS API
    useGetCoworkersQuery,
    useGetTeamCoworkerByTokenQuery,
    useCreateCoworkerMutation,
    useDeleteCoworkerMutation,
    useAcceptInviteCoworkerMutation,
    useAcceptInviteTeamCoworkerMutation,
    useToggleRoleCoworkerMutation,
    useToggleStatusCoworkerMutation,
    useUpgradeEnterpriseContactMutation,

    // NOTIFICATIONS API
    useUpdateUserNotificationsMutation,
    useGetUserNotificationsQuery,
    useSubmitIssueMutation,

    //SYSTEM-NOTIFICATIONS API
    useGetSystemNotificationsQuery,
    useSetSystemNotificationsStatusMutation,
    useDeleteSystemNotificationMutation,
} = baseApi;

export default baseApi;
