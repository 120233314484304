import React from "react";
import { Link } from "react-router-dom";
import arrowLeft from "../../../assets/images/arrowLeft.png";
import DotLoader from "../../../components/DotLoaderBtn/DotLoader";
import { debounce } from "../../../utils/utils";
import FailedRecordRows from "./FailedRecordRows";

const CreateMapNav = (props) => {
    const { locUpdatingRef, changedRow, state, handleUpdateLoc, handleSaveMapSubmit } = props;
    // const updateLocation = () => {
    //     handleUpdateLoc();
    // };

    return (
        <div className="createMap-nav flex-between">
            <h5>
                <Link to="/dashboard">
                    <img
                        src={arrowLeft}
                        alt="left arrow icon"
                        style={{ width: "22px", marginRight: "10px" }}
                    />
                    <strong>Add map</strong>
                </Link>
            </h5>

            {props.showFailedRecords && (
                <FailedRecordRows
                    failedMapData={props.state.failedRows}
                    setShowFailedRecords={props.setShowFailedRecords}
                />
            )}

            <div className="mx-10" style={{ marginRight: 20 }}>
                <button className="btn cancel" onClick={props.handleCancel}>
                    Cancel
                </button>

                {props.state.map.showMap ? (
                    <>
                        {props.state.saveLoading ? (
                            <button
                                className="btn animate-dots-blue cta-border-blue-auto mx-10"
                                type="button"
                                disabled
                            >
                                <DotLoader />
                            </button>
                        ) : (
                            <>
                                {/* {!state?.updateLocations && changedRow?.length > 0 ? (
                                    <>
                                        {locUpdatingRef ? (
                                            <button
                                                className="btn animate-dots-red del-outline-btn"
                                                type="button"
                                                disabled
                                            >
                                                <DotLoader />
                                            </button>
                                        ) : (
                                            <button
                                                className="btn save update-loc"
                                                onClick={updateLocation}
                                            >
                                                Update Locations
                                            </button>
                                        )}
                                    </>
                                ) : (
                                    <button
                                        className="btn save"
                                        onClick={debounce(handleSaveMapSubmit, 500)}
                                        disabled={props.state.updateLoading}
                                    >
                                        Save
                                    </button>
                                )} */}
                                <button
                                    className="btn save"
                                    onClick={debounce(handleSaveMapSubmit, 500)}
                                    disabled={props.state.updateLoading}
                                >
                                    Save
                                </button>
                            </>
                        )}
                    </>
                ) : (
                    <button
                        className="btn save"
                        disabled={!props.state.rawData?.length}
                        onClick={debounce(() => {
                            props.updatingRef.current = false;
                            props.handleMapNow();
                        }, 300)}
                    >
                        Map Data
                    </button>
                )}
            </div>
        </div>
    );
};

export default CreateMapNav;
