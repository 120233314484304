/* global google */
import { memo, useEffect, useMemo, useState } from "react";
import { InfoWindow } from "@react-google-maps/api";

import MarkerInfo from "../../../components/Map/MarkerInfo";
import MapGroups from "../../../components/MapGroups/MapGroups";
import MapMarkers from "../../../components/Map/MapMarkers";
import Map from "../../../components/Map/Map";
import { setMapBounds } from "../../../utils/mapUtils";

import MarkerOptionsEditMap from "../../../components/myMaps/MarkerOptionsEditMap";
import socket, { addSetSocketMap, deleteSocketMap } from "../../../socket/socket";
import EditMapData from "../../../components/EditMapData/EditMapData";
import CreateMapNav from "./CreateMapNav";
import GoogleMapResizer from "../../../components/GoogleMapResizer/GoogleMapResizer";
import { UPDATE_MAP_LOCATION } from "../../../socket/socketsList";
import { v4 as uuid } from "uuid";
import { rawData } from "../../../utils/constants";

// create Map BG map stryle
const containerStyle = {
    width: "100%",
    maxHeight: "100%",
    height: "100%",
};

const headers = [
    "name",
    "email",
    "phone",
    "url",
    "group",
    "address",
    "city",
    "state",
    "zip",
    "note",
    "opportunities",
    "opendate",
    "closedate",
];

const CreateMapView = (props) => {
    const {
        refState,
        createMapState,
        setCreateMapState,
        handleCancel,
        handleMapNow,
        handleSaveMapSubmit,
        zoomRef,
        changedRow,
        setChangedRow,
        setTableRowsData,
        tableRowsData,
        setCurrentUpdateData,
        setLocUpdatingRef,
        locUpdatingRef,
        rawDataIds,
        setRawDataIds,
    } = props;

    const [createMapViewState, setCreateMapViewState] = useState({
        showInfo: false,
        markerInfo: null,
        duplicateMarkers: null,
        showUserBox: false,
        showTable: true,
        center: { lat: 40.756795, lng: -73.954298 },
    });

    const [pinsFilterState, setPinsFilterState] = useState({
        activePinFilter: "group",
        showList: false,
    });
    const [hiddenMarkers, setHiddenMarkers] = useState([]);

    const [showFailedRecords, setShowFailedRecords] = useState(false);

    function handleMarkerClick(props, pin) {
        if (pin?.length > 1) {
            setCreateMapViewState((prevState) => ({
                ...prevState,
                markerInfo: pin?.[0],
                duplicateMarkers: pin,
                showInfo: true,
            }));
        } else {
            setCreateMapViewState((prevState) => ({
                ...prevState,
                markerInfo: pin?.[0],
                duplicateMarkers: null,
                showInfo: true,
            }));
        }
    }

    const handleShowTableData = (cond) => {
        setCreateMapViewState((prevState) => {
            return {
                ...prevState,
                showTable: cond,
            };
        });
    };

    const onMapLoad = ({ map, maps }) => {
        setCreateMapState((prevState) => {
            return {
                ...prevState,
                map: {
                    ...prevState.map,
                    mapRef: map,
                },
            };
        });
        // do not remove this  code
        // let manTimer = setInterval(() => {
        //     let streetViewImage = document.querySelector(".gm-svpc img");
        //     if (streetViewImage) {
        //         streetViewImage.src = streetViewIcon;
        //         clearInterval(manTimer);
        //     }
        // }, 500);

        setTimeout(() => {
            setMapBounds(
                map,
                createMapState.successRows,
                zoomRef,
                window.google?.maps?.LatLngBounds
            );
        }, 1000);
    };

    const getUpdatedData = (updatedData) => {
        if (updatedData) {
            setCreateMapState((prevState) => {
                return {
                    ...prevState,
                    rawData: updatedData,
                };
            });

            let map = socket.maps?.find((map) => map.id === createMapState.id);
            if (map) {
                map.rawData = updatedData;
            }
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setMapBounds(
                createMapState.map.mapRef,
                createMapState.successRows,
                zoomRef,
                window.google?.maps?.LatLngBounds
            );
        }, 0);

        return () => {
            deleteSocketMap(createMapState.id);
        };
    }, [createMapState.successRows, createMapState.id, zoomRef, createMapState.map.mapRef]);

    const info = useMemo(
        () => ({
            name: createMapViewState.markerInfo?.[createMapState.advanceOptions.name],
            email: createMapViewState.markerInfo?.[createMapState.advanceOptions.email],
            url: createMapViewState.markerInfo?.[createMapState.advanceOptions.url],
            phone: createMapViewState.markerInfo?.[createMapState.standardOptions.phone],
            address:
                createMapViewState.markerInfo?.[createMapState.standardOptions.address_location],
            zip: createMapViewState.markerInfo?.[createMapState.standardOptions.zip_postcode],
            city: createMapViewState.markerInfo?.[createMapState.standardOptions.city_country],
            state: createMapViewState.markerInfo?.[createMapState.standardOptions.state_province],
            group: createMapViewState.markerInfo?.[
                createMapState.standardOptions.groupby_thematicValue
            ],
        }),
        [
            createMapViewState.markerInfo,
            JSON.stringify(createMapState.advanceOptions),
            JSON.stringify(createMapState.standardOptions),
        ]
    );

    const handleCloseMarkerModel = () => {
        setCreateMapViewState((prevState) => {
            return {
                ...prevState,
                showInfo: false,
            };
        });
    };

    const handleUpdateLoc = () => {
        const isLocationUpdate = changedRow?.filter((item) => {
            if (
                tableRowsData.NAME !== item?.rowData?.NAME ||
                tableRowsData.CITY !== item?.rowData?.CITY ||
                tableRowsData.STATE !== item?.rowData?.STATE ||
                tableRowsData.ADDRESS !== item?.rowData?.ADDRESS ||
                tableRowsData.ZIP !== item?.rowData?.ZIP
            ) {
                return item;
            }
        });

        if (isLocationUpdate?.length > 0) {
            socket.emit(UPDATE_MAP_LOCATION, {
                mapID: createMapState?.id,
                socketID: socket.id,
                data: isLocationUpdate,
            });
            setLocUpdatingRef(true);
            addSetSocketMap(changedRow);
        }
        if (isLocationUpdate?.length === 0) {
            setTimeout(() => {
                setChangedRow([]);
            }, 500);
        }
    };

    const handleDelSelect = (e, id) => {
        if (id) {
            if (e.shiftKey) {
                if (rawDataIds.delSelected.includes(id)) {
                    setRawDataIds((prev) => {
                        let filteredSelections = rawDataIds.delSelected.filter(
                            (item) => item !== id
                        );
                        return {
                            ...prev,
                            delSelected: filteredSelections,
                        };
                    });
                } else {
                    setRawDataIds((prev) => {
                        return {
                            ...prev,
                            delSelected: [id, ...prev.delSelected],
                        };
                    });
                }
            } else {
                setRawDataIds((prev) => {
                    return {
                        ...prev,
                        delSelected: [id],
                    };
                });
            }
        } else {
            setRawDataIds((prev) => {
                return {
                    ...prev,
                    delSelected: [],
                };
            });
        }
    };

    const handleDeleteSelections = () => {
        let filteredData = tableRowsData?.filter(
            (item) => !rawDataIds?.delSelected?.includes(item.id)
        );
        let updatedSuccessRows = filteredData?.filter(
            (item) => item.location && item.location !== null
        );
        if (rawDataIds?.delSelected?.length > 0) {
            setCreateMapState((prev) => {
                return {
                    ...prev,
                    deletedLocations: rawDataIds.delSelected,
                };
            });
            setRawDataIds((prev) => {
                return {
                    ...prev,
                    delSelected: [],
                };
            });
        }
        setTableRowsData(filteredData);
        setCreateMapState((prev) => {
            return {
                ...prev,
                rawData: updatedSuccessRows,
                successRows: updatedSuccessRows,
            };
        });
    };

    const handleRowInsert = () => {
        let newItem = Object.keys(createMapState.rawData?.[0] || {}).reduce((acc, key) => {
            acc[key] = "";
            return acc;
        }, {});
        // newItem.location = "";
        newItem.id = uuid();

        let changedRowObj = {
            rowId: newItem.id,
            rowData: newItem,
        };

        setChangedRow([changedRowObj, ...changedRow]);
        setTableRowsData([newItem, ...tableRowsData]);
    };

    const handleInsertedEdit = (dataItem) => {
        const getUpdatedRows = tableRowsData.map((item) => {
            if (item?.id === dataItem?.id) {
                return dataItem;
            }
            return item;
        });
        setTableRowsData(getUpdatedRows);
    };

    return (
        <>
            <section className="map-options-container">
                <MarkerOptionsEditMap
                    refState={refState}
                    state={createMapState}
                    setState={setCreateMapState}
                    handleShowTableData={handleShowTableData}
                    create={true}
                    showFailedRecords={showFailedRecords}
                    setShowFailedRecords={setShowFailedRecords}
                    zoomRef={zoomRef}
                    // setMapBounds={setMapBounds}
                >
                    <CreateMapNav
                        state={createMapState}
                        handleCancel={handleCancel}
                        handleMapNow={handleMapNow}
                        handleSaveMapSubmit={handleSaveMapSubmit}
                        updatingRef={props.updatingRef}
                        showFailedRecords={showFailedRecords}
                        setShowFailedRecords={setShowFailedRecords}
                        changedRow={changedRow}
                        // locUpdatingRef={locUpdatingRef}
                        // handleUpdateLoc={handleUpdateLoc}
                    />
                </MarkerOptionsEditMap>

                <Map
                    componentProps={{
                        center: createMapViewState.center,
                        containerStyle,
                        mapStyle: createMapState.advanceOptions.mapStyle,
                        maxZoomOut: zoomRef.current.maxZoomOut,
                        defaultZoom: zoomRef.current.defaultZoom,
                        maxZoomIn: zoomRef.current.maxZoomIn,
                        onMapLoad: onMapLoad,
                    }}
                >
                    {/* MARKERS */}
                    {createMapState.successRows?.length && (
                        <MapMarkers
                            data={createMapState.successRows}
                            // data={createMapState.rawData}
                            map={createMapState.map.mapRef}
                            markerStyle={createMapState.advanceOptions?.markerStyle}
                            handleMarkerClick={handleMarkerClick}
                            hiddenMarkers={hiddenMarkers}
                            pinsFilterState={pinsFilterState}
                        />
                    )}

                    {createMapViewState.showInfo && (
                        <InfoWindow
                            onCloseClick={() => {
                                setCreateMapViewState((prevState) => {
                                    return {
                                        ...prevState,
                                        showInfo: false,
                                        markerInfo: null,
                                    };
                                });
                            }}
                            position={createMapViewState.markerInfo.location}
                            zIndex={2}
                        >
                            <MarkerInfo
                                // info={info}
                                info={createMapViewState.markerInfo}
                                mapState={createMapState}
                                setMapState={setCreateMapViewState}
                                handleCloseMarkerModel={handleCloseMarkerModel}
                            />
                        </InfoWindow>
                    )}
                </Map>
                <GoogleMapResizer />
                <MapGroups
                    state={createMapState}
                    markerStyle={
                        createMapState.advanceOptions?.customMarkerURL ||
                        createMapState.advanceOptions?.markerStyle
                    }
                    mapData={{
                        ...createMapState.standardOptions,
                        ...createMapState.advanceOptions,
                    }}
                    data={createMapState.groupedData}
                    pinsFilterState={pinsFilterState}
                    setPinsFilterState={setPinsFilterState}
                    hiddenMarkers={hiddenMarkers}
                    setHiddenMarkers={setHiddenMarkers}
                    editMode={true}
                    handleRowInsert={handleRowInsert}
                    rawDataIds={rawDataIds}
                    setRawDataIds={setRawDataIds}
                    handleDeleteSelections={handleDeleteSelections}
                    changedRow={changedRow}
                    handleUpdateLoc={handleUpdateLoc}
                    locUpdatingRef={locUpdatingRef}
                />
            </section>

            {createMapViewState.showTable && (
                <EditMapData
                    state={createMapState}
                    data={tableRowsData}
                    getUpdatedData={getUpdatedData}
                    headers={headers}
                    setCurrentUpdateData={setCurrentUpdateData}
                    rawDataIds={rawDataIds}
                    handleDelSelect={handleDelSelect}
                    handleInsertedEdit={handleInsertedEdit}
                />
            )}
        </>
    );
};

export default memo(CreateMapView);
