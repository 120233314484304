import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PinMarker from "../../assets/images/MarkerStyle/pin-icon.png";
import SquareMarker from "../../assets/images/MarkerStyle/square-icon.png";
import CircleMarker from "../../assets/images/MarkerStyle/circle-icon.png";
import TableView from "../../assets/images/createmap/table-view.png";
import CardView from "../../assets/images/createmap/card-view.png";
import eyeIcon from "../../assets/images/svg/eyeIcon.svg";
import eyeIconOff from "../../assets/images/svg/eyeIconOff.svg";
import noImage from "../../assets/images/noImage.png";
import infoTransparent from "../../assets/images/info-transparent-icon.png";
import copyIcon from "../../assets/images/copyIconGrey.png";
import ColorPicker from "../ColorPicker/ColorPicker";
import MapStyles from "../Map/MapStyles";
import GroupButtons from "../Map/GroupButtons";
import StandardOptions from "../Map/StandardOptions";
import {
    capitalizeString,
    changeToGroup,
    handleScrollToEnd,
    makeDeepCopy,
} from "../../utils/utils";
import { filterHeaders } from "../../utils/mapUtils";
import socket from "../../socket/socket";
import { CREATE_MAP_SOCKET } from "../../socket/socketsList";
import MarkerBoxPreview from "../../views/dashboard/createMap/MarkerBoxPreview";
import { errorMessage, successMessage } from "../../utils/messages";
import ReactPortal from "../Portal/ReactPortal";
import MapCropperModal from "../Modals/ImageCropper/MapCropperModal";
import ArrowRight from "../icons/ArrowRight";
import AlertIcon from "../icons/AlertIcon";

const MarkerOptionsEditMap = (props) => {
    const {
        refState,
        state,
        setState,
        create,
        handleShowTableData,
        setShowFailedRecords,
        showEditInputCodes,
    } = props;
    const firstTime = useRef(true);
    const [optionState, setOptionState] = useState({
        current: state.successRows?.length,
        failed: state.failedRows?.length,
        total: state.rawData.length,
    });

    const [imageCropperState, setImageCropperState] = useState({
        openMapCropperModal: false,
        clickedItemID: null,
        clickedItemAvatar: null,
    });

    const removeAttribute = (attr) => (e) => {
        e.target.removeAttribute(attr);
    };

    useEffect(() => {
        if (state?.rawData) {
            setOptionState({
                current: state.successRows?.length - state.failedRows?.length,
                failed: state.failedRows?.length,
                total: state.rawData.length,
            });
        }
    }, [state?.rawData?.length, state.successRows?.length, state.failedRows?.length]);

    const { mapType } = useSelector((state) => state.mapTypeState);

    useEffect(() => {
        socket.on(CREATE_MAP_SOCKET, (res) => {
            if (socket.child) {
                if (socket.id === res.socketID) {
                    if (state.id === res.mapID) {
                        setOptionState({
                            current: res.current,
                            total: res.total,
                        });
                    }
                }
            }
        });
    }, [state.id]);

    const [markerOptionsState, setMarkerOptionsState] = useState({
        options: "mapOptions",
        mapCenter: { lat: 40.756795, lng: -73.954298 },
        colorPicker: false,
        color: "",
        activeGroupID: "",
        passwordType: false,
    });

    const handleOptions = (val, e) => {
        e.stopPropagation();
        e.preventDefault();
        if (val === "mapOptions") {
            handleShowTableData(true);
        } else {
            handleShowTableData(false);
        }

        if (markerOptionsState?.options === val) {
            return;
        }
        setMarkerOptionsState((prevState) => {
            return {
                ...prevState,
                options: val,
            };
        });
    };

    // ----------------------------
    // STANDARD OPTIONS
    // ----------------------------
    const handleStandardOptions = (e) => {
        let { name, value } = e.target;

        if (name === "groupby_thematicValue") {
            let groupedData = changeToGroup(state.rawData, value);

            setState((prevState) => {
                let newState = {
                    ...prevState,
                    groupedData,
                    rawData: prevState.rawData.map((item) => {
                        item.color =
                            groupedData.find((group) => group.group === item[value])?.color ||
                            "rgb(255,0,0)";
                        return item;
                    }),
                    standardOptions: {
                        ...prevState.standardOptions,
                        [name]: value,
                    },
                };

                if (prevState.successRows) {
                    newState.successRows = prevState.successRows.map((item) => {
                        let foundedItem = groupedData.find((group) => group.group === item[value]);

                        item.color = foundedItem?.color || "rgb(255,0,0)";
                        item.group = foundedItem?.group || "rgb(255,0,0)";
                        return item;
                    });
                }

                return newState;
            });
        } else {
            setState((prevState) => {
                return {
                    ...prevState,
                    standardOptions: {
                        ...prevState.standardOptions,
                        [e.target.name]: e.target.value,
                    },
                };
            });
        }
    };

    // ----------------------------
    // ADVANCE OPTIONS
    // ----------------------------
    const handleAdvanceOptions = (e) => {
        setState((prevState) => {
            return {
                ...prevState,
                advanceOptions: {
                    ...prevState.advanceOptions,
                    [e.target.name]: e.target.value,
                },
            };
        });
    };

    const handleChangeColor = (color) => {
        // updatingRef.current = true;
        setState((prevState) => {
            let successRows = [...(prevState.successRows || [])];
            let updateState = {
                ...prevState,
                groupedData: prevState.groupedData.map((grp) => {
                    if (grp.group === markerOptionsState.activeGroupID) {
                        grp.color = `rgb(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`;
                        grp.hexcolor = color.hex;
                    }
                    return grp;
                }),
                rawData: prevState.rawData.map((loc, i) => {
                    if (loc.group === markerOptionsState.activeGroupID) {
                        loc.color = `rgb(${color.rgb.r},${color.rgb.g},${color.rgb.b})`;
                        loc.hexcolor = color.hex;

                        if (successRows.length) {
                            successRows[
                                i
                            ].color = `rgb(${color.rgb.r},${color.rgb.g},${color.rgb.b})`;
                            successRows[i].hexcolor = color.hex;
                        }
                    }
                    return loc;
                }),
                successRows,
            };

            return updateState;
        });
    };

    const handleActiveGroup = (id) => {
        setMarkerOptionsState((prevState) => {
            return {
                ...prevState,
                colorPicker: id === prevState.activeGroupID ? !prevState.colorPicker : true,
                activeGroupID: id,
            };
        });
    };

    const handleMarkerShape = (shape) => {
        setState((prevState) => {
            return {
                ...prevState,
                advanceOptions: {
                    ...prevState.advanceOptions,
                    markerStyle: shape,
                },
            };
        });
    };

    const handleMapStyle = (mapStyle) => {
        setState((prevState) => {
            return {
                ...prevState,
                advanceOptions: {
                    ...prevState.advanceOptions,
                    mapStyle,
                },
            };
        });
    };

    const copyPublicContent = (id) => {
        const element = document.getElementById(id).value;
        navigator.clipboard
            .writeText(element)
            .then(() => {
                successMessage("Successfully copied");
            })
            .catch((error) => {
                errorMessage(error);
            });
    };

    const options = state.rawData?.length ? filterHeaders(Object.keys(state.rawData?.[0])) : [];

    let info = {
        // ADVANCE OPTIONS
        name: state.rawData?.[0]?.[state.advanceOptions.name],
        email: state.rawData?.[0]?.[state.advanceOptions.email],
        url: state.rawData?.[0]?.[state.advanceOptions.url],
        // STANDARD OPTIONS
        address: state.rawData?.[0]?.[state.standardOptions.address_location],
        zip: state.rawData?.[0]?.[state.standardOptions.zip_postcode],
        phone: state.rawData?.[0]?.[state.standardOptions.phone],
        group: state.rawData?.[0]?.[state.standardOptions.groupby_thematicValue],
        city: state.rawData?.[0]?.[state.standardOptions.city_country],
        state: state.rawData?.[0]?.[state.standardOptions.state_province],
    };

    const handleMapDetail = (e) => {
        if (e.target.name === "title") {
            firstTime.current = false;
            refState.title = e.target.value;
            return;
        }
        if (e.target.name === "description") {
            refState.description = e.target.value;
            return;
        }

        setState((prevState) => {
            let val = "";
            if (e.target.value.trim()) {
                val = e.target.value;
                return {
                    ...prevState,
                    [e.target.name]: val,
                };
            }
            return {
                ...prevState,
                [e.target.name]: val,
            };
        });
    };

    const mapSuccessPinPercentage = (optionState.current / optionState.total) * 100;

    return (
        <>
            <section className="editMap-markerOption--container">
                {props.children}

                {!create && refState.title && (
                    <div className="editMap-title-bar">
                        <img
                            src={state?.mapData?.mapImage || noImage}
                            alt="Map Preview edit"
                            onClick={(e) => {
                                e.stopPropagation();
                                setImageCropperState((prevState) => ({
                                    ...prevState,
                                    openMapCropperModal: true,
                                    clickedItemID: state.id,
                                    clickedItemAvatar: state?.mapData?.mapImage,
                                }));
                            }}
                        />
                        <div>
                            <p>My Maps</p>
                            <h4>{refState?.title}</h4>
                        </div>
                    </div>
                )}

                {create && (
                    <div className="mapRecords-container">
                        {/* <div className="records records-success">
                            <div
                                className="result"
                                style={{ width: `${mapSuccessPinPercentage}%` }}
                            ></div>
                        </div> */}
                        <div className="map-record-counter--wrapper ">
                            <div className="map_record_files_text">
                                <p>
                                    {optionState.current} / {optionState.total} files
                                </p>
                                {(optionState.failed > 0 || state.failedRows?.length > 0) && (
                                    <div className="records-error">
                                        <button
                                            onClick={() => setShowFailedRecords((state) => !state)}
                                        >
                                            <AlertIcon />
                                            {optionState.failed > 1 || state.failedRows?.length > 1
                                                ? `${
                                                      optionState.failed || state.failedRows?.length
                                                  } locations were not found`
                                                : `${
                                                      optionState.failed || state.failedRows?.length
                                                  } location was not found`}{" "}
                                            {/* <ArrowRight /> */}
                                        </button>{" "}
                                    </div>
                                )}
                            </div>
                            <div className="records records-success">
                                <div
                                    className="result"
                                    style={{ width: `${mapSuccessPinPercentage}%` }}
                                ></div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="markerOption-collapse-wrapper">
                    <button
                        className={`markerOption-collapse 
                        ${markerOptionsState.options === "mapOptions" ? "active" : ""}
                    `}
                        onClick={(e) => handleOptions("mapOptions", e)}
                    >
                        Map details
                    </button>
                    <button
                        className={`markerOption-collapse 
                    ${markerOptionsState.options === "standard" ? "active" : ""}
                `}
                        onClick={(e) => handleOptions("standard", e)}
                    >
                        Standard options
                    </button>
                    <button
                        className={`markerOption-collapse 
                    ${markerOptionsState.options === "advance" ? "active" : ""}
                `}
                        onClick={(e) => handleOptions("advance", e)}
                    >
                        Advanced options
                    </button>
                    <button
                        className={`markerOption-collapse 
                            `}
                        // ${markerOptionsState.options === "advance" ? "active" : ""}
                        onClick={handleScrollToEnd}
                    >
                        Data
                    </button>
                </div>

                <div className="createMap-tabs-wrapper">
                    {markerOptionsState.options === "mapOptions" && (
                        <div id="map-options" className="vanishIn">
                            <div className="">
                                <div className="createMap-formgroup">
                                    <label>Map</label>
                                    <input
                                        className={`createMap-formcontrol ${
                                            !firstTime.current && !refState.title.trim()
                                                ? "error-border"
                                                : ""
                                        }`}
                                        name="title"
                                        type="text"
                                        onChange={handleMapDetail}
                                        defaultValue={refState.title || ""}
                                        aria-label="Map Title"
                                        required
                                        autoComplete="off"
                                        readOnly
                                        onClick={removeAttribute("readOnly")}
                                    />
                                    {!firstTime.current && !refState.title.trim() && (
                                        <span className="error-text">Error: Enter Map title</span>
                                    )}
                                </div>

                                <div className="createMap-formgroup">
                                    <label>Email</label>
                                    <input
                                        className="createMap-formcontrol"
                                        type="email"
                                        name="email"
                                        value={state.email}
                                        aria-label="User Email"
                                        disabled={true}
                                    />
                                    <span className="info">
                                        Your email address is used to access map editing and
                                        receiving map expiration notifications.
                                    </span>
                                </div>

                                <div className="createMap-formgroup">
                                    <label>Description (optional)</label>
                                    <textarea
                                        className="createMap-formcontrol"
                                        name="description"
                                        type="text"
                                        autoComplete="off"
                                        defaultValue={refState.description}
                                        onBlur={handleMapDetail}
                                        aria-label="Map Description"
                                        style={{ padding: 10 }}
                                    />
                                </div>

                                {showEditInputCodes && (
                                    <>
                                        <div className="createMap-formgroup editMap-fields-wrapper">
                                            <h4 className="createMap-box-title">Address</h4>
                                            <div className="link-wrapper">
                                                <input
                                                    type="text"
                                                    className="createMap-formcontrol"
                                                    id="editAddressCopy"
                                                    value={`https://v3.xoommaps.com/map-view/${
                                                        mapType === 1
                                                            ? "map"
                                                            : mapType === 3
                                                            ? "route"
                                                            : "group"
                                                    }/${state.id}`}
                                                    disabled
                                                />
                                                <button
                                                    onClick={() =>
                                                        copyPublicContent("editAddressCopy")
                                                    }
                                                >
                                                    <img src={copyIcon} alt="copy-icon" />
                                                </button>
                                            </div>
                                            <div className="info-wrapper">
                                                <img
                                                    src={infoTransparent}
                                                    alt="info-icon"
                                                    width={15}
                                                />
                                                <span>
                                                    Your email address is used to access map editing
                                                    and receiving map expiration notifications.
                                                </span>
                                            </div>
                                        </div>
                                        <div className="createMap-formgroup editMap-fields-wrapper">
                                            <h4 className="createMap-box-title">Locator code</h4>
                                            <div className="link-wrapper">
                                                <input
                                                    type="text"
                                                    className="createMap-formcontrol"
                                                    id="locatorCodeCopy"
                                                    value={`<form action="https://v3.xoommaps.com/map-view/${
                                                        mapType === 1
                                                            ? "map"
                                                            : mapType === 3
                                                            ? "route"
                                                            : "group"
                                                    }"><input type="hidden" name="i" value="${
                                                        state.id
                                                    }"><label>Search for location on map <input type="text" name="q" value=""></label><input type="submit" value="Search"></form>`}
                                                    disabled
                                                />

                                                <button
                                                    onClick={() =>
                                                        copyPublicContent("locatorCodeCopy")
                                                    }
                                                >
                                                    <img src={copyIcon} alt="copy-icon" />
                                                </button>
                                            </div>
                                            <div className="info-wrapper">
                                                <img
                                                    src={infoTransparent}
                                                    alt="info-icon"
                                                    width={15}
                                                />
                                                <span>
                                                    The locator code is specific. Missing a single
                                                    character will prevent the address from working.
                                                </span>
                                            </div>
                                        </div>
                                        <div className="createMap-formgroup editMap-fields-wrapper">
                                            <h4 className="createMap-box-title">Embed code</h4>
                                            <div className="link-wrapper">
                                                <input
                                                    type="text"
                                                    id="embedCodeCopy"
                                                    className="createMap-formcontrol"
                                                    value={`<p><iframe src="https://v3.xoommaps.com/map-view/${
                                                        mapType === 1
                                                            ? "map"
                                                            : mapType === 3
                                                            ? "route"
                                                            : "group"
                                                    }/${
                                                        state.id
                                                    }" frameborder="0" width="100%" height="550" sandbox="allow-top-navigation allow-scripts allow-popups allow-same-origin allow-modals allow-forms" style="border:1px solid #aaa;"></iframe></p><p><small>View <a href="https://geo.myimmix.com/map/${
                                                        state.id
                                                    }">${
                                                        state.id
                                                    }</a> in a full screen map</small></p>`}
                                                    disabled
                                                />

                                                <button
                                                    onClick={() =>
                                                        copyPublicContent("embedCodeCopy")
                                                    }
                                                >
                                                    <img src={copyIcon} alt="copy-icon" />
                                                </button>
                                            </div>
                                            <div className="info-wrapper">
                                                <img
                                                    src={infoTransparent}
                                                    alt="info-icon"
                                                    width={15}
                                                />
                                                <span>
                                                    The embeded code is specific. Missing a single
                                                    character will prevent the address from working.
                                                </span>
                                            </div>
                                        </div>
                                        <div className="createMap-formgroup editMap-fields-wrapper">
                                            <h4 className="createMap-box-title">Badge code</h4>
                                            <div className="link-wrapper">
                                                <input
                                                    type="text"
                                                    className="createMap-formcontrol"
                                                    id="badgeCodeCopy"
                                                    value={`<form action="https://v3.xoommaps.com/map-view/map/"><input type="hidden" name="i" value="${state.id}"><label>Search for nearest location <input type="text" name="q" value=""></label><input type="submit" value="Search"></form>`}
                                                    disabled
                                                />

                                                <button
                                                    onClick={() =>
                                                        copyPublicContent("badgeCodeCopy")
                                                    }
                                                >
                                                    <img src={copyIcon} alt="copy-icon" />
                                                </button>
                                            </div>
                                            <div className="info-wrapper">
                                                <img
                                                    src={infoTransparent}
                                                    alt="info-icon"
                                                    width={15}
                                                />
                                                <span>
                                                    The badge code is specific. Missing a single
                                                    character will prevent the address from working.
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>

                            <div className="create-map-radiocheck ">
                                <h4 className="createMap-box-title">Project Implementation (PI)</h4>
                                <div className="create-map-radiocheck--inner">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="projectImplementationDisabled"
                                            name="projectImplementation"
                                            value="disabled"
                                            checked={state.projectImplementation === "disabled"}
                                            onChange={handleMapDetail}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="projectImplementationDisabled"
                                        >
                                            Disabled
                                            {/* <br />
                                            <span>
                                                Anyone with the map link can not see your map.
                                            </span> */}
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="projectImplementationEnabled"
                                            name="projectImplementation"
                                            value="enabled"
                                            checked={state.projectImplementation === "enabled"}
                                            onChange={handleMapDetail}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="projectImplementationEnabled"
                                        >
                                            Enabled
                                            {/* <br />
                                            <span>Anyone can see map.</span> */}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="create-map-radiocheck ">
                                <h4 className="createMap-box-title">Privacy</h4>
                                <div className="create-map-radiocheck--inner">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="unlisted"
                                            name="access"
                                            value="unlisted"
                                            checked={state.access === "unlisted"}
                                            onChange={handleMapDetail}
                                        />
                                        <label className="form-check-label" htmlFor="unlisted">
                                            Unlisted
                                            <br />
                                            <span>Anyone with a map link cannot see map.</span>
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="public"
                                            name="access"
                                            value="public"
                                            checked={state.access === "public"}
                                            onChange={handleMapDetail}
                                        />
                                        <label className="form-check-label" htmlFor="public">
                                            Public
                                            <br />
                                            <span>Anyone can see your map.</span>
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="protected"
                                            name="access"
                                            value="password"
                                            onChange={handleMapDetail}
                                            checked={state.access === "password"}
                                            autoComplete="off"
                                        />
                                        <label className="form-check-label" htmlFor="protected">
                                            Password protected (Paid only)
                                            <br />
                                            <span>
                                                Only you and people you choose can see your <br />{" "}
                                                map.
                                            </span>
                                            <div className="pos-rel">
                                                <input
                                                    style={{ marginTop: "10px" }}
                                                    autoComplete="off"
                                                    className="createMap-formcontrol"
                                                    type={
                                                        markerOptionsState.passwordType
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    id="password"
                                                    name="password"
                                                    placeholder="Password"
                                                    onChange={handleMapDetail}
                                                    required
                                                    readOnly
                                                    onClick={removeAttribute("readOnly")}
                                                />
                                                <img
                                                    src={
                                                        markerOptionsState.passwordType
                                                            ? eyeIconOff
                                                            : eyeIcon
                                                    }
                                                    className="eye-password-toggle"
                                                    alt="view password"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setMarkerOptionsState((prevState) => {
                                                            return {
                                                                ...prevState,
                                                                passwordType:
                                                                    !prevState.passwordType,
                                                            };
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="create-map-radiocheck">
                                <h4 className="createMap-box-title">Public view search</h4>
                                <div
                                    className="create-map-radiocheck--inner"
                                    style={{ marginBottom: 5 }}
                                >
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="disabled"
                                            name="viewSearch"
                                            value="disabled"
                                            checked={state.viewSearch === "disabled"}
                                            onChange={handleMapDetail}
                                        />
                                        <label className="form-check-label" htmlFor="disabled">
                                            Disabled
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="enabled"
                                            name="viewSearch"
                                            value="enabled"
                                            checked={state.viewSearch === "enabled"}
                                            onChange={handleMapDetail}
                                        />
                                        <label className="form-check-label" htmlFor="enabled">
                                            Enabled
                                        </label>
                                    </div>
                                </div>
                                <span className="ml-20">
                                    Enabling provides a window on your map to search for specific
                                    map content.
                                </span>
                            </div>
                            <div className="create-map-radiocheck">
                                <h4 className="createMap-box-title">Data display</h4>
                                <div
                                    className="create-map-radiocheck--inner"
                                    style={{ marginBottom: 5 }}
                                >
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="mapdata"
                                            name="dataDisplay"
                                            value="mapdata"
                                            checked={state.dataDisplay === "mapdata"}
                                            onChange={handleMapDetail}
                                        />
                                        <label className="form-check-label" htmlFor="mapdata">
                                            Map & Data
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="onlydata"
                                            name="dataDisplay"
                                            value="onlydata"
                                            checked={state.dataDisplay === "onlydata"}
                                            onChange={handleMapDetail}
                                        />
                                        <label className="form-check-label" htmlFor="onlydata">
                                            Map only (Data will be hidden)
                                        </label>
                                    </div>
                                </div>
                                <span className="ml-20">
                                    Map & Data will display your map and all data below map.
                                </span>
                            </div>

                            <div className="create-map-radiocheck ">
                                <h4 className="createMap-box-title">Map mode</h4>
                                <div className="create-map-radiocheck--inner">
                                    <div className="form-check map-mode--wrapper">
                                        <div>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                id="table"
                                                name="view"
                                                value="table"
                                                checked={state.view === "table"}
                                                onChange={handleMapDetail}
                                            />
                                            <label className="form-check-label" htmlFor="table">
                                                List view
                                                <br />
                                                <span>
                                                    Display your data below your map <br /> with
                                                    filtering tools.
                                                </span>
                                            </label>
                                        </div>
                                        <img
                                            src={TableView}
                                            className="map-mode-img"
                                            alt="table-view of data"
                                            style={{
                                                border: `${
                                                    state.view === "table"
                                                        ? "3px solid #981029"
                                                        : ""
                                                }`,
                                            }}
                                        />
                                    </div>

                                    <div className="form-check map-mode--wrapper">
                                        <div>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                id="list"
                                                name="view"
                                                value="list"
                                                checked={state.view === "list"}
                                                onChange={handleMapDetail}
                                            />
                                            <label className="form-check-label" htmlFor="list">
                                                Card view
                                                <br />
                                                <span>
                                                    Display your locations in cards below your
                                                    <br />
                                                    map with easy swiping.
                                                </span>
                                            </label>
                                        </div>
                                        <img
                                            src={CardView}
                                            className="map-mode-img"
                                            alt="table-view of data"
                                            style={{
                                                border: `${
                                                    state.view === "list" ? "3px solid #981029" : ""
                                                }`,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {markerOptionsState.options === "standard" && (
                        <StandardOptions
                            options={options}
                            data={state.standardOptions}
                            handleStandardOptions={handleStandardOptions}
                        />
                    )}

                    {markerOptionsState.options === "advance" && (
                        <div
                            style={{
                                display: "grid",
                                gridAutoFlow: "column",
                                gridTemplateColumns: "repeat(auto-fit,minmax(350px,1fr))",
                                gap: "30px",
                            }}
                            id="advance-options"
                            className="vanishIn"
                        >
                            {/* 1ST COLUMN */}
                            <div>
                                <div className="createMap-formgroup">
                                    <label htmlFor="disabledSelect">Title</label>
                                    <select
                                        id="disabledSelectss"
                                        className="form-control"
                                        value={state.advanceOptions.name}
                                        name="name"
                                        onChange={handleAdvanceOptions}
                                        required
                                    >
                                        <option value="">None</option>
                                        {options?.map((option) => (
                                            <option key={option} value={option}>
                                                {capitalizeString(option)}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="createMap-formgroup">
                                    <label htmlFor="disabledSelect">E-mail</label>
                                    <select
                                        id="disabledSelectss"
                                        className="form-control"
                                        value={state.advanceOptions.email}
                                        name="email"
                                        onChange={handleAdvanceOptions}
                                    >
                                        <option value="">None</option>
                                        {options.map((option) => (
                                            <option key={option} value={option}>
                                                {capitalizeString(option)}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="createMap-formgroup">
                                    <label htmlFor="disabledSelect">Image URL</label>
                                    <select
                                        id="disabledSelectss"
                                        className="form-control"
                                        value={state.advanceOptions.image_url}
                                        name="url"
                                        onChange={handleAdvanceOptions}
                                    >
                                        <option value="">None</option>
                                        {options.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="createMap-formgroup">
                                    <label htmlFor="disabledSelect">URL</label>
                                    <select
                                        id="disabledSelectss"
                                        className="form-control"
                                        value={state.advanceOptions.url}
                                        name="url"
                                        onChange={handleAdvanceOptions}
                                    >
                                        <option value="">None</option>
                                        {options.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="createMap-formgroup">
                                    <label htmlFor="disabledSelect">Marker Description</label>
                                    <select
                                        id="disabledSelectss"
                                        className="form-control"
                                        value={state.advanceOptions.markerDescription}
                                        name="markerDescription"
                                        onChange={handleAdvanceOptions}
                                    >
                                        <option value="">None</option>
                                        {options.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="createMap-formgroup">
                                    <label htmlFor="disabledSelect">Latitude</label>
                                    <select
                                        id="disabledSelectss"
                                        className="form-control"
                                        value={state.advanceOptions.lat}
                                        name="lat"
                                        onChange={handleAdvanceOptions}
                                    >
                                        <option value="">None</option>
                                        {options.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="createMap-formgroup">
                                    <label htmlFor="disabledSelect">Longitude</label>
                                    <select
                                        id="disabledSelectss"
                                        className="form-control"
                                        defaultValue={state.advanceOptions.lng}
                                        name="lng"
                                        onChange={handleAdvanceOptions}
                                    >
                                        <option value="">None</option>
                                        {options.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="createMap-formgroup">
                                    <label htmlFor="disabledSelect">Marker label</label>
                                    <select
                                        id="disabledSelectss"
                                        className="form-control"
                                        defaultValue={state.advanceOptions.markerLabel}
                                        name="markerLabel"
                                        onChange={handleAdvanceOptions}
                                    >
                                        <option value="">Select</option>
                                        <option value="letters">Letters</option>
                                    </select>
                                </div>

                                <div className="createMap-formgroup">
                                    <label htmlFor="disabledSelect">Map view</label>
                                    <select
                                        id="disabledSelectss"
                                        className="form-control"
                                        defaultValue={state.advanceOptions.mapView}
                                        name="mapView"
                                        onChange={handleAdvanceOptions}
                                    >
                                        <option value="">Satellite</option>
                                        <option value="">Select</option>
                                        <option value="">Street</option>
                                    </select>
                                </div>

                                <div className="createMap-formgroup">
                                    <label htmlFor="disabledSelect">Distance measurement</label>

                                    <select
                                        id="disabledSelectss"
                                        className="form-control"
                                        name="distance"
                                        onChange={handleAdvanceOptions}
                                        defaultValue={state.advanceOptions.distance}
                                    >
                                        <option value="">Select</option>
                                        <option value="mile">Mile (straight line)</option>
                                    </select>
                                </div>
                            </div>

                            {/* 2nd COLUMN */}
                            <div className="editMap-markerOptions">
                                <MarkerBoxPreview
                                    info={info}
                                    markerOptionsState={markerOptionsState}
                                />

                                <div className="createMap-marker-box">
                                    <h6>Others</h6>
                                    <div className="createMap-formgroup">
                                        <div className="formgroup-flex">
                                            <label
                                                className="custom-label clr-light"
                                                htmlFor="openLinkInNewWindow"
                                            >
                                                <input
                                                    type="checkbox"
                                                    name="openLinkInNewWindow"
                                                    id="openLinkInNewWindow"
                                                />
                                                <span>Link open a new window</span>
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <section className="createMap-marker-box">
                                    <h6>Map style</h6>
                                    <MapStyles
                                        currentMapStyle={state.advanceOptions.mapStyle}
                                        handleMapStyle={handleMapStyle}
                                    />
                                </section>

                                {/* For create map screen Marker Styles */}
                                <section className="createMap-marker-box">
                                    <h6>Marker shape</h6>
                                    <div className="editMap-shapes">
                                        <button
                                            type="button"
                                            className={`mapMarker-Btn ${
                                                state.advanceOptions.markerStyle === "default"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={(e) => {
                                                handleMarkerShape("default");
                                            }}
                                        >
                                            <img src={PinMarker} alt="Default Pin Marker" />
                                        </button>

                                        <button
                                            type="button"
                                            className={`mapMarker-Btn ${
                                                state.advanceOptions.markerStyle === "circle"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={(e) => handleMarkerShape("circle")}
                                        >
                                            <img src={CircleMarker} alt="Marker Circle" />
                                        </button>

                                        <button
                                            type="button"
                                            className={`mapMarker-Btn ${
                                                state.advanceOptions.markerStyle === "square"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={(e) => handleMarkerShape("square")}
                                        >
                                            <img src={SquareMarker} alt="Marker Square" />
                                        </button>
                                    </div>
                                </section>
                                <section className="createMap-marker-box">
                                    <h6>Marker color</h6>
                                    {markerOptionsState.colorPicker && (
                                        <ColorPicker handleChangeColor={handleChangeColor} />
                                    )}

                                    <GroupButtons
                                        data={state.groupedData}
                                        activeGroup={markerOptionsState.activeGroupID}
                                        handleActiveGroup={handleActiveGroup}
                                    />
                                </section>
                            </div>
                        </div>
                    )}

                    {markerOptionsState.options === "standard" && (
                        <MarkerBoxPreview info={info} markerOptionsState={markerOptionsState} />
                    )}
                </div>

                {markerOptionsState.options === "standard" && (
                    <p className="markerPlace-note">
                        NOTE: Out of place markers can be corrected by dragging and dropping.
                    </p>
                )}
            </section>
            {imageCropperState.openMapCropperModal && (
                <ReactPortal wrapperId="external_modal_container">
                    <MapCropperModal
                        setParentState={setImageCropperState}
                        parentState={imageCropperState}
                    />
                </ReactPortal>
            )}
        </>
    );
};

// export default MarkerOptionsEditMap;
export default MarkerOptionsEditMap;
