import React, { useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
import { csvToJSON, extractExtention } from "../../../utils/utils";
import GeoMappingSample from "../../../assets/Templates/GeoMappingSample.xlsx";
import * as XLSX from "xlsx";
import { filterHeaders, resetMapOptions } from "../../../utils/mapUtils";
import EditMapData from "../../../components/EditMapData/EditMapData";
import copyIcon from "../../../assets/images/svg/copyIcon.svg";
import CreateMapNav from "./CreateMapNav";
import { errorMessage } from "../../../utils/messages";
import ChevronDown from "../../../components/icons/ChevronDown";
import ReactPortal from "../../../components/Portal/ReactPortal";
import CreateMapMaxMarkersLimitModal from "../../../components/Modals/CreateMapMaxMarkersLimitModal";
import { rawData } from "../../../utils/constants";

let acceptedExtentions = ["xls", "xlsx", "csv"];

const Drop = (props) => {
    const { createMapState, setCreateMapState, getUpdatedData } = props;
    const { planData } = useSelector((state) => state.auth);
    const fileInputRef = useRef(null);

    const [dropState, setDropState] = useState({
        showTable: false,
        showFilesList: false,
        showMaxMarkersModal: false,
        pinsCount: planData?.markersPerMap,
        hideHover: true,
    });

    const handleUploadClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        fileInputRef.current.click(); // Manually trigger the file input click
    };

    const { headers, setHeaders } = props;

    function wrongFile(msg) {
        resetMapOptions(setCreateMapState);

        setDropState((prevState) => {
            return {
                ...prevState,
                showTable: false,
            };
        });

        setHeaders({
            orgHeaders: [],
            newHeaders: [],
        });
    }

    const handleDrop = (acceptedFiles) => {
        let file = acceptedFiles[0];

        let ext = extractExtention(file?.path);

        if (acceptedExtentions.includes(ext)) {
            const fileReader = new FileReader();

            fileReader.onload = function (event) {
                const data = event.target.result;

                const workbook = XLSX.read(data, {
                    type: "binary",
                });
                const sheet = workbook.SheetNames[0];

                let rawData = [];
                let headers = [];

                if (ext === "csv") {
                    let csvData = XLSX.utils.sheet_to_csv(workbook.Sheets[sheet], { header: 1 });

                    const lines = csvData.split("\n");
                    headers = lines[0]
                        .split(",")
                        .map((header) => header.trim().toUpperCase())
                        .filter((item) => !!item); // Assuming the first row contains headers
                    rawData = csvToJSON(csvData);
                } else {
                    let arrayData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {
                        header: 1,
                        raw: false,
                        rawNumbers: false,
                    });

                    headers = arrayData
                        ?.shift()
                        ?.map((header) => header.trim?.().toUpperCase())
                        ?.filter((item) => !!item); // Remove the first row (headers) from the data

                    rawData = arrayData.reduce((arr, row) => {
                        let updatedRow = row.filter((entry) => entry?.trim?.());
                        if (updatedRow.length) {
                            const obj = {};
                            headers?.forEach((header, index) => {
                                obj[header] = row[index]?.toString() || "";
                            });

                            arr.push(obj);
                        }
                        return arr;
                    }, []);
                }

                if (!rawData.length) {
                    wrongFile("No data found in the file!");
                    return;
                }

                setHeaders({
                    orgHeaders: headers,
                    newHeaders: headers,
                });

                if (rawData.length > planData.markersPerMap) {
                    setDropState((prevState) => {
                        return {
                            ...prevState,
                            showMaxMarkersModal: true,
                        };
                    });

                    setCreateMapState((prevData) => {
                        return {
                            ...prevData,
                            rawData: rawData.slice(0, planData.markersPerMap),
                            dropFileName: file?.name,
                        };
                    });
                } else {
                    setCreateMapState((prevData) => {
                        return {
                            ...prevData,
                            rawData,
                            dropFileName: file?.name,
                        };
                    });
                }
                setDropState((prevState) => {
                    return {
                        ...prevState,
                        hideHover: false,
                    };
                });
            };

            fileReader.readAsBinaryString(file);
        } else {
            wrongFile("Only excel & csv files are acceptable.");
            return;
        }
    };

    function handleDrag() {
        const div = document.getElementById("hoverId");
        if (div) {
            div.style.opacity = "0";
        }
    }

    function handleBlur(e) {
        e.stopPropagation();

        if (e.relatedTarget == null) {
            setDropState((prevState) => {
                return {
                    ...prevState,
                    showTable: false,
                };
            });
        }
    }

    function handleShowTable() {
        setDropState((prevState) => {
            return {
                ...prevState,
                showTable: true,
            };
        });
    }

    useEffect(() => {
        const box = document.getElementById("csvBox");
        if (box) {
            box.focus();
            box.select();
        }
    }, [dropState.showTable]);

    useEffect(() => {
        setHeaders((prev) => {
            return {
                ...prev,
                newHeaders: filterHeaders(Object.keys(rawData?.[0]) || []),
                orgHeaders: filterHeaders(Object.keys(rawData?.[0]) || []),
            };
        });
    }, []);

    const handleToggleFilesList = () => {
        setDropState((prevState) => {
            return {
                ...prevState,
                showFilesList: !prevState.showFilesList,
            };
        });
    };

    return (
        <>
            <section>
                {/* -------------------------------------------- */}
                <CreateMapNav
                    state={props.createMapState}
                    handleCancel={props.handleCancel}
                    handleMapNow={props.handleMapNow}
                    handleSaveMapSubmit={props.handleSaveMapSubmit}
                    updatingRef={props.updatingRef}
                />
                {/* -------------------------------------------- */}

                <div className="createMap-csvBox">
                    {createMapState.rawData?.length && dropState.showTable ? (
                        <EditMapData
                            changedHeaders={headers}
                            changeableHeaders={true}
                            data={createMapState.rawData}
                            focusFirst={true}
                            getUpdatedData={getUpdatedData}
                            handleBlur={handleBlur}
                            headers={headers.orgHeaders}
                            state={createMapState}
                            setHeaders={setHeaders}
                        />
                    ) : (
                        <>
                            <Dropzone
                                onDrop={handleDrop}
                                onDragEnter={handleDrag}
                                noClick={true}
                                multiple={false}
                                // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, application/vnd.ms-excel"
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <section
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (
                                                e.target.tagName !== "INPUT" &&
                                                e.target.tagName !== "LABEL"
                                            ) {
                                                handleShowTable();
                                            }
                                        }}
                                    >
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} ref={fileInputRef} />

                                            <div className="dropClass55">
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        overflow: "hidden",
                                                        overflowX: "auto",
                                                        background: "#fff",
                                                        // border: "2px solid #981029",
                                                        borderRadius: 10,
                                                        height: 600,
                                                    }}
                                                >
                                                    <table className="createMap-table">
                                                        <thead>
                                                            <tr>
                                                                {filterHeaders(
                                                                    headers?.newHeaders
                                                                ).map((th, key) => (
                                                                    <th key={key}>{th}</th>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {createMapState.rawData.map(
                                                                (row, i) => (
                                                                    <tr key={i}>
                                                                        {filterHeaders(
                                                                            headers?.orgHeaders
                                                                        ).map((header, index) => {
                                                                            if (
                                                                                typeof row[
                                                                                    header
                                                                                ] === "object"
                                                                            ) {
                                                                                return (
                                                                                    <td key={index}>
                                                                                        {
                                                                                            row[
                                                                                                header
                                                                                            ]?.lat
                                                                                        }
                                                                                        ,{" "}
                                                                                        {
                                                                                            row[
                                                                                                header
                                                                                            ]?.lng
                                                                                        }
                                                                                    </td>
                                                                                );
                                                                            }

                                                                            return (
                                                                                <td key={index}>
                                                                                    {row[header]}
                                                                                </td>
                                                                            );
                                                                        })}
                                                                    </tr>
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                {dropState.hideHover && (
                                                    <div
                                                        id="hoverId"
                                                        className="hoverClass"
                                                        style={{ opacity: 0 }}
                                                    >
                                                        <p>
                                                            <img
                                                                src={copyIcon}
                                                                style={{
                                                                    width: "26px",
                                                                    marginRight: "10px",
                                                                }}
                                                                alt="copy"
                                                            />{" "}
                                                            Copy & paste{" "}
                                                            <span style={{ color: "#ffffff" }}>
                                                                or
                                                            </span>{" "}
                                                            Drag files here{" "}
                                                            <span style={{ color: "#ffffff" }}>
                                                                or
                                                            </span>
                                                            <span className="uploadMapFiles">
                                                                <label
                                                                    htmlFor="uploadMapFiles"
                                                                    onClick={handleUploadClick}
                                                                >
                                                                    Upload
                                                                </label>
                                                            </span>
                                                        </p>
                                                        <span>
                                                            (Google sheet, Excel file and Numbers)
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>

                            <div className="createMap-Instructions">
                                Your data must include column headers or use one of our
                                <div className="pos-rel">
                                    <button
                                        onMouseEnter={handleToggleFilesList}
                                        onMouseLeave={handleToggleFilesList}
                                        // onClick={handleToggleFilesList}
                                    >
                                        templates
                                        <ChevronDown />
                                    </button>
                                    <ul
                                        onMouseEnter={handleToggleFilesList}
                                        onMouseLeave={handleToggleFilesList}
                                        className={`mapPinBar-group-list-content ${
                                            dropState.showFilesList ? "active" : ""
                                        }`}
                                    >
                                        <li>
                                            <a download href={GeoMappingSample}>
                                                Sample data
                                            </a>
                                        </li>
                                        <li>
                                            <a download href={GeoMappingSample}>
                                                Real estate
                                            </a>
                                        </li>
                                        <li>
                                            <a download href={GeoMappingSample}>
                                                Help desk
                                            </a>
                                        </li>
                                        <li>
                                            <a download href={GeoMappingSample}>
                                                Field service
                                            </a>
                                        </li>
                                        <li>
                                            <a download href={GeoMappingSample}>
                                                Retailer
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {dropState.showMaxMarkersModal && (
                    <ReactPortal wrapperId="external_modal_container">
                        <CreateMapMaxMarkersLimitModal
                            dropState={dropState}
                            setDropState={setDropState}
                            handleMapNow={props.handleMapNow}
                            updatingRef={props.updatingRef}
                        />
                    </ReactPortal>
                )}
            </section>
        </>
    );
};

export default Drop;
