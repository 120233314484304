import React, { useMemo, useState } from "react";
import { GEO_MAPPING_BASE_URL } from "../../config";
import { capitalizeFirstCharatcter } from "../../utils/utils";
import TrashIcon from "../icons/TrashIcon";
import PlusIcon from "../icons/PlusIcon";

import SpinLoader from "../SpinLoader/SpinLoader";
import DotLoader from "../DotLoaderBtn/DotLoader";

const pinsFilterList = [
    "name",
    "email",
    "url",
    "phone",
    "group",
    "address",
    "city",
    "state",
    "zip",
    "opportunities",
    "leadbysource",
];

function MapGroups({
    markerStyle,
    data,
    mapData,
    isNocUrl,
    hiddenMarkers,
    setHiddenMarkers,
    pinsFilterState,
    setPinsFilterState,
    mapDataheaders,
    editMode = false,
    handleRowInsert,
    rawDataIds,
    handleDeleteSelections,
    changedRow,
    state,
    handleUpdateLoc,
    locUpdatingRef,
}) {
    const [pinsFilterValues] = useState(() => {
        if (editMode) return [];
        return mapDataheaders?.newHeaders;
    });
    const [rotateGroupIcon, setRotateGroupIcon] = useState(false);

    const isCreateMode = window.location.href.includes("create-map");

    const handleToggleMarker = (marker) => {
        if (!isNocUrl) {
            if (!editMode) {
                // TOGGLE MARKER (ADD IF NOT PRESENT, REMOVE IF PRESENT)
                setHiddenMarkers((prevMarkers) => {
                    if (
                        prevMarkers.find(
                            (prevMarker) =>
                                prevMarker[pinsFilterState.activePinFilter] ===
                                marker[pinsFilterState.activePinFilter]
                        )
                    ) {
                        return prevMarkers.filter(
                            (prevMarker) =>
                                prevMarker[pinsFilterState.activePinFilter] !==
                                marker[pinsFilterState.activePinFilter]
                        );
                    } else {
                        return [...prevMarkers, marker];
                    }
                });
            }
        } else {
            setHiddenMarkers((prevMarkers) => {
                if (
                    prevMarkers.find(
                        (prevMarker) =>
                            prevMarker?.pinColor === marker?.pinColor &&
                            prevMarker?.userEmail === marker.userEmail
                    )
                ) {
                    return prevMarkers.filter(
                        (prevMarker) => prevMarker.pinColor !== marker?.pinColor
                    );
                } else {
                    return [...prevMarkers, marker];
                }
            });
        }
    };

    const filteredGroups = useMemo(() => {
        let arr = [];

        data?.forEach((item) => {
            if (
                !arr.find(
                    (obj) =>
                        obj?.[pinsFilterState?.activePinFilter || "group"] ===
                        item?.[pinsFilterState?.activePinFilter || "group"]
                )
            )
                arr.push(item);
        });
        return arr;
    }, [data, pinsFilterState?.activePinFilter]);

    const filteredNocGroups = useMemo(() => {
        let arr = [];
        data?.forEach((item) => {
            const isDuplicate = arr.find(
                (obj) => obj?.pinColor === item?.color && obj?.userEmail === item?.user?.email
            );

            if (!isDuplicate) {
                if (item?.user) {
                    arr.push({
                        pinColor: item?.color,
                        userName: item?.user?.first_name,
                        userEmail: item?.user?.email,
                    });
                }
            }
        });

        return arr;
    }, [data]);

    const [currentPage, setCurrentPage] = useState(0);
    const handleScrollLeft = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleScrollRight = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleTogglePinsFilterList = () => {
        setRotateGroupIcon((prev) => !prev);
        setPinsFilterState((prevState) => {
            return {
                ...prevState,
                showList: !prevState.showList,
            };
        });
    };

    const handleTogglePinsFilterValue = (value) => (e) => {
        e.stopPropagation();
        setPinsFilterState((prevState) => {
            return {
                ...prevState,
                activePinFilter: value,
                showList: !prevState.showList,
            };
        });
    };

    const itemsPerPage = 7;

    const getItems = () => {
        if (!isNocUrl) {
            return filteredGroups.length;
        } else {
            return filteredNocGroups.length;
        }
    };
    let totalItems = getItems();

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const groupsToShow = filteredGroups.slice(startIndex, endIndex);
    const sortedFilteredNocGroups = filteredNocGroups.sort((a, b) =>
        a?.userName.localeCompare(b?.userName, "en", { sensitivity: "base" })
    );
    const nocGroupsToshow = sortedFilteredNocGroups.slice(startIndex, endIndex);
    const itemsOnLeft = startIndex;
    const itemsOnRight = totalItems - endIndex < 0 ? 0 : totalItems - endIndex;

    let content;

    if (!isNocUrl) {
        content = (
            <>
                <div className="mapPinBar-group" id="mapPinBar_group">
                    {groupsToShow?.map((pin, index) => {
                        return (
                            <div
                                key={index}
                                onClick={() => handleToggleMarker(pin)}
                                className={`${
                                    hiddenMarkers?.some(
                                        (marker) =>
                                            marker[pinsFilterState?.activePinFilter || "group"] ===
                                            pin[pinsFilterState?.activePinFilter || "group"]
                                    )
                                        ? "group-bar-items pin-hide-effect"
                                        : "group-bar-items"
                                }`}
                            >
                                <img
                                    alt="map pin"
                                    src={`${GEO_MAPPING_BASE_URL}marker?type=${
                                        markerStyle || "default"
                                    }&size=13&fill=${
                                        editMode
                                            ? pin?.color || "red"
                                            : pin?.dummyColor || pin?.color || "red"
                                    }`}
                                />
                                <span className="overflow-ellipsis">
                                    {!pin?.[pinsFilterState?.activePinFilter || "group"]
                                        ? `Group ${index + 1}`
                                        : `${
                                              pin?.[pinsFilterState?.activePinFilter || "group"] ===
                                              "NO_GROUP"
                                                  ? "No Group"
                                                  : pin?.[
                                                        pinsFilterState?.activePinFilter || "group"
                                                    ]
                                          }`}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </>
        );
    } else {
        content = (
            <div className="mapPinBar-group" id="mapPinBar_group">
                {nocGroupsToshow?.map((pin, index) => {
                    return (
                        <div
                            key={index}
                            onClick={() => handleToggleMarker(pin)}
                            className={`${
                                hiddenMarkers.some(
                                    (item) =>
                                        item?.pinColor === pin?.pinColor &&
                                        item?.userEmail === pin?.userEmail
                                )
                                    ? "group-bar-items pin-hide-effect"
                                    : "group-bar-items"
                            }`}
                        >
                            <img
                                alt="map pin"
                                src={`${GEO_MAPPING_BASE_URL}marker?type=${
                                    markerStyle || "default"
                                }&size=13&fill=${
                                    editMode
                                        ? pin?.pinColor || "red"
                                        : pin?.dummyColor || pin?.pinColor || "red"
                                }`}
                            />
                            <span className="overflow-ellipsis">{pin?.userName || "No Group"}</span>
                        </div>
                    );
                })}
            </div>
        );
    }
    let saveContentButton;
    if (!state?.updateLocations && changedRow?.length > 0) {
        if (!locUpdatingRef) {
            saveContentButton = (
                <button onClick={handleUpdateLoc} className="mapPinbar_btn save flex-center">
                    Save
                </button>
            );
        } else {
            saveContentButton = (
                <button disabled={locUpdatingRef} className="mapPinbar_btn save flex-center">
                    <SpinLoader />
                </button>
            );
        }
    }

    return (
        <section className="mapPinBar-group-container">
            <div className="mapPinBar-group-content">
                {!!itemsOnLeft && (
                    <button className="btn" onClick={handleScrollLeft} disabled={currentPage === 0}>
                        <i className="fa fa-chevron-left"></i>
                        <span>
                            <img
                                alt="map pin"
                                src={`${GEO_MAPPING_BASE_URL}marker?type=default&size=13&fill=rgb(245, 245, 245)`}
                            />{" "}
                            ({itemsOnLeft})
                        </span>
                    </button>
                )}
                {content}

                {!!itemsOnRight && (
                    <button
                        className="btn"
                        onClick={handleScrollRight}
                        disabled={currentPage >= Math.ceil(totalItems / itemsPerPage) - 1}
                    >
                        <span>
                            <img
                                alt="map pin"
                                src={`${GEO_MAPPING_BASE_URL}marker?type=default&size=13&fill=rgb(245, 245, 245)`}
                            />{" "}
                            ({itemsOnRight})
                        </span>
                        <i className="fa fa-chevron-right"></i>
                    </button>
                )}
            </div>
            {editMode && (
                <div className="mapPinBar_changes_btn">
                    {saveContentButton}
                    {rawDataIds.delSelected.length > 0 && (
                        <button
                            onClick={handleDeleteSelections}
                            className="mapPinBar_del_btn mapPinbar_btn flex-center"
                        >
                            Remove row <TrashIcon />
                        </button>
                    )}
                    <button
                        className="mapPinBar_add_btn mapPinbar_btn flex-center"
                        onClick={handleRowInsert}
                    >
                        Insert row <PlusIcon />
                    </button>
                </div>
            )}
            {!editMode && !isCreateMode && !isNocUrl && (
                <div className="mapPinBar-group-list" onClick={handleTogglePinsFilterList}>
                    <span>
                        {capitalizeFirstCharatcter(pinsFilterState?.activePinFilter?.toLowerCase())}
                    </span>
                    <svg
                        style={{ transform: `${rotateGroupIcon ? "rotate(180deg)" : ""}` }}
                        id="Sort-down"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            id="Icon_awesome-sort-down"
                            data-name="Icon awesome-sort-down"
                            d="M2.2,20.25h9.981a1.008,1.008,0,0,1,.713,1.719L7.9,26.96a1,1,0,0,1-1.422,0L1.487,21.969A1.008,1.008,0,0,1,2.2,20.25Z"
                            transform="translate(4.809 -12.249)"
                            fill="#000000"
                        />
                    </svg>
                    <ul
                        className={`mapPinBar-group-list-content ${
                            pinsFilterState?.showList ? "active" : ""
                        }`}
                    >
                        {pinsFilterValues?.map((item) => {
                            return (
                                <li key={item} onClick={handleTogglePinsFilterValue(item)}>
                                    {capitalizeFirstCharatcter(item.toLowerCase())}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </section>
    );
}

export default MapGroups;
