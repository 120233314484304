import React from "react";

const PlusIcon = () => {
    return (
        <svg
            id="Plus"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
        >
            <g
                id="Boundary"
                fill="rgba(255,255,255,0.5)"
                stroke="rgba(0,0,0,0)"
                strokeWidth="1"
                opacity="0"
            >
                <rect width="16" height="16" stroke="none" />
                <rect x="0.5" y="0.5" width="15" height="15" fill="none" />
            </g>
            <path
                id="Path_plus"
                data-name="Path / plus"
                d="M15,10.714H10.714V15H9.286V10.714H5V9.286H9.286V5h1.429V9.286H15Z"
                transform="translate(-2 -2)"
                fill="rgba(0,0,0,0.4)"
            />
        </svg>
    );
};

export default PlusIcon;
