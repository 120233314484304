import React from "react";
import noImage from "../../../assets/images/noImage.png";
import { useDispatch, useSelector } from "react-redux";
import { setMapDashboardId } from "../../../store/slicers/mapDashboardSlice";
import userIcon from "../../../assets/images/avatar-grey.png";
import starGrey from "../../../assets/images/star-grey.png";
import starGolden from "../../../assets/images/star-golden.png";
import ImageListView from "../../../components/ImageListView/ImageListView";
import TrashIcon from "../../../components/icons/TrashIcon";
import ShareIcon from "../../../components/icons/ShareIcon";
import EditIcon from "../../../components/icons/EditIcon";
import ChevronRightIcon from "../../../components/icons/ChevronRightIcon";

const GroupMapListItem = (props) => {
    const {
        data,
        handleToggleMapDelete,
        handleShare,
        myMapFavHandle,
        showGroupState,
        setShowGroupState,
        pinsLoading,
        handleEdit,
    } = props;

    const dispatch = useDispatch();
    const mapDashboardId = useSelector((state) => state.mapDashboardState.id);
    const { mapType } = useSelector((state) => state.mapTypeState);

    const handleCheckboxChange = (id, title) => {
        if (showGroupState.checkListArr.some((item) => item.id === id)) {
            const updatedIds = showGroupState.checkListArr.filter((item) => item.id !== id);
            setShowGroupState((prevState) => {
                return {
                    ...prevState,
                    checkListArr: updatedIds,
                };
            });
        } else {
            setShowGroupState((prevState) => {
                return {
                    ...prevState,
                    checkListArr: [...showGroupState.checkListArr, { id: id, title: title }],
                };
            });
        }
    };

    const handleGroupItemClick = (id) => {
        setShowGroupState((prevState) => {
            return {
                ...prevState,
                activeMapGroupID: null,
                firstGroups: false,
                secondGroups: false,
            };
        });

        dispatch(setMapDashboardId(id));
    };

    return (
        <li
            className={`myMapList-data-item ${mapDashboardId === data._id ? "active" : ""} ${
                pinsLoading ? "clickable-none" : ""
            }`}
            onClick={() => handleGroupItemClick(data?._id)}
        >
            <div
                className={`myMapList-data-item-inner ${
                    mapDashboardId === data._id ? "active" : ""
                }`}
            >
                <div className="flex-gap-y5">
                    <div className="myMap-img">
                        <div className="list-input-wrapper">
                            {showGroupState.showCheckList && (
                                <>
                                    <label
                                        className="custom-label"
                                        htmlFor={data._id}
                                        style={{ paddingLeft: 15 }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <input
                                            type="checkbox"
                                            name={data._id}
                                            id={data._id}
                                            value={data._id}
                                            onChange={() => {
                                                handleCheckboxChange(data._id, data.title);
                                            }}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </>
                            )}
                            <img
                                src={data.favorite ? starGolden : starGrey}
                                className="fav-star"
                                alt="Favourite Map"
                                title="Mark as favorite"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    myMapFavHandle(data._id, !data.favorite, "groups");
                                }}
                            />
                        </div>

                        <div className="myGroup-tooltip--container">
                            <img
                                src={data?.mapImage || noImage}
                                className="myMap-list-img"
                                alt="map-list"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowGroupState((prevState) => ({
                                        ...prevState,
                                        openMapCropperModal: true,
                                        clickedItemID: data._id,
                                        clickedItemAvatar: data?.mapImage,
                                    }));
                                }}
                            />
                        </div>
                    </div>
                    <div className="mapList-data">
                        <div className="MyGroup-title-wrapper">
                            <h5 className="mapList-title">{data?.title}</h5>
                        </div>

                        {data.shared?.length ? (
                            <div
                                className="myMapList-people-shared"
                                onClick={(e) => {
                                    setShowGroupState((prevState) => {
                                        return {
                                            ...prevState,
                                            firstGroups: false,
                                            secondGroups: false,
                                            showShared: true,
                                        };
                                    });
                                }}
                            >
                                <ImageListView data={data?.shared} />
                            </div>
                        ) : (
                            <div className="no-shared-user-wrapper">
                                <img src={userIcon} alt="user" />
                            </div>
                        )}

                        <div className="myMapList-views">
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    let url = `/map-view/${
                                        Number(mapType) === 4 ? "group" : "map"
                                    }/${data._id}`;
                                    window.open(url, "_blank");
                                }}
                            >
                                Public view <ChevronRightIcon />
                            </button>

                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    props.getMyGroupGroups(data._id);
                                    setShowGroupState((prevState) => {
                                        return {
                                            ...prevState,
                                            activeGroupID: data._id,
                                            firstGroups: true,
                                            secondGroups: false,
                                            showShared: false,
                                            showGroupShared: false,
                                        };
                                    });
                                    props?.hideShare();
                                }}
                            >
                                Submaps <ChevronRightIcon />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="myMap-config options">
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleShare(data._id, data?.shared, "share-group");
                            props.setModalMapTitle(data.title);
                        }}
                    >
                        <ShareIcon />
                    </button>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(data._id, "group", data?.email);
                        }}
                    >
                        <EditIcon />
                    </button>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleToggleMapDelete(
                                data._id,
                                data?.map_id,
                                data?.parent_group,
                                // data?.type,
                                "group"
                            );
                            props.setModalMapTitle(data.title);
                        }}
                    >
                        <TrashIcon />
                    </button>
                </div>
            </div>
        </li>
    );
};

export default GroupMapListItem;
