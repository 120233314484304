import React from "react";

const AlertIcon = () => {
    return (
        <svg
            id="alert"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g id="Boundary" fill="#ef6c00" stroke="rgba(0,0,0,0)" stroke-width="1" opacity="0">
                <rect width="24" height="24" stroke="none" />
                <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
            </g>
            <path
                id="Path_alert"
                data-name="Path / alert"
                d="M13,14H11V9h2m0,9H11V16h2M1,21H23L12,2Z"
                transform="translate(0 1)"
                fill="#ef6c00"
            />
        </svg>
    );
};

export default AlertIcon;
