import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";
import { axiosInstance } from "../apiCalls/axios";
import { errorMessage, successMessage } from "./messages";
import OneSignal from "react-onesignal";

export const colors = [
    "rgb(255, 0, 0)", // Red
    "rgb(0, 0, 255)", // Blue
    "rgb(255, 0, 255)", // Magenta
    "rgb(0, 255, 255)", // Cyan
    "rgb(128, 0, 0)", // Maroon
    "rgb(128, 0, 128)", // Purple
    "rgb(0, 0, 128)", // Navy
    "rgb(59, 150, 0)", // Orange
    "rgb(255, 192, 203)", // Pink
    "rgb(0, 128, 0)", // Green
    "rgb(128, 128, 128)", // Gray
    "rgb(255, 128, 128)", // Light Maroon
    "rgb(0, 127, 255)", // Azure
    "rgb(0, 0, 0)", // Black
    "rgb(0, 0, 255)", // Blue
    "rgb(138, 43, 222)", // Blue-Violet
    "rgb(228, 34, 54)", // Brick Red
    "rgb(128, 0, 0)", // Brown
    "rgb(95, 158, 205)", // Cadet Blue
    "rgb(0, 127, 205)", // Cerulean
    "rgb(210, 105, 30)", // Chocolate
    "rgb(100, 95, 225)", // Cornflower Blue
    "rgb(220, 20, 60)", // Crimson
    "rgb(0, 0, 85)", // Dark Blue
    "rgb(0, 139, 139)", // Dark Cyan
    "rgb(149, 210, 600)", // Dark Goldenrod
    "rgb(0, 128, 0)", // Dark Green
    "rgb(139, 0, 139)", // Dark Magenta
    "rgb(195, 25, 10)", // Dark Olive Green
    "rgb(255, 127, 0)", // Dark Orange
];

// ---------------------------
// COLOR GENERTOR USING GENERTORS
// ---------------------------

// function* colorGenerator() {
//     let index = 0;
//     while (true) {
//         yield colors[index];
//         index = (index + 1) % colors.length;
//     }
// }
// const generator = colorGenerator();

// function getColor() {
//     return generator.next().value;
// }

// ---------------------------
// COLOR GENERTOR USING CLOSURES
// ---------------------------
export const getColor = (() => {
    let index = 0;

    return () => {
        const color = colors[index];
        index = (index + 1) % colors.length;
        return color;
    };
})();

export function CustomError(options) {
    for (const key in options) {
        this[key] = options[key];
    }
}

export function capitalizeString(string) {
    if (typeof string == "string") {
        return string
            .split(" ")
            .reduce((str, elem) => {
                return str + " " + elem.charAt(0).toUpperCase() + elem.slice(1).toLowerCase();
            }, "")
            .trim();
    }
    return "";
}

export function capitalizeFirstCharatcter(string) {
    return typeof string === "string" ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}
export function lowerCaseString(string) {
    return typeof string === "string" ? string.toLocaleLowerCase() : "";
}

export function toastApiMessage(inputString) {
    inputString = capitalizeFirstCharatcter(inputString);
    inputString = inputString.replaceAll(".", "");
    return inputString;
}

export const makeDeepCopy = (data) => {
    if (window.structuredClone) {
        return structuredClone(data);
    } else {
        return deepCopy(data);
    }
};

export function deepCopy(obj) {
    let copiedObject = obj;

    if (obj && typeof obj === "object") {
        copiedObject = Array.isArray(obj) ? [] : {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (obj[key] && typeof obj[key] === "object") {
                    copiedObject[key] = deepCopy(obj[key]);
                } else {
                    copiedObject[key] = obj[key];
                }
            }
        }
    }

    return copiedObject;
}

export function convertNumberToCommaNotation(nmbr) {
    return parseFloat(nmbr).toLocaleString("en");
}

export function extractExtention(str) {
    if (str) {
        let arr = str.split(".");
        return arr.at(-1).trim();
    }
    return "";
}

export function dimColor(color) {
    let newColor = window.w3color?.(color)?.toRgbString();
    const [red, green, blue] = newColor.slice(4, -1).split(","); // rgb(255,255,255)
    const alpha = 0.5;
    return `rgba(${red},${green},${blue},${alpha})`;
}

export function calculatePercentage(earned, total) {
    return parseFloat(((earned / total) * 100).toFixed(0));
}

export function changeToGroup(rows, userGroupBy = "") {
    if (
        userGroupBy.trim() &&
        userGroupBy.trim() !== "NO_GROUP" &&
        userGroupBy.trim() !== "undefined"
    ) {
        let colorIndex = 0;

        const groupedData = rows?.reduce(function (groupedDataArr, item) {
            let group = item[userGroupBy];

            // IF ITEM HAVE THAT KEY
            if (group) {
                let groupedItem = groupedDataArr.find((grp) => grp.group === group);

                if (groupedItem) {
                    item.color = groupedItem.color;
                    item.hexcolor = groupedItem.hexcolor;
                    item.group = group;

                    groupedItem.data.push(item);
                } else {
                    let groupedItem = groupedDataArr.find((grp) => grp.color === item.color);

                    let color = groupedItem ? getColor() : item.color || getColor();

                    let hexcolor = window.w3color?.(color)?.toHexString() || color;

                    item.group = group;
                    item.color = color;
                    item.hexcolor = hexcolor;

                    groupedDataArr.push({
                        group,
                        color,
                        hexcolor,
                        data: [item],
                    });
                }
            } else {
                let groupedItem = groupedDataArr.find((grp) => grp.group === "NO_GROUP");

                if (groupedItem) {
                    item.color = groupedItem.color;
                    item.hexcolor = groupedItem.hexcolor;
                    item.group = "NO_GROUP";

                    groupedItem.data.push(item);
                } else {
                    let color = item.color || colors[colorIndex++];
                    let hexcolor = window.w3color(color).toHexString();
                    let group = "NO_GROUP";

                    item.group = group;
                    item.color = color;
                    item.hexcolor = hexcolor;

                    groupedDataArr.push({
                        group,
                        color,
                        hexcolor,
                        data: [item],
                    });
                }
            }

            return groupedDataArr;
        }, []);

        return groupedData;
    } else {
        let group = "NO_GROUP";
        let color = rows?.[0]?.color || colors[0];
        let hexcolor = rows?.[0]?.hexcolor || window.w3color(color).toHexString();

        return [
            {
                group,
                color,
                hexcolor,
                data: rows.map((innerGrp) => {
                    innerGrp.group = group;
                    innerGrp.color = color;
                    innerGrp.hexcolor = hexcolor;
                    return innerGrp;
                }),
            },
        ];
    }
}

export function distanceBetweenTwoMarkersUsingMaps(maps, pointOne, pointTwo) {
    let distance = maps.geometry.spherical.computeDistanceBetween(pointOne, pointTwo) / 1609.344;
    return distance.toFixed(2);
}

// Calculate color range for progress bar formula

let colorRange = [
    [0, 33, "#4BBB5799"],
    [34, 66, "#FFC043"],
    [67, 100, "#FF000099"],
];

export function getPercentageColor(value) {
    if (isNaN(parseFloat(value))) return "red";

    let result;
    colorRange?.forEach((i) => {
        if (value >= i[0] && value <= i[1]) {
            result = i[2];
        } else if (value > i[1]) {
            result = "red";
        }
    });
    return result;
}

export const checkEmailValidation = (emails) => {
    let isInvalid = emails?.filter((item) => !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(item?.email));
    if (isInvalid?.length > 0) {
        return false;
    } else {
        return true;
    }
};

export const checkEmailValid = (email) => {
    let isInvalid = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    return isInvalid;
};

export const filterDataEmail = (data) => {
    // let validEmailCount = 0;
    let invalidEmailCount = 0;
    let duplicateCount = 0;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const uniqueSet = new Set();
    const filteredData = data?.filter((item) => {
        if (emailRegex.test(item)) {
            if (uniqueSet.has(item)) {
                duplicateCount++;
                return false;
            } else {
                uniqueSet.add(item);
                // validEmailCount++;
                return true;
            }
        } else {
            invalidEmailCount++;
            return false;
        }
    });

    const uniqueEmails = Array.from(uniqueSet).map((email) => ({ email: email }));

    return { uniqueEmails, invalidEmailCount, duplicateCount };
};

export const filterdData = (data, searchState, checkSearchQueryRun, setCheckSearchQueryRun) => {
    if (searchState) {
        let isData = data?.filter((item) => {
            return `${item.first_name || item?.sender?.first_name} ${
                item.last_name || item?.sender?.last_name
            } ${item?.email || item?.content}`
                ?.toLowerCase()
                .includes(searchState);
        });
        if (isData?.length === 0 && checkSearchQueryRun === false) {
            setCheckSearchQueryRun((prevState) => !prevState);
        }
        return isData;
    } else {
        return data;
    }
};

export const getType = (mapDataTypeState) => {
    let type = "";
    if (mapDataTypeState?.shareType === "shared-map") {
        type = "shareMap";
    } else if (mapDataTypeState?.shareType === "share-group") {
        type = "group";
    } else if (mapDataTypeState?.shareType === "share-route") {
        type = "route";
    } else {
        type = "map";
    }
    return type;
};
export const updateConnectedUsers = (data, sharedData) => {
    let copiedConnectedUser = makeDeepCopy(data);
    // let updateData
    sharedData.forEach((item) => {
        if (typeof item === "string") {
            copiedConnectedUser.paginatedUsers.push({ email: item });
            copiedConnectedUser.totalUsersCount = copiedConnectedUser.totalUsersCount + 1;
        }
        // return copiedConnectedUser;
    });
    return copiedConnectedUser;
};

// export function getPercentageColor(number) {
//     let color = "";
//     if (number >= 0 && number <= 33) {
//         color = "green";
//     } else if (number >= 34 && number <= 66) {
//         color = "yellow";
//     } else if (number >= 67 && number <= 400) {
//         color = "red";
//     }
//     return color;
// }

export function replaceKeys(object) {
    Object.keys(object).forEach(function (key) {
        const newKey = key.replace(/\s+/g, "");
        if (object[key] && typeof object[key] === "object") {
            replaceKeys(object[key]);
        }
        if (key !== newKey) {
            object[newKey] = object[key];
            delete object[key];
        }
    });
    return object;
}

export async function convertArrayTSV(data) {
    const mapArray = data.map((item) => {
        const { location, color, ...otherRemaining } = item;

        return otherRemaining;
    });
    const headings = Object.keys(mapArray[0]).join("\t");
    const rows = mapArray
        .reduce((acc, c) => {
            return acc.concat([Object.values(c).join("\t")]);
        }, [])
        .join("\n");

    let _newData = `${headings}\n${rows}`;

    await navigator.clipboard.writeText(_newData);
}

export async function convertDataByGrouping(data, grouping) {
    let groupingSet = new Set(data.map((pin) => pin[grouping.toLowerCase()]));
    groupingSet = Array.from(groupingSet);

    const mapArray = data.map((item) => {
        const { history, ...otherRemaining } = item;

        return otherRemaining;
    });
    const relatedData = groupingSet?.map((grouping) => {
        const relatedGroups = mapArray
            ?.filter((filterItem) => Object?.values(filterItem).includes(grouping))
            .map((item) => Object.values(item).join("\t"))
            .join("\n");
        return `${grouping}\n${relatedGroups}`;
    });

    const headings = Object.keys(mapArray[0])?.join("\t");
    let _newData = `${headings}\n${relatedData?.join("\n")}`;
    await navigator.clipboard.writeText(_newData);
}

export const tsvToJSON = (tsv) => {
    const lines = tsv.split("\n");
    const headers = lines.slice(0, 1)[0].split("\t");
    return lines.slice(1, lines.length).map((line) => {
        const data = line.split("\t");
        return headers.reduce((obj, nextKey, index) => {
            obj[nextKey] = data[index];
            return obj;
        }, {});
    });
};

export function arrayToTSV(data) {
    if (data.length > 0) {
        const headings = Object.keys(data[0]).join("\t");
        const rows = data
            .reduce((arr, c) => {
                return arr.concat([Object.values(c).join("\t")]);
            }, [])
            .join("\n");
        return `${headings}\n${rows}`;
    } else {
        return "";
    }
}

export function csvToJSON(csvData) {
    const lines = csvData.split("\n");
    const headers = lines[0].split(",").map((header) => header.trim().toUpperCase()); // Assuming the first row contains headers

    let rawData = [];
    for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].split(",");

        if (currentLine.some((entry) => entry.trim())) {
            const rowObject = {};
            for (let j = 0; j < headers.length; j++) {
                rowObject[headers[j]] = currentLine[j];
            }
            rawData.push(rowObject);
        }
    }

    return rawData;
}

export const cleanObj = (d) => {
    const data = d.map((o) => {
        const obj = { ...o };
        Object.keys(obj).forEach((key) => {
            if (!obj[key]?.trim()) {
                delete obj[key];
            }
        });
        if (Object.keys(obj).length) {
            return obj;
        }
    });
    return data.filter((e) => e !== undefined);
};

export const cleanData = (d) => {
    const data = d.map((o) => {
        const obj = { ...o };
        Object.keys(obj).forEach((key) => {
            if (key === "location") {
                delete obj[key];
            }
        });
        if (Object.keys(obj).length !== 0) {
            return obj;
        }
    });
    return data.filter((e) => e !== undefined);
};

function getHeaderFromRawHeaders(value, rawHeaders) {
    return rawHeaders.find((header) => {
        let lowerHeader = header.toLowerCase();
        return (
            lowerHeader === value ||
            lowerHeader.startsWith(value) ||
            lowerHeader.includes(value) ||
            ""
        );
    });
}

export function getMandatoryHeaders(rawHeaders) {
    let generaterHeaders = {
        city: getHeaderFromRawHeaders("city", rawHeaders),
        state: getHeaderFromRawHeaders("state", rawHeaders),
        zip: getHeaderFromRawHeaders("zip", rawHeaders),
        address: getHeaderFromRawHeaders("address", rawHeaders),

        name: getHeaderFromRawHeaders("name", rawHeaders),
        phone: getHeaderFromRawHeaders("phone", rawHeaders),
        email: getHeaderFromRawHeaders("email", rawHeaders),
        url: getHeaderFromRawHeaders("url", rawHeaders),
        note: getHeaderFromRawHeaders("note", rawHeaders),
        opportunities: getHeaderFromRawHeaders("opportunit", rawHeaders), // opportuni is better than opportunity or opportunities
    };

    return generaterHeaders;
}

export const idGenerator = (idLength = 16) => {
    let chars =
        "0123456789QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm";
    let firstChar = "QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm";
    let id = firstChar[Math.floor(Math.random() * firstChar.length)];
    for (let i = 0; i < idLength - 1; i++) {
        let randomNumber = Math.floor(Math.random() * chars.length);
        id += chars[randomNumber];
    }
    return id;
};

const dotDynamic = (length) => {
    let createArr = [];
    for (let index = 0; index < length - 3; index++) {
        createArr.push(index);
    }
    return createArr;
};

export const emailHideFunc = (param) => {
    let dynamicAarray = [];
    let email = param;
    let at_index = email?.indexOf("@");
    let first_index = email[0];
    let last_index = email[at_index - 1];
    let secondLast_index = email[at_index - 2];
    let domain = email.substring(at_index);
    dynamicAarray = dotDynamic(at_index);
    return (
        <>
            <span>
                {at_index > 3 ? (
                    first_index
                ) : (
                    <>
                        <span className="filled-dot"></span>
                    </>
                )}
            </span>
            {dynamicAarray.map((item, index) => (
                <span className="filled-dot"></span>
            ))}
            <span>{secondLast_index}</span>
            <span>{last_index}</span>
            <span>{domain}</span>
        </>
    );
};

// 2nd scenario if client asked to use
// export const emailHideFunc = (param) => {
//     let email = param;
//     let at_index = email?.indexOf("@");
//     let first_index = email[0];
//     let last_index = email[at_index - 1];
//     let secondLast_index = email[at_index - 2];
//     let domain = email.substring(at_index);

//     return (
//         <>
//             <span>{first_index}</span>
//             <span className="filled-dot"></span>
//             <span className="filled-dot"></span>
//             <span className="filled-dot"></span>
//             <span>{secondLast_index}</span>
//             <span>{last_index}</span>
//             <span>{domain}</span>
//         </>
//     );
// };

let rad = function (x) {
    return (x * Math.PI) / 180;
};

export function getDistance(p1, p2) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2.lat - p1.lat);
    var dLong = rad(p2.lng - p1.lng);
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d; // returns the distance in meter
}

export function checkDuplicatePins(pins) {
    let fakePins = pins.slice();
    let duplicatePins = {};
    let duplicate = false;

    let uniquePins = [];

    fakePins?.forEach((pin, i) => {
        if (duplicatePins[pin.location.lat + pin.location.lng]) {
            duplicate = true;

            duplicatePins[pin.location.lat + pin.location.lng].push({
                id: idGenerator(),
                pin,
            });

            uniquePins.splice(uniquePins.length - 1, 1);
        } else {
            duplicatePins[pin.location.lat + pin.location.lng] = [
                {
                    id: idGenerator(),
                    pin,
                },
            ];

            uniquePins.push(pin);
        }
    });

    Object.keys(duplicatePins).forEach((key) => {
        if (duplicatePins[key].length === 1) {
            delete duplicatePins[key];
        }
    });

    return {
        uniquePins,
        duplicate,
        duplicatePins,
    };
}

export const saveImageAsPdf = () => {
    const myElement = document.createElement("section");

    myElement.appendChild(document.getElementById("google_map_container").cloneNode(true));
    let group = document.getElementById("mapPinBar_group").cloneNode(true);
    group.style.margin = 0;
    myElement.appendChild(group);

    document.body.appendChild(myElement);

    html2canvas(myElement, {
        useCORS: true,
        allowTaint: true,
    }).then((canvas) => {
        const image = canvas.toDataURL("image/png", 1);
        const doc = new jsPDF("l", "mm", [210, 110]);
        // const doc = new jsPDF("landscape");
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const widthRatio = pageWidth / canvas.width;
        const heightRatio = pageHeight / canvas.height;
        const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

        const canvasWidth = canvas.width * ratio;
        const canvasHeight = canvas.height * ratio;

        const marginX = (pageWidth - canvasWidth) / 2;
        const marginY = (pageHeight - canvasHeight) / 250;

        doc.addImage(image, "PNG", marginX, marginY, canvasWidth, canvasHeight);
        // doc.addImage(image, "PNG", 15, 40, 180, 180);
        doc.save("xoom-maps.pdf");
        myElement.remove();
    });
};

export const handleMapToImage = async (arg) => {
    const myElement = document.createElement("section");
    myElement.appendChild(document.getElementById("google_map_container").cloneNode(true));

    let group = document.getElementById("mapPinBar_group").cloneNode(true);
    group.style.margin = 0;
    myElement.appendChild(group);

    document.body.appendChild(myElement);

    try {
        const canvas = await html2canvas(myElement, {
            allowTaint: true,
            useCORS: true,
            width: document.getElementById("google_map_container").scrollWidth,
            height: myElement.scrollHeight,
        });
        var imgData = canvas.toDataURL("image/png", 1);
        saveAs(imgData, "XoomMaps.png");
        myElement.remove();
    } catch (err) {
        console.log("error in image", err);
    }
};

export const printMap = () => {
    let mapContents = document.querySelector("#my_google_map").outerHTML;
    let groups = document.querySelector("#mapPinBar_group").outerHTML;

    let printableContent = window.open();

    printableContent.document.write(`
        <html>
            <style>
                html,
                .main,
                body {
                    overflow: hidden;
                    height: 100vh;
                    width: 100vw;
                    page-break-after: auto;
                }
                
                main,
                div[role="menubar"],
                .resize-container,
                .gm-style-moc,
                .mapView-options--container,
                .mapView-options--wrapper,
                .drawing-manager-wrapper {
                    display: none;
                }

                .main {
                    position: absolute;
                    inset: 0;

                    width: 100%;
                    height: 100%;

                    display: flex;
                    flex-direction: column-reverse;
                }

        
                .map-container {
                    position: relative;
                    flex:1;
                    transform: scaleY(1.35);
                    top:-8%;
                }


                /* -------------------------- */
                /* Map Pin Bar Group */
                /* -------------------------- */
                .mapPinBar-group {
                    background-color: #eee;
                    box-shadow: -3px 3px 5px #105d8d33, 3px -3px 7px #fff;

                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    padding: 20px 30px;
                    border-radius: 10px;
                    
                    opacity: 1;
                    overflow-x: auto;
                    overflow-y: hidden;

                    z-index: 9999 !important;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width:100%;

                }
                .mapPinBar-group div {
                    margin-right: 20px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }
                .mapPinBar-group img {
                    width: 100%;
                    max-width: 17px;
                    height: auto;
                }
                .mapPinBar-group span {
                    font: normal normal normal 14px/20px SourceSansPro-Regular, sans-serif;
                    color: var(--clr-blue);
                    margin-left: 10px;
                    display: inline-block;
                    min-width: 60px;
                    text-transform: capitalize;
                }
                

         


            </style>

            <body>
                <main class="main">
                    <section class="map-container">
                        ${mapContents}
                    </section>

                    ${groups}
                <main>
            </body>
        </html>
    `);

    printableContent.document.close();
    printableContent.print();
};

export function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

export function throttle(func, timeout = 1000, options = {}) {
    let lastRan = 0;
    let timer;

    return function (...args) {
        const leading = options.leading !== undefined ? options.leading : true;
        const trailing = options.trailing !== undefined ? options.trailing : true;

        const shouldExecute = leading && Date.now() - lastRan >= timeout;

        clearTimeout(timer);

        if (shouldExecute) {
            func.apply(this, args);
            lastRan = Date.now();
        } else if (trailing) {
            timer = setTimeout(() => {
                func.apply(this, args);
                lastRan = Date.now();
            }, timeout - (Date.now() - lastRan));
        }
    };
}

export function userLogout(param) {
    // DELETE USER FROM LOCAL

    const tags = ["user_id", "email"];
    OneSignal.User.removeTags(tags);

    localStorage.clear();
    // DELETE USER TOKEN
    axiosInstance.defaults.headers = {
        "x-auth-token": "",
    };
    param === "auto"
        ? successMessage("Automatically log out due to inactivity")
        : successMessage("Successfully logout");

    setTimeout(() => {
        window.location.href = "/login";
    }, 1000);
}

export const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
    const { top, left, bottom, right } = el.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
    return partiallyVisible
        ? ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight)) &&
              ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
        : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

export const getFilteredData = (data, hiddenMarkers, searchText) => {
    let getData = data?.filter(
        (data) =>
            data?.name?.toLowerCase().includes(searchText) ||
            data?.phone?.toLowerCase().includes(searchText) ||
            data?.group?.toLowerCase().includes(searchText) ||
            data?.address?.toLowerCase().includes(searchText) ||
            data?.zip?.toLowerCase().includes(searchText) ||
            data?.state?.toLowerCase().includes(searchText) ||
            data?.city?.toLowerCase().includes(searchText)
    );
    if (hiddenMarkers && hiddenMarkers?.length > 0) {
        getData = getData?.filter((pin) =>
            hiddenMarkers?.some((hiddenMarker) => hiddenMarker?.group === pin?.group)
        );
    }
    return getData;
};

export const convertBtoA = (value) => {
    return window.btoa(value);
};
export const convertAtoB = (value) => {
    return window.atob(value);
};

export const SetDataToLocalStorage = (id, pinId, email) => {
    if (id && pinId && email) {
        let ecodeId = convertBtoA(id);
        let ecodePinId = convertBtoA(pinId);
        let ecodeEmail = convertBtoA(email);
        localStorage.setItem(
            "shareETAData",
            JSON.stringify({ id: ecodeId, pinId: ecodePinId, email: ecodeEmail })
        );
    }
};

export const GetDataFromLocalStorage = () => {
    const savedData = localStorage.getItem("shareETAData");
    if (savedData) {
        const { id, pinId, email } = JSON.parse(savedData);
        let decodeId = convertAtoB(id);
        let decodePinId = convertAtoB(pinId);
        let decodeEmail = convertAtoB(email);
        return { id: decodeId, pinId: decodePinId, email: decodeEmail };
    }
};

export const handleScrollToEnd = () => {
    window.scrollTo({
        top: document.body.scrollHeight, // Scroll to the bottom of the page
        behavior: "smooth", // Smooth scrolling
    });
};

export const dummyData = [
    {
        ADDRESS: "1206 VAN NESS AVE",
        CITY: "FRESNO",
        STATE: "CA",
        ZIP: "93721",
        NAME: "WELLS FARGO",
        PHONE: "(800) 869-3577",
        GROUP: "BRANCH",
        URL: "HTTPS://WWW.WELLSFARGO.COM",
        EMAIL: "INFO@WELLSFARGO.COM",
        NOTE: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR SADIPS-CING ELITR, SED DIAM NONUMY EIRMOD TEMPOR INVIDUNT UT LABORE ET DOLORE MAGNA ALIQUYAM ERAT",
        OPPORTUNITIES: "100",
        LEADBYSOURCE: "COLLEAGUE",
        group: "BRANCH",
        color: "rgb(255, 0, 0)",
        hexcolor: "#FF0000",
        address: "1206 VAN NESS AVE",
        city: "FRESNO",
        zip: "93721",
        state: "CA",
        name: "WELLS FARGO",
        email: "INFO@WELLSFARGO.COM",
        phone: "(800) 869-3577",
        url: "HTTPS://WWW.WELLSFARGO.COM",
        note: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR SADIPS-CING ELITR, SED DIAM NONUMY EIRMOD TEMPOR INVIDUNT UT LABORE ET DOLORE MAGNA ALIQUYAM ERAT",
        opportunities: "100",
        id: "adccdee6-8216-43f7-8114-c4ab945c2115",
        index: 0,
        location: {
            lat: 36.737061,
            lng: -119.7912549,
        },
        g_city: "Fresno",
        g_zip: "93721",
        g_state: "CA",
        g_country: "USA",
        g_timezone: "-08",
        leadBySource: "COLLEAGUE",
        history: [
            {
                status: 0,
                type: null,
                state: null,
                group_id: null,
            },
        ],
    },
    {
        ADDRESS: "50 W SAN FERNANDO ST ",
        CITY: "SAN JOSE",
        STATE: "CA",
        ZIP: "95113",
        NAME: "WELLS FARGO",
        PHONE: "(408) 689-6800",
        GROUP: "BRANCH",
        URL: "HTTPS://WWW.WELLSFARGO.COM",
        EMAIL: "INFO@WELLSFARGO.COM",
        NOTE: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR SADIPS-CING ELITR, SED DIAM NONUMY EIRMOD TEMPOR INVIDUNT UT LABORE ET DOLORE MAGNA ALIQUYAM ERAT",
        OPPORTUNITIES: "150",
        LEADBYSOURCE: "NEWSPAPER",
        group: "BRANCH",
        color: "rgb(255, 0, 0)",
        hexcolor: "#FF0000",
        address: "50 W SAN FERNANDO ST ",
        city: "SAN JOSE",
        zip: "95113",
        state: "CA",
        name: "WELLS FARGO",
        email: "INFO@WELLSFARGO.COM",
        phone: "(408) 689-6800",
        url: "HTTPS://WWW.WELLSFARGO.COM",
        note: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR SADIPS-CING ELITR, SED DIAM NONUMY EIRMOD TEMPOR INVIDUNT UT LABORE ET DOLORE MAGNA ALIQUYAM ERAT",
        opportunities: "150",
        id: "8ef1fe9f-4a5d-4d38-9f18-4737f12e2ff1",
        index: 1,
        location: {
            lat: 37.3339912,
            lng: -121.8892658,
        },
        g_city: "San Jose",
        g_zip: "95113",
        g_state: "CA",
        g_country: "USA",
        g_timezone: "-08",
        leadBySource: "NEWSPAPER",
        history: [
            {
                status: 0,
                type: null,
                state: null,
                group_id: null,
            },
        ],
    },
    {
        ADDRESS: "5763 CHRISTIE AVE",
        CITY: "EMERYVILLE",
        STATE: "CA",
        ZIP: "94608",
        NAME: "WELLS FARGO",
        PHONE: "(800) 869-3557",
        GROUP: "LOANS",
        URL: "HTTPS://WWW.WELLSFARGO.COM",
        EMAIL: "INFO@WELLSFARGO.COM",
        NOTE: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR SADIPS-CING ELITR, SED DIAM NONUMY EIRMOD TEMPOR INVIDUNT UT LABORE ET DOLORE MAGNA ALIQUYAM ERAT",
        OPPORTUNITIES: "200",
        group: "LOANS",
        color: "rgb(255, 0, 255)",
        hexcolor: "#FF00FF",
        address: "5763 CHRISTIE AVE",
        city: "EMERYVILLE",
        zip: "94608",
        state: "CA",
        name: "WELLS FARGO",
        email: "INFO@WELLSFARGO.COM",
        phone: "(800) 869-3557",
        url: "HTTPS://WWW.WELLSFARGO.COM",
        note: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR SADIPS-CING ELITR, SED DIAM NONUMY EIRMOD TEMPOR INVIDUNT UT LABORE ET DOLORE MAGNA ALIQUYAM ERAT",
        opportunities: "200",
        id: "eadf3289-88cf-43b2-a4cd-7443410d3f32",
        index: 2,
        location: {
            lat: 37.8363694,
            lng: -122.2950096,
        },
        g_city: "Emeryville",
        g_zip: "94608",
        g_state: "CA",
        g_country: "USA",
        g_timezone: "-08",
        leadBySource: "",
        history: [
            {
                status: 0,
                type: null,
                state: null,
                group_id: null,
            },
        ],
    },
    {
        ADDRESS: "1121 LAS VEGAS",
        CITY: "LAS VEGAS",
        STATE: "NV",
        ZIP: "89104",
        NAME: "WELLS FARGO",
        PHONE: "(702) 464-3138",
        GROUP: "BRANCH",
        URL: "HTTPS://WWW.WELLSFARGO.COM",
        EMAIL: "INFO@WELLSFARGO.COM",
        NOTE: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR SADIPS-CING ELITR, SED DIAM NONUMY EIRMOD TEMPOR INVIDUNT UT LABORE ET DOLORE MAGNA ALIQUYAM ERAT",
        OPPORTUNITIES: "250",
        color: "rgb(255, 0, 0)",
        hexcolor: "#FF0000",
        group: "BRANCH",
        address: "1121 LAS VEGAS",
        city: "LAS VEGAS",
        zip: "89104",
        state: "NV",
        name: "WELLS FARGO",
        email: "INFO@WELLSFARGO.COM",
        phone: "(702) 464-3138",
        url: "HTTPS://WWW.WELLSFARGO.COM",
        note: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR SADIPS-CING ELITR, SED DIAM NONUMY EIRMOD TEMPOR INVIDUNT UT LABORE ET DOLORE MAGNA ALIQUYAM ERAT",
        opportunities: "250",
        id: "9e7655bd-a648-44b3-bbf1-40f3f803a32f",
        index: 3,
        location: {
            lat: 36.14975099999999,
            lng: -115.0762536,
        },
        g_city: "Las Vegas",
        g_zip: "89104",
        g_state: "NV",
        g_country: "USA",
        g_timezone: "-08",
        leadBySource: "",
        history: [
            {
                status: 0,
                type: null,
                state: null,
                group_id: null,
            },
        ],
    },
    {
        ADDRESS: "1300 22ND ST",
        CITY: "BALERSFIELD",
        STATE: "CA",
        ZIP: "93301",
        NAME: "WELLS FARGO",
        PHONE: "(661) 861-9971",
        GROUP: "MORTGAGE",
        URL: "HTTPS://WWW.WELLSFARGO.COM",
        EMAIL: "INFO@WELLSFARGO.COM",
        NOTE: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR SADIPS-CING ELITR, SED DIAM NONUMY EIRMOD TEMPOR INVIDUNT UT LABORE ET DOLORE MAGNA ALIQUYAM ERAT",
        OPPORTUNITIES: "300 ",
        group: "MORTGAGE",
        color: "rgb(0, 255, 255)",
        hexcolor: "#00FFFF",
        address: "1300 22ND ST",
        city: "BALERSFIELD",
        zip: "93301",
        state: "CA",
        name: "WELLS FARGO",
        email: "INFO@WELLSFARGO.COM",
        phone: "(661) 861-9971",
        url: "HTTPS://WWW.WELLSFARGO.COM",
        note: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR SADIPS-CING ELITR, SED DIAM NONUMY EIRMOD TEMPOR INVIDUNT UT LABORE ET DOLORE MAGNA ALIQUYAM ERAT",
        opportunities: "300 ",
        id: "2d015836-68ce-4907-8f32-63bebc07cef0",
        index: 4,
        location: {
            lat: 35.3793508,
            lng: -119.016737,
        },
        g_city: "Bakersfield",
        g_zip: "93301",
        g_state: "CA",
        g_country: "USA",
        g_timezone: "-08",
        leadBySource: "",
        history: [
            {
                status: 0,
                type: null,
                state: null,
                group_id: null,
            },
        ],
    },
    {
        ADDRESS: "232 E 2ND ST",
        CITY: "LOS ANGELES",
        STATE: "CA",
        ZIP: "90012",
        NAME: "WELLS FARGO",
        PHONE: "(213) 335-6549",
        GROUP: "BRANCH",
        URL: "HTTPS://WWW.WELLSFARGO.COM",
        EMAIL: "INFO@WELLSFARGO.COM",
        NOTE: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR SADIPS-CING ELITR, SED DIAM NONUMY EIRMOD TEMPOR INVIDUNT UT LABORE ET DOLORE MAGNA ALIQUYAM ERAT",
        OPPORTUNITIES: "350",
        color: "rgb(255, 0, 0)",
        hexcolor: "#FF0000",
        group: "BRANCH",
        address: "232 E 2ND ST",
        city: "LOS ANGELES",
        zip: "90012",
        state: "CA",
        name: "WELLS FARGO",
        email: "INFO@WELLSFARGO.COM",
        phone: "(213) 335-6549",
        url: "HTTPS://WWW.WELLSFARGO.COM",
        note: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR SADIPS-CING ELITR, SED DIAM NONUMY EIRMOD TEMPOR INVIDUNT UT LABORE ET DOLORE MAGNA ALIQUYAM ERAT",
        opportunities: "350",
        id: "43046cbf-1342-42aa-bf98-190d7b2ce380",
        index: 5,
        location: {
            lat: 34.049037,
            lng: -118.242155,
        },
        g_city: "Los Angeles",
        g_zip: "90012",
        g_state: "CA",
        g_country: "USA",
        g_timezone: "-08",
        leadBySource: "",
        history: [
            {
                status: 0,
                type: null,
                state: null,
                group_id: null,
            },
        ],
    },
    {
        ADDRESS: "101 W BROADWAY STE",
        CITY: "SAN DIEGO",
        STATE: "CA",
        ZIP: "92101",
        NAME: "WELLS FARGO",
        PHONE: "(800) 869-3557",
        GROUP: "BRANCH",
        URL: "HTTPS://WWW.WELLSFARGO.COM",
        EMAIL: "INFO@WELLSFARGO.COM",
        NOTE: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR SADIPS-CING ELITR, SED DIAM NONUMY EIRMOD TEMPOR INVIDUNT UT LABORE ET DOLORE MAGNA ALIQUYAM ERAT",
        OPPORTUNITIES: "400",
        color: "rgb(255, 0, 0)",
        hexcolor: "#FF0000",
        group: "BRANCH",
        address: "101 W BROADWAY STE",
        city: "SAN DIEGO",
        zip: "92101",
        state: "CA",
        name: "WELLS FARGO",
        email: "INFO@WELLSFARGO.COM",
        phone: "(800) 869-3557",
        url: "HTTPS://WWW.WELLSFARGO.COM",
        note: "LOREM IPSUM DOLOR SIT AMET, CONSETETUR SADIPS-CING ELITR, SED DIAM NONUMY EIRMOD TEMPOR INVIDUNT UT LABORE ET DOLORE MAGNA ALIQUYAM ERAT",
        opportunities: "400",
        id: "0bea4e34-9f96-40e0-8e35-71dfee2235d3",
        index: 6,
        location: {
            lat: 32.7150214,
            lng: -117.1642958,
        },
        g_city: "San Diego",
        g_zip: "92101",
        g_state: "CA",
        g_country: "USA",
        g_timezone: "-08",
        leadBySource: "",
        history: [
            {
                status: 0,
                type: null,
                state: null,
                group_id: null,
            },
        ],
    },
];
